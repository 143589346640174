import React from "react";
import { CityNode, CityNodeProps } from "./CityNode";
import { Gallery } from "../Gallery";
import { StoreItemProps } from "./StoreItem";

const cities: CityNodeProps[] = [
  {
    name: "Singapore",
    latitude: 1.29027,
    longitude: 103.851959,
    country: "Singapore",
    stores: [
      {
        imageUrl: "https://via.placeholder.com/150",
        storeName: "Singapore Night Club",
        images: [
          "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_2-1280x720.jpg",
          "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_3-1280x720.jpg",
          "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_1-1280x720.jpg",
          //   "https://swiperjs.com/demos/images/nature-4.jpg",
          //   "https://swiperjs.com/demos/images/nature-5.jpg",
          //   "https://swiperjs.com/demos/images/nature-6.jpg",
        ],
      },
    ],
  },
  {
    name: "Bangkok",
    latitude: 13.736717,
    longitude: 100.523186,
    country: "Thailand",
    stores: [
      {
        imageUrl: "https://via.placeholder.com/150",
        storeName: "F Studio and Wellness Center",
        images: [
          "https://files.company.fashiontv.com/wp-content/uploads/2019/03/Screenshot-2019-03-24-at-07.49.18.png",
          //   "https://swiperjs.com/demos/images/nature-2.jpg",
          //   "https://swiperjs.com/demos/images/nature-3.jpg",
          //   "https://swiperjs.com/demos/images/nature-4.jpg",
          //   "https://swiperjs.com/demos/images/nature-5.jpg",
          //   "https://swiperjs.com/demos/images/nature-6.jpg",
          //   "https://swiperjs.com/demos/images/nature-7.jpg",
          //   "https://swiperjs.com/demos/images/nature-8.jpg",
          //   "https://swiperjs.com/demos/images/nature-9.jpg",
        ],
      },
      //   {
      //     imageUrl: "https://via.placeholder.com/150",
      //     storeName: "Thailand Night Club 2",
      //     images: ["https://swiperjs.com/demos/images/nature-1.jpg"],
      //   },
    ],
  },
  {
    name: "Dubai",
    latitude: 25.2048,
    longitude: 55.2708,
    country: "Dubai",
    stores: [
      {
        imageUrl: "https://via.placeholder.com/150",
        storeName: "F-Cafe Mini PopUp",
        images: [
          "https://files.company.fashiontv.com/wp-content/uploads/2015/04/love_f_cafe_dubai_2-1280x720.jpg",
          //   "https://swiperjs.com/demos/images/nature-2.jpg",
          //   "https://swiperjs.com/demos/images/nature-3.jpg",
          //   "https://swiperjs.com/demos/images/nature-4.jpg",
          //   "https://swiperjs.com/demos/images/nature-5.jpg",
          //   "https://swiperjs.com/demos/images/nature-6.jpg",
          //   "https://swiperjs.com/demos/images/nature-7.jpg",
          //   "https://swiperjs.com/demos/images/nature-8.jpg",
          //   "https://swiperjs.com/demos/images/nature-9.jpg",
        ],
      },
      //   {
      //     imageUrl: "https://via.placeholder.com/150",
      //     storeName: "Thailand Night Club 2",
      //     images: ["https://swiperjs.com/demos/images/nature-1.jpg"],
      //   },
    ],
  },
  {
    name: "China",
    latitude: 35.8617,
    longitude: 104.1954,
    country: "China",
    stores: [
      {
        imageUrl: "https://via.placeholder.com/150",
        storeName: "F-Cafe Mini PopUp",
        images: [
          "https://files.company.fashiontv.com/wp-content/uploads/2018/11/MJ-PopUp-00.jpg",
          //   "https://swiperjs.com/demos/images/nature-2.jpg",
          //   "https://swiperjs.com/demos/images/nature-3.jpg",
          //   "https://swiperjs.com/demos/images/nature-4.jpg",
          //   "https://swiperjs.com/demos/images/nature-5.jpg",
          //   "https://swiperjs.com/demos/images/nature-6.jpg",
          //   "https://swiperjs.com/demos/images/nature-7.jpg",
          //   "https://swiperjs.com/demos/images/nature-8.jpg",
          //   "https://swiperjs.com/demos/images/nature-9.jpg",
        ],
      },
      //   {
      //     imageUrl: "https://via.placeholder.com/150",
      //     storeName: "Thailand Night Club 2",
      //     images: ["https://swiperjs.com/demos/images/nature-1.jpg"],
      //   },
    ],
  },
  {
    name: "Chiro",
    latitude: 9.0795,
    longitude: 40.8652,
    country: "Chiro",
    stores: [
      {
        imageUrl: "https://via.placeholder.com/150",
        storeName: "F-Cafe Mini PopUp",
        images: [
          "https://files.company.fashiontv.com/wp-content/uploads/2015/04/love_f_cafe_cairo_1-1280x720.jpg",
          //   "https://swiperjs.com/demos/images/nature-2.jpg",
          //   "https://swiperjs.com/demos/images/nature-3.jpg",
          //   "https://swiperjs.com/demos/images/nature-4.jpg",
          //   "https://swiperjs.com/demos/images/nature-5.jpg",
          //   "https://swiperjs.com/demos/images/nature-6.jpg",
          //   "https://swiperjs.com/demos/images/nature-7.jpg",
          //   "https://swiperjs.com/demos/images/nature-8.jpg",
          //   "https://swiperjs.com/demos/images/nature-9.jpg",
        ],
      },
      //   {
      //     imageUrl: "https://via.placeholder.com/150",
      //     storeName: "Thailand Night Club 2",
      //     images: ["https://swiperjs.com/demos/images/nature-1.jpg"],
      //   },
    ],
  },
  {
    name: "Macau",
    latitude: 22.1987,
    longitude: 113.5439,
    country: "Macau",
    stores: [
      {
        imageUrl: "https://via.placeholder.com/150",
        storeName: "FASHIONTV COSMETICS",
        images: [
          "https://files.company.fashiontv.com/wp-content/uploads/2018/01/4.jpg",
          //   "https://swiperjs.com/demos/images/nature-2.jpg",
          //   "https://swiperjs.com/demos/images/nature-3.jpg",
          //   "https://swiperjs.com/demos/images/nature-4.jpg",
          //   "https://swiperjs.com/demos/images/nature-5.jpg",
          //   "https://swiperjs.com/demos/images/nature-6.jpg",
          //   "https://swiperjs.com/demos/images/nature-7.jpg",
          //   "https://swiperjs.com/demos/images/nature-8.jpg",
          //   "https://swiperjs.com/demos/images/nature-9.jpg",
        ],
      },
      //   {
      //     imageUrl: "https://via.placeholder.com/150",
      //     storeName: "Thailand Night Club 2",
      //     images: ["https://swiperjs.com/demos/images/nature-1.jpg"],
      //   },
    ],
  },
  {
    name: "Bali",
    latitude: 8.4095,
    longitude: 115.1889,
    country: "Bali",
    stores: [
      {
        imageUrl: "https://via.placeholder.com/150",
        storeName: "LOVE FASHION HOTEL",
        images: [
          "https://files.company.fashiontv.com/wp-content/uploads/2015/04/hotel_bali_1-1280x720.jpg",
          //   "https://swiperjs.com/demos/images/nature-2.jpg",
          //   "https://swiperjs.com/demos/images/nature-3.jpg",
          //   "https://swiperjs.com/demos/images/nature-4.jpg",
          //   "https://swiperjs.com/demos/images/nature-5.jpg",
          //   "https://swiperjs.com/demos/images/nature-6.jpg",
          //   "https://swiperjs.com/demos/images/nature-7.jpg",
          //   "https://swiperjs.com/demos/images/nature-8.jpg",
          //   "https://swiperjs.com/demos/images/nature-9.jpg",
        ],
      },
      //   {
      //     imageUrl: "https://via.placeholder.com/150",
      //     storeName: "Thailand Night Club 2",
      //     images: ["https://swiperjs.com/demos/images/nature-1.jpg"],
      //   },
    ],
  },
  {
    name: "India",
    latitude: 20.5937,
    longitude: 78.9629,
    country: "India",
    stores: [
      {
        imageUrl: "https://via.placeholder.com/150",
        storeName: "FASHION RESIDENCE",
        images: [
          "https://files.company.fashiontv.com/wp-content/uploads/2015/04/hotel_noida_india_1-1280x720.jpg",
          //   "https://swiperjs.com/demos/images/nature-2.jpg",
          //   "https://swiperjs.com/demos/images/nature-3.jpg",
          //   "https://swiperjs.com/demos/images/nature-4.jpg",
          //   "https://swiperjs.com/demos/images/nature-5.jpg",
          //   "https://swiperjs.com/demos/images/nature-6.jpg",
          //   "https://swiperjs.com/demos/images/nature-7.jpg",
          //   "https://swiperjs.com/demos/images/nature-8.jpg",
          //   "https://swiperjs.com/demos/images/nature-9.jpg",
        ],
      },
      //   {
      //     imageUrl: "https://via.placeholder.com/150",
      //     storeName: "Thailand Night Club 2",
      //     images: ["https://swiperjs.com/demos/images/nature-1.jpg"],
      //   },
    ],
  },
];

const CityNodes: React.FC = () => {
  const [activeGallery, setActiveGallery] = React.useState<
    string[] | undefined
  >();
  return (
    <>
      {cities.map((city: CityNodeProps, index: number) => (
        <CityNode
          {...city}
          key={index}
          onStoreClick={(store: StoreItemProps) =>
            setActiveGallery(store.images)
          }
        />
      ))}

      {!!activeGallery && (
        <Gallery
          show
          onDismiss={() => setActiveGallery(undefined)}
          images={activeGallery}
        />
      )}
    </>
  );
};

export { CityNodes };
