import {
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
  alpha,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export interface BannerProps {
  header?: React.ReactNode;
  icon?: React.ReactNode;
  body: React.ReactNode;
  show: boolean;
  position?: "bottom-left" | "bottom-right";
  withBorder?: boolean;
  withTransparent?: boolean;
  inline?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  header,
  icon,
  body,
  show,
  position = "bottom-left",
  withBorder,
  withTransparent,
  inline,
}: BannerProps) => {
  const theme = useTheme();
  const isMobileView: boolean = useMediaQuery("(max-width:600px)");

  const renderHeader = React.useCallback(() => {
    const defaultHeader: React.ReactNode = (
      <Typography variant="h6">{header}</Typography>
    );
    if (isMobileView && !!icon && !inline) {
      return (
        <Box component="div" sx={{ display: "flex" }}>
          <Box
            component="div"
            sx={{
              flex: "0 0 2.5rem",
              p: 2,
            }}
          >
            {icon}
          </Box>
          {defaultHeader}
        </Box>
      );
    }
    return defaultHeader;
  }, [isMobileView, header, icon, inline]);

  return (
    <Box
      component="div"
      sx={{
        position: "absolute",
        bottom: {
          xs: "1rem",
          sm: "3rem",
          md: position === "bottom-right" ? "1rem" : "7.5rem",
        },
        left:
          position === "bottom-left"
            ? {
                xs: 0,
                md: "10rem",
              }
            : "unset",
        right:
          position === "bottom-right"
            ? {
                xs: 0,
                md: "2.5rem",
                lg: "5rem",
              }
            : "unset",
        p: {
          xs: 1,
          md: 0,
        },
        borderLeft: withBorder ? `2px solid ${theme.palette.primary.main}` : "",
        borderRadius: withBorder ? "1rem" : "initial",
      }}
    >
      <Slide direction="right" in={show} mountOnEnter unmountOnExit>
        <Box
          component="div"
          sx={{
            p: 2,
            backgroundColor: withTransparent
              ? alpha(theme.palette.background.default, 0.8)
              : theme.palette.background.default,
            boxShadow: theme.shadows[10],
            display: "flex",
            alignItems: "center",
          }}
        >
          {!!icon && (!isMobileView || inline) && (
            <Box
              component="div"
              sx={{
                flex: {
                  xs: "0 0 3rem",
                  md: "0 0 5rem",
                },
                p: {
                  xs: 0.5,
                  md: 2,
                },
              }}
            >
              {icon}
            </Box>
          )}
          <Box component="div" sx={{ flex: 1, paddingLeft: 1 }}>
            {renderHeader()}
            {body}
          </Box>
        </Box>
      </Slide>
    </Box>
  );
};

export { Banner };
