import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import Lootbox from "../../assets/graphics/lootbox.gif";

export interface GenesisBoxItemProps {
  value: number;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const GenesisBoxItem: React.FC<GenesisBoxItemProps> = ({
  value,
  name,
  onChange,
  checked,
}: GenesisBoxItemProps) => {
  const theme = useTheme();

  return (
    <Box
      component="div"
      sx={{
        width: {
          xs: "6.25rem",
          sm: "11.25rem",
        },
        margin: "auto",
        position: "relative",
        ...(checked
          ? {
              border: `2px solid ${theme.palette.primary.main}`,
              boxShadow: `0 0 5px 2px ${theme.palette.primary.main}`,
              borderRadius: "1rem",
              overflow: "hidden",
            }
          : {}),
      }}
    >
      <label>
        <input
          type="checkbox"
          id={`${name}-${value}`}
          name={name}
          onChange={onChange}
          hidden
          checked={checked}
          value={value}
        />
        <Card
          sx={{
            padding: 0,
          }}
        >
          <CardMedia
            component="img"
            alt="Lootbox"
            image={Lootbox}
            sx={{
              width: "100%",
              cursor: "pointer",
              marginBottom: {
                xs: "0.25rem",
                sm: "1rem",
              },
            }}
          />
          <CardContent sx={{ padding: "0 0 0.75rem 0 !important" }}>
            <Typography
              key={value}
              gutterBottom
              variant="h5"
              component="h5"
              sx={{
                paddingTop: 0,
                fontSize: {
                  xs: "0.85rem",
                  sm: "1.25rem !important",
                },
              }}
            >
              Genesis Box #{value}
            </Typography>
          </CardContent>
        </Card>
      </label>
    </Box>
  );
};

export { GenesisBoxItem };
