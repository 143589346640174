import React from "react";
import { Typography } from "@mui/material";
import { BaseModal, BaseModalProps } from "./BaseModal";
import { ButtonLarge } from "./CustomButtons";

export interface ErrorModalProps extends Omit<BaseModalProps, "title"> {
  message: string;
}

const ErrorModal: React.FC<ErrorModalProps> = (props: ErrorModalProps) => {
  return (
    <BaseModal open={props.open} title="Error" onClose={props.onClose}>
      <Typography
        variant="body1"
        component="p"
        sx={{
          marginBottom: "2rem",
          fontSize: "1.5rem",
        }}
        dangerouslySetInnerHTML={{ __html: props.message }}
      />
      <ButtonLarge fullWidth variant="contained" onClick={props.onClose}>
        Okay
      </ButtonLarge>
    </BaseModal>
  );
};

export { ErrorModal };
