import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Theme,
  Link,
  SxProps,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { PanelWrapper, ButtonLarge, ErrorModal } from "../components";
import MintBackground from "../assets/pages/home/phases/mint-bg.png";
import Lootbox from "../assets/graphics/lootbox.gif";
import { Add, Remove } from "@mui/icons-material";
import { common } from "@mui/material/colors";
import { useWeb3Context } from "../utils/hooks/useWeb3Context";
import { useBalance, useNetwork } from "wagmi";
import { useCountdown } from "../utils/hooks/useCountdown";
import { useMintBoxContract } from "../utils/hooks/useMintBoxContract";
import { LoadingSkeleton } from "../components/LoadingSkeleton";
import { COUNTDOWN_START_DATE, COUNTDOWN_END_DATE } from "../app/constants";

const PhaseStatus = styled(Typography)(() => ({
  background: "linear-gradient(93.5deg, #D01AEE 1.14%, #9747FF 100.09%)",
  display: "inline-block",
  padding: "0.5rem 1rem",
  borderRadius: "5rem",
}));

const MintingCell = styled(TableCell)(({ theme }) => ({
  fontSize: "0.875rem",
  [theme.breakpoints.only("xs")]: {
    fontSize: "0.75rem",
    padding: "0.5rem 0.75rem",
  },
}));

type MintListItem = {
  label: React.ReactNode;
  content: React.ReactNode;
};

const DEFAULT_MINT_QUANTITY: number = 1;
const MAX_MINT_QUANTITY: number = 2;

const MintGenesisBox: React.FC = () => {
  const isMobileView: boolean = useMediaQuery("(max-width:600px)");
  const isTabletView: boolean = useMediaQuery("(max-width:960px)");
  const {
    countdownTimer: mintingStartCountdown,
    isCompleted: isMintingStartCountdownCompleted,
  } = useCountdown(new Date(COUNTDOWN_START_DATE));

  const {
    // countdownTimer: mintingEndCountdown,
    isCompleted: isMintingEndCountdownCompleted,
  } = useCountdown(new Date(COUNTDOWN_END_DATE));

  const [mintQuantity, setMintQuantity] = React.useState<number>(
    DEFAULT_MINT_QUANTITY
  );
  const [errorDialog, setErrorDialog] = React.useState<{
    open: boolean;
    message: string;
  }>({ open: false, message: "" });
  const [isInsufficientFund, setIsInsufficientFund] =
    React.useState<boolean>(false);
  const [isNotWhitelisted, setIsNotWhitelisted] =
    React.useState<boolean>(false);
  const [hasMintedMax, setHasMintedMax] = React.useState<boolean>(false);
  const [isPrepareContractEnabled, setIsPrepareContractEnabled] =
    React.useState<boolean>(false);

  const onError = (error: Error): void => {
    const extractedError = error.message?.match?.(/([^;]+)\(/)?.[1];
    let message = extractedError || error.message;

    if (message.includes("call revert exception")) {
      return;
    }
    setErrorDialog({
      open: true,
      message: message.charAt(0).toUpperCase() + message.slice(1),
    });
  };
  const { initConnection, connected, accountAddress } = useWeb3Context();

  const { data: balance } = useBalance({
    address: accountAddress,
    onError,
  });

  const {
    isMaxRoundSupplyLoading,
    isRoundSoldDataLoading,
    isTokenPriceLoading,
    totalMintingAmount,
    maxRoundSupply,
    tokenPrice,
    mintLeft,
    transactionLink,
    mint,
  } = useMintBoxContract({
    onReadError: onError,
    mintQuantity,
    mintConfig: {
      enabled: isPrepareContractEnabled,
      onError,
      onPrepareError: (error: Error) => {
        const message: string = error.message.toLowerCase();
        if (!connected || message.includes("concurrent requests capacity")) {
          return;
        }
        if (
          message.includes(
            "transaction may fail or may require manual gas limit"
          )
        ) {
          if (message.includes("max by round by address")) {
            setHasMintedMax(true);
            return;
          }
          return;
        }

        if (message.includes("invalid value for array")) {
          setIsNotWhitelisted(true);
          return;
        }
        if (message.includes("insufficient funds for intrinsic transaction")) {
          setIsInsufficientFund(true);
          return;
        }

        if (message.includes("max by round by address")) {
          setIsNotWhitelisted(true);
          return;
        }

        onError(error);
      },
    },
  });

  const { chain } = useNetwork();

  const list: MintListItem[] = useMemo(
    () => [
      {
        label: "Mints Left",
        content: (
          <LoadingSkeleton isLoading={isRoundSoldDataLoading}>
            {mintLeft}
          </LoadingSkeleton>
        ),
      },
      {
        label: "Mints Price",
        content: (
          <LoadingSkeleton isLoading={isTokenPriceLoading}>
            {tokenPrice}
          </LoadingSkeleton>
        ),
      },
      {
        label: "Mints Quantity",
        content: (
          <Box
            component="div"
            sx={{
              border: (theme: Theme) =>
                `1px solid ${theme.palette.primary.main}`,
              borderRadius: "2rem",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{ marginRight: "1rem" }}
              size={isMobileView ? "small" : "medium"}
              disabled={mintQuantity >= MAX_MINT_QUANTITY}
              onClick={() => {
                setMintQuantity((quantity: number) => quantity + 1);
              }}
            >
              <Add />
            </IconButton>
            <Typography component="span">{mintQuantity}</Typography>
            <IconButton
              sx={{ marginLeft: "1rem" }}
              size={isMobileView ? "small" : "medium"}
              disabled={mintQuantity <= DEFAULT_MINT_QUANTITY}
              onClick={() =>
                setMintQuantity((quantity: number) => quantity - 1)
              }
            >
              <Remove />
            </IconButton>
          </Box>
        ),
      },
      {
        label: "Total",
        content: totalMintingAmount,
      },
    ],
    [
      mintQuantity,
      isMobileView,
      mintLeft,
      tokenPrice,
      totalMintingAmount,
      isTokenPriceLoading,
      isRoundSoldDataLoading,
    ]
  );

  const renderCountdown = (style: SxProps<Theme>) => {
    let label: string;
    if (isMintingStartCountdownCompleted) {
      return null;
    }
    label = `Count down to minting: ${mintingStartCountdown}`;
    return (
      <PhaseStatus variant="h5" sx={style}>
        {label}
      </PhaseStatus>
    );
  };

  const renderMintButtonContent = () => {
    if (hasMintedMax) {
      return "You've exceeded max mint";
    }

    if (isNotWhitelisted) {
      return "Not whitelisted";
    }
    if (isInsufficientFund) {
      return "Insufficient Fund";
    }
    if (connected) {
      return "Mint";
    }
    return "Connect Wallet";
  };

  useEffect(() => {
    if (!connected) {
      setIsInsufficientFund(false);
      setIsNotWhitelisted(false);
      setHasMintedMax(false);
    }
  }, [connected]);

  useEffect(() => {
    setIsInsufficientFund(false);
    setIsNotWhitelisted(false);
    setHasMintedMax(false);
  }, [accountAddress, mintQuantity, chain]);

  return (
    <PanelWrapper gutterLeft gutterRight>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        sx={{
          pt: {
            xs: "5rem",
            sm: "7.5rem",
            md: "10rem",
          },
          pb: "5rem",
          height: {
            md: "100%",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 2, md: 1 }}
          sx={{
            paddingTop: { md: "unset !important" },
            textAlign: "center",
            marginTop: {
              sm: "2rem",
              md: "unset !important",
            },
            display: {
              md: "flex",
            },
          }}
        >
          <Box component="div" sx={{ margin: "auto" }}>
            <Typography
              variant="h4"
              sx={{
                marginBottom: {
                  xs: "0.25rem",
                  sm: "0.5rem",
                  md: "1rem",
                },
                fontSize: { xs: "1.25rem", sm: "1.5rem" },
              }}
            >
              Mint Genesis Boxes
            </Typography>
            <Typography
              sx={{
                marginBottom: {
                  xs: "0.5rem",
                  sm: "1rem",
                  md: "2rem",
                },
                fontSize: { xs: "0.75rem", sm: "1.25rem" },
              }}
            >
              Whitelisters are allow to mint up to 2 genesis boxes!
            </Typography>
            <Table
              sx={{
                marginBottom: {
                  xs: "1rem",
                  sm: "3rem",
                },
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    "td, th": {
                      borderBottom: (theme: Theme) =>
                        `1px solid ${theme.palette.text.primary}`,
                    },
                  }}
                >
                  <MintingCell>Your balance</MintingCell>
                  <MintingCell align="right">
                    {balance
                      ? Number.parseFloat(balance.formatted).toFixed(4)
                      : 0}{" "}
                    ETH
                  </MintingCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item: MintListItem, index: number) => (
                  <TableRow
                    key={index}
                    sx={{
                      "td, th": {
                        border: 0,
                      },
                      "&:last-child": {
                        borderTop: (theme: Theme) =>
                          `1px solid ${theme.palette.text.primary}`,
                        borderBottom: (theme: Theme) =>
                          `1px solid ${theme.palette.text.primary}`,
                      },
                    }}
                  >
                    <MintingCell component="th" scope="row">
                      {item.label}
                    </MintingCell>
                    <MintingCell align="right">{item.content}</MintingCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <ButtonLarge
              variant="contained"
              onClick={
                connected
                  ? () => {
                      setIsPrepareContractEnabled(true);
                      mint?.();
                    }
                  : initConnection
              }
              fullWidth
              disabled={
                !isMintingStartCountdownCompleted ===
                  !isMintingEndCountdownCompleted ||
                isInsufficientFund ||
                isNotWhitelisted ||
                hasMintedMax
              }
            >
              <Typography variant="h6">{renderMintButtonContent()}</Typography>
            </ButtonLarge>
            {transactionLink && (
              <Link target="_blank" href={transactionLink} underline="always">
                Click here to check the transaction
              </Link>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 1, md: 2 }}
          sx={{
            height: {
              md: "100%",
            },
            paddingTop: { md: "unset !important" },
          }}
        >
          <Box
            component="div"
            sx={{
              backgroundImage: `url(${MintBackground})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              textAlign: "center",
              height: {
                md: "100%",
              },
              borderRadius: "2rem",
              display: "flex",
              alignItems: "center",
              flexDirection: {
                xs: "row",
                md: "column",
              },
              padding: {
                xs: "1.25rem 1rem",
                sm: "2rem 3rem",
              },
            }}
          >
            <Box
              component="div"
              sx={{
                margin: {
                  xs: "unset",
                  md: "auto",
                },
                width: {
                  xs: "100%",
                  md: "initial",
                },
                display: { xs: "flex", md: "initial" },
              }}
            >
              <Box
                component="div"
                sx={{
                  flex: { xs: 1, md: "unset" },
                  textAlign: { xs: "left", md: "inherit" },
                  marginBottom: { md: "2rem" },
                  alignSelf: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "bold",
                    fontSize: {
                      xs: "1.5rem",
                      sm: "2.5rem",
                    },
                    color: common.white,
                  }}
                >
                  Phase 1 {isMintingEndCountdownCompleted && "(Sold out)"}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "bold",
                    fontSize: {
                      xs: "1.5rem",
                      sm: "2.5rem",
                    },
                    color: common.white,
                  }}
                ></Typography>
                {isTabletView && (
                  <PhaseStatus
                    variant="h5"
                    sx={{
                      marginTop: "2rem",
                      fontSize: {
                        xs: "1rem",
                        sm: "1.5rem",
                      },
                      color: common.white,
                    }}
                  >
                    {renderCountdown({ color: "white" })}
                  </PhaseStatus>
                )}
              </Box>
              <Box component="div">
                <Box
                  component="img"
                  src={Lootbox}
                  alt="Lootbox"
                  sx={{
                    width: {
                      xs: "4.5rem",
                      sm: "10rem",
                      md: "15rem",
                    },
                    cursor: "pointer",
                    marginBottom: {
                      xs: "0.25rem",
                      sm: "1rem",
                    },
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.premint.xyz/nftv-panda-nfts-whitelist-campaign/",
                      "_blank"
                    );
                  }}
                />
                <LoadingSkeleton
                  isLoading={isMaxRoundSupplyLoading || isRoundSoldDataLoading}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: {
                        xs: "1rem",
                        sm: "1.5rem",
                      },
                      color: common.white,
                    }}
                  >
                    {mintLeft} / {maxRoundSupply}
                  </Typography>
                </LoadingSkeleton>
              </Box>
              {!isTabletView && (
                <PhaseStatus
                  variant="h5"
                  sx={{
                    marginTop: "1rem",
                    color: common.white,
                  }}
                >
                  {renderCountdown({ color: "white" })}
                </PhaseStatus>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ErrorModal
        children={undefined}
        {...errorDialog}
        onClose={() => setErrorDialog((dialog) => ({ ...dialog, open: false }))}
      />
      {/* <BaseModal
				open={errorDialog.open}
				title="Error"
				onClose={() =>
					setErrorDialog((dialog) => ({ ...dialog, open: false }))
				}
			>
				<Typography
					variant="body1"
					component="p"
					sx={{
						marginBottom: '2rem',
						fontSize: '1.5rem',
					}}
					dangerouslySetInnerHTML={{ __html: errorDialog.message }}
				/>
				<ButtonLarge
					fullWidth
					variant="contained"
					onClick={() =>
						setErrorDialog((dialog) => ({ ...dialog, open: false }))
					}
				>
					Okay
				</ButtonLarge>
			</BaseModal> */}
    </PanelWrapper>
  );
};

export { MintGenesisBox };
