import React from "react";
// import { getDefaultProvider } from "ethers";

import { configureChains, createClient, WagmiConfig } from "wagmi";

import { mainnet, bsc, polygon, goerli } from "wagmi/chains";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
// import { publicProvider } from "wagmi/providers/public";
// import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
interface IWagmiWrapperProps {
  children: React.ReactNode;
}

export type ConnectorName =
  | MetaMaskConnector["id"]
  | WalletConnectConnector["id"];

export type NftvWalletConnector = MetaMaskConnector | WalletConnectConnector;

const { chains, provider, webSocketProvider } = configureChains(
  // [chain.goerli],
  [mainnet, bsc, polygon, goerli],
  [
    alchemyProvider({ apiKey: "-tjLgtSuC1ba2JSx5XsozxV1XCXTF2AG" }),
    publicProvider(),
  ]
  // [
  //   jsonRpcProvider({
  //     rpc: (chain) => ({
  //       http: `https://${chain.name}.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161`,
  //     }),
  //   }),
  // ]
);

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      options: {
        qrcode: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
});

const WagmiWrapper: React.FC<IWagmiWrapperProps> = ({
  children,
}: IWagmiWrapperProps) => {
  return <WagmiConfig client={client}>{children}</WagmiConfig>;
};

export { WagmiWrapper };
