import React from "react";
import { ButtonGroup, Button, Theme } from "@mui/material";
import { ReactComponent as GridIcon } from "../../assets/icons/grid.svg";
import { ReactComponent as GridExtraIcon } from "../../assets/icons/grid-extra.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/list.svg";
import { useAppSelector } from "../../app/hooks";
import { selectAppState } from "../../features/appStateSlice";

export type GalleryLayoutType = "list" | "grid" | "grid-sm";

type GalleryLayoutItem = {
  icon: React.FC<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  value: GalleryLayoutType;
};

const LAYOUTS: GalleryLayoutItem[] = [
  {
    icon: GridIcon,
    value: "grid",
  },
  {
    icon: GridExtraIcon,
    value: "grid-sm",
  },
  {
    icon: ListIcon,
    value: "list",
  },
];

export interface GalleryLayoutSelectorProps {
  onLayoutChange: (layout: GalleryLayoutType) => void;
  value: GalleryLayoutType;
}

const GalleryLayoutSelector: React.FC<GalleryLayoutSelectorProps> = ({
  onLayoutChange,
  value,
}) => {
  const { darkMode } = useAppSelector(selectAppState);

  const getButtonColor = (theme: Theme) =>
    darkMode ? theme.palette.grey["700"] : theme.palette.grey["400"];

  return (
    <ButtonGroup
      variant="outlined"
      aria-label="outlined primary button group"
      size="large"
      color="inherit"
    >
      {LAYOUTS.map(({ icon: Icon, value: layoutType }, index: number) => (
        <Button
          key={index}
          sx={{
            paddingY: "0.65rem",
            color: (theme) => {
              if (value === layoutType) {
                return theme.palette.text.primary;
              }
              return getButtonColor(theme);
            },
            borderColor: getButtonColor,
          }}
          onClick={() => onLayoutChange(layoutType)}
        >
          <Icon width="1rem" />
        </Button>
      ))}
    </ButtonGroup>
  );
};

export { GalleryLayoutSelector };
