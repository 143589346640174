/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 panda.glb --types --transform
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import {
  EffectComposer,
  Vignette,
  SelectiveBloom,
} from "@react-three/postprocessing";

// Import glb file
import pandaObj from "./panda-transformed.glb";

type GLTFResult = GLTF & {
  nodes: {
    Torus002: THREE.Mesh;
    Torus003: THREE.Mesh;
    头001: THREE.Mesh;
    Cube008: THREE.Mesh;
    Cube008_1: THREE.Mesh;
    helmet_ring: THREE.Mesh;
    helmet_light: THREE.Mesh;
    Cube002: THREE.Mesh;
    Cube002_1: THREE.Mesh;
    衣领: THREE.Mesh;
    Torus005: THREE.Mesh;
    Circle003: THREE.Mesh;
    Circle004: THREE.Mesh;
    Circle005: THREE.Mesh;
    Circle006: THREE.Mesh;
    Circle007: THREE.Mesh;
    Circle008: THREE.Mesh;
    Sphere001_1: THREE.Mesh;
    Sphere001_2: THREE.Mesh;
    吊带: THREE.Mesh;
    Cube010: THREE.Mesh;
    Cube013: THREE.Mesh;
    Cylinder001: THREE.Mesh;
    Cube011: THREE.Mesh;
    Cylinder006: THREE.Mesh;
    Cylinder002: THREE.Mesh;
    Cube014_1: THREE.Mesh;
    Cube014_2: THREE.Mesh;
    Cube014_3: THREE.Mesh;
    Cube015: THREE.Mesh;
    Cylinder003: THREE.Mesh;
    Cylinder004_1: THREE.Mesh;
    Cylinder004_2: THREE.Mesh;
    Cylinder004_3: THREE.Mesh;
    Plane002: THREE.Mesh;
    Plane003: THREE.Mesh;
    Cube012: THREE.Mesh;
    glasses: THREE.Mesh;
    glasses_1: THREE.Mesh;
    头: THREE.Mesh;
    耳朵: THREE.Mesh;
    Bamboo: THREE.Mesh;
    Cube004: THREE.Mesh;
    Cube004_1: THREE.Mesh;
    Cube005: THREE.Mesh;
    Cube005_1: THREE.Mesh;
    Torus: THREE.Mesh;
    柱体: THREE.Mesh;
    Curve021: THREE.Mesh;
    Curve023: THREE.Mesh;
    Curve031: THREE.Mesh;
    柱体001: THREE.Mesh;
    球体: THREE.Mesh;
    球体001: THREE.Mesh;
  };
  materials: {
    金链: THREE.MeshStandardMaterial;
    texture_helmet: THREE.MeshStandardMaterial;
    ["Material.008"]: THREE.MeshStandardMaterial;
    texture_helmet_light: THREE.MeshStandardMaterial;
    ["Body-white"]: THREE.MeshStandardMaterial;
    texture_torso: THREE.MeshStandardMaterial;
    texture_collar: THREE.MeshStandardMaterial;
    扣子: THREE.MeshStandardMaterial;
    ["吊带裤.Pouch"]: THREE.MeshStandardMaterial;
    ["Material.015"]: THREE.MeshStandardMaterial;
    ["吊带裤.body"]: THREE.MeshStandardMaterial;
    ["Body-black"]: THREE.MeshStandardMaterial;
    ["Material.011"]: THREE.MeshStandardMaterial;
    texture_arm_left: THREE.MeshStandardMaterial;
    texture_arm_right: THREE.MeshStandardMaterial;
    texture_bag_top: THREE.MeshStandardMaterial;
    ["Material.007"]: THREE.MeshPhysicalMaterial;
    texture_bag_bottom: THREE.MeshStandardMaterial;
    ["Bag-purple 2"]: THREE.MeshStandardMaterial;
    ["Material.013"]: THREE.MeshStandardMaterial;
    ["Teeth-grey"]: THREE.MeshStandardMaterial;
    texture_shoe: THREE.MeshStandardMaterial;
    Face: THREE.MeshStandardMaterial;
    ["Material.003"]: THREE.MeshStandardMaterial;
    texture_token_4: THREE.MeshStandardMaterial;
    texture_token_1: THREE.MeshStandardMaterial;
    texture_token_2: THREE.MeshStandardMaterial;
    texture_token_3: THREE.MeshStandardMaterial;
    texture_token_5: THREE.MeshStandardMaterial;
  };
};

export function Panda(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(pandaObj) as GLTFResult;
  const objHelmetLight = useRef<THREE.Mesh>(null);
  return (
    <>
      <EffectComposer autoClear={false}>
        <SelectiveBloom
          selection={[objHelmetLight]}
          luminanceThreshold={0}
          luminanceSmoothing={0.9}
          height={300}
        />
        <Vignette eskil={false} offset={0.1} darkness={1.1} />
      </EffectComposer>

      <group {...props} dispose={null}>
        <mesh
          geometry={nodes.Torus002.geometry}
          material={materials.金链}
          position={[0.13, 2.49, 0.5]}
          rotation={[0.01, 0.42, 0.41]}
          scale={[0.09, 0.1, 0.05]}
        />
        <mesh
          geometry={nodes.Torus003.geometry}
          material={materials.金链}
          position={[0.01, 2.45, 0.54]}
          rotation={[0.99, 0.34, 0.02]}
          scale={[0.09, 0.1, 0.05]}
        />
        <mesh
          geometry={nodes.头001.geometry}
          material={materials.金链}
          position={[-0.09, 2.48, 0.51]}
          rotation={[0.04, -0.62, -0.44]}
          scale={[0.09, 0.1, 0.05]}
        />
        <group
          position={[0, 3.77, -0.01]}
          rotation={[0.1, 0, 0]}
          scale={[0.87, 0.81, 0.9]}
        >
          <mesh
            geometry={nodes.Cube008.geometry}
            material={materials.texture_helmet}
          />
          <mesh
            geometry={nodes.Cube008_1.geometry}
            material={materials["Material.008"]}
          />
        </group>
        <mesh
          geometry={nodes.helmet_ring.geometry}
          material={materials.texture_helmet}
          position={[-0.01, 3.96, 0.55]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.18, 0.19, 0.18]}
        />
        <mesh
          ref={objHelmetLight}
          geometry={nodes.helmet_light.geometry}
          material={materials.texture_helmet_light}
          position={[0, 0.32, 0]}
          scale={0.92}
          material-emissive="#B5B388"
          material-emissiveIntensity={3}
          material-toneMapped={false}
        />
        <group
          position={[0.02, 2.23, -0.04]}
          rotation={[-0.06, 0, 0]}
          scale={[1.03, 1, 1]}
        >
          <mesh
            geometry={nodes.Cube002.geometry}
            material={materials["Body-white"]}
          />
          <mesh
            geometry={nodes.Cube002_1.geometry}
            material={materials.texture_torso}
          />
        </group>
        <mesh
          geometry={nodes.衣领.geometry}
          material={materials.texture_collar}
          position={[0, 2.7, -0.02]}
          rotation={[0.17, 0.04, 0.02]}
          scale={[0.6, 0.53, 0.52]}
        />
        <mesh
          geometry={nodes.Torus005.geometry}
          material={materials.扣子}
          position={[-0.44, 2.33, 0.45]}
          rotation={[0.9, 0.49, 0.88]}
          scale={0.08}
        />
        <mesh
          geometry={nodes.Circle003.geometry}
          material={materials.金链}
          position={[0, 1.98, 0.67]}
          rotation={[0.91, -1.2, -1.72]}
          scale={[0.11, 0.11, 0.12]}
        >
          <mesh
            geometry={nodes.Circle004.geometry}
            material={materials.金链}
            position={[0.06, 1.08, -0.99]}
            rotation={[0.08, -0.2, 0.66]}
            scale={[0.97, 1.02, 1.01]}
          />
          <mesh
            geometry={nodes.Circle005.geometry}
            material={materials.金链}
            position={[1.14, 0.16, 1.02]}
            rotation={[0, 0.05, -0.15]}
            scale={[1.01, 0.99, 1]}
          />
          <mesh
            geometry={nodes.Circle006.geometry}
            material={materials.金链}
            position={[0.93, 0.74, -0.09]}
            rotation={[0.91, -0.46, 2.02]}
            scale={[0.99, 1.01, 1.02]}
          />
          <mesh
            geometry={nodes.Circle007.geometry}
            material={materials.金链}
            position={[0.82, 0.9, -1.64]}
            rotation={[0.54, -0.3, -1.61]}
            scale={[0.97, 1.01, 1.03]}
          />
          <mesh
            geometry={nodes.Circle008.geometry}
            material={materials.金链}
            position={[0.35, -0.38, 1.48]}
            rotation={[0.71, 0.48, -1.94]}
            scale={[0.96, 1.01, 1.04]}
          />
        </mesh>
        <group
          position={[0, 2.02, 0.48]}
          rotation={[0, 0.07, 0.02]}
          scale={[1.17, 1, 1.05]}
        >
          <mesh
            geometry={nodes.Sphere001_1.geometry}
            material={materials["吊带裤.Pouch"]}
          />
          <mesh
            geometry={nodes.Sphere001_2.geometry}
            material={materials["Material.015"]}
          />
        </group>
        <mesh
          geometry={nodes.吊带.geometry}
          material={materials["吊带裤.body"]}
          position={[-0.04, 2.44, -0.07]}
          rotation={[0, 0, 0.04]}
          scale={[0.99, 1, 1]}
        />
        <mesh
          geometry={nodes.Cube010.geometry}
          material={materials["Body-black"]}
          position={[-0.65, 2.18, -0.08]}
          scale={[0.73, 0.85, 0.68]}
        />
        <mesh
          geometry={nodes.Cube013.geometry}
          material={materials["Material.011"]}
          position={[-0.81, 1.51, -0.13]}
          rotation={[Math.PI, -1.4, Math.PI]}
          scale={[1.05, 1, 1.12]}
        />
        <mesh
          geometry={nodes.Cylinder001.geometry}
          material={materials.texture_arm_left}
          position={[-0.47, 2.47, -0.1]}
          rotation={[0, 0, -0.75]}
          scale={[1.07, 1.06, 1.05]}
        />
        <mesh
          geometry={nodes.Cube011.geometry}
          material={materials["Body-black"]}
          position={[0.67, 2.34, 0.12]}
          rotation={[1.89, -0.01, Math.PI]}
          scale={[0.73, 0.89, 0.65]}
        />
        <mesh
          geometry={nodes.Cylinder006.geometry}
          material={materials.texture_arm_right}
          position={[-0.47, 2.47, -0.1]}
          rotation={[0, 0, -0.75]}
          scale={[1.07, 1.06, 1.05]}
        />
        <mesh
          geometry={nodes.Cylinder002.geometry}
          material={materials.texture_bag_top}
          position={[-0.02, 2.84, -0.8]}
          rotation={[0, 0, Math.PI / 2]}
          scale={[0.8, 1.05, 0.8]}
        />
        <group position={[-0.47, 2.84, -0.8]} scale={[0.51, 0.21, 0.16]}>
          <mesh
            geometry={nodes.Cube014_1.geometry}
            material={materials.texture_helmet}
          />
          <mesh geometry={nodes.Cube014_2.geometry} material={materials.扣子} />
          <mesh
            geometry={nodes.Cube014_3.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <mesh
          geometry={nodes.Cube015.geometry}
          material={materials.texture_bag_bottom}
          position={[-0.02, 2.15, -0.77]}
          scale={[2.08, 2.49, 0.98]}
        />
        <mesh
          geometry={nodes.Cylinder003.geometry}
          material={materials["Bag-purple 2"]}
          position={[0.42, 2.01, -0.77]}
          rotation={[0, 0, -0.19]}
          scale={[-0.17, -0.22, -0.17]}
        />
        <group
          position={[0.47, 2, -0.77]}
          rotation={[0, 0, -0.17]}
          scale={[0.12, 0.15, 0.12]}
        >
          <mesh
            geometry={nodes.Cylinder004_1.geometry}
            material={materials["Material.013"]}
          />
          <mesh
            geometry={nodes.Cylinder004_2.geometry}
            material={materials.扣子}
          />
          <mesh
            geometry={nodes.Cylinder004_3.geometry}
            material={materials["Teeth-grey"]}
          />
        </group>
        <mesh
          geometry={nodes.Plane002.geometry}
          material={materials["Material.013"]}
          position={[-0.5, 0.92, -0.15]}
          rotation={[-0.64, -0.16, 1.57]}
          scale={[0.06, 0.06, 0.02]}
        />
        <mesh
          geometry={nodes.Plane003.geometry}
          material={materials["Material.013"]}
          position={[0.12, 0.95, -0.15]}
          rotation={[-0.65, -0.12, 1.51]}
          scale={[0.06, 0.06, 0.02]}
        />
        <mesh
          geometry={nodes.Cube012.geometry}
          material={materials.texture_shoe}
          position={[-0.29, 0.92, 0.04]}
          rotation={[0, 0.01, 0.07]}
          scale={[1.21, 1.08, 1.13]}
        />
        <group
          position={[-0.04, 3.33, -0.3]}
          rotation={[Math.PI / 2, -0.07, 0.42]}
          scale={[0.46, 0.47, 0.46]}
        >
          <mesh geometry={nodes.glasses.geometry} material={materials.金链} />
          <mesh
            geometry={nodes.glasses_1.geometry}
            material={materials["Material.008"]}
          />
        </group>
        <mesh
          geometry={nodes.头.geometry}
          material={materials.Face}
          position={[0, 3.29, -0.01]}
          scale={[1, 1, 1.03]}
        />
        <mesh
          geometry={nodes.耳朵.geometry}
          material={materials["Body-black"]}
          position={[-0.67, 3.73, -0.01]}
          scale={[0.96, 0.96, 0.99]}
        />
        <mesh
          geometry={nodes.Bamboo.geometry}
          material={materials["Material.003"]}
          position={[0.15, 2.92, 0.65]}
          rotation={[1.47, 0, -0.35]}
          scale={[0.05, 0.21, 0.05]}
        />
        <group
          position={[-0.39, 3.33, 0.44]}
          rotation={[-0.21, -0.62, -0.3]}
          scale={[0.93, 0.92, 0.94]}
        >
          <mesh geometry={nodes.Cube004.geometry} material={materials.Face} />
          <mesh
            geometry={nodes.Cube004_1.geometry}
            material={materials["Body-black"]}
          />
        </group>
        <group position={[0, 3.1, 0.65]} scale={[1, 1, 1.03]}>
          <mesh geometry={nodes.Cube005.geometry} material={materials.Face} />
          <mesh
            geometry={nodes.Cube005_1.geometry}
            material={materials["Body-black"]}
          />
        </group>
        <mesh
          geometry={nodes.Torus.geometry}
          material={materials["Teeth-grey"]}
          position={[0.04, 2.94, 0.48]}
          rotation={[0.01, 0.11, -0.05]}
          scale={[0.18, 0.19, 0.09]}
        />
        <mesh
          geometry={nodes.柱体.geometry}
          material={materials.texture_token_4}
          position={[0.72, 2.61, 0.66]}
          rotation={[Math.PI / 2, 0, 0.6]}
          scale={[0.17, 0.03, 0.17]}
        />
        <mesh
          geometry={nodes.Curve021.geometry}
          material={materials.texture_token_1}
          position={[0.76, 2.59, 0.69]}
          rotation={[Math.PI / 2, 0, 0.6]}
          scale={[0.17, 0.05, 0.17]}
        />
        <mesh
          geometry={nodes.Curve023.geometry}
          material={materials.texture_token_2}
          position={[0.7, 2.6, 0.65]}
          rotation={[Math.PI / 2, 0, 0.6]}
          scale={[0.17, 0.05, 0.17]}
        />
        <mesh
          geometry={nodes.Curve031.geometry}
          material={materials.texture_token_3}
          position={[0.78, 2.6, 0.7]}
          rotation={[Math.PI / 2, 0, 0.6]}
          scale={[0.17, 0.05, 0.17]}
        />
        <mesh
          geometry={nodes.柱体001.geometry}
          material={materials.texture_token_5}
          position={[0.72, 2.61, 0.66]}
          rotation={[Math.PI / 2, 0, 0.6]}
          scale={[0.13, 0.01, 0.13]}
        />
        <mesh
          geometry={nodes.球体.geometry}
          material={nodes.球体.material}
          position={[-0.27, 1.04, -0.01]}
          scale={[1, 1, 1.17]}
        />
        <mesh
          geometry={nodes.球体001.geometry}
          material={nodes.球体001.material}
          position={[0.32, 1.04, -0.01]}
          scale={[1, 1, 1.17]}
        />
      </group>
    </>
  );
}

// useGLTF.preload("/panda-transformed.glb");
