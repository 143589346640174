import React from "react";
import { Box, styled } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ArrowLeft from "../assets/icons/ArrowLeft";
import ArrowRight from "../assets/icons/ArrowRight";
import { Panel } from "../models";

const CustomSwiper = styled(Swiper)(({ theme }) => ({
  height: "100%",
  "& .swiper-pagination-bullet": {
    height: 4,
    width: 50,
    borderRadius: 3,
    background: theme.palette.text.secondary,
    [theme.breakpoints.down("lg")]: {
      width: 25,
    },
  },
  "& .swiper-pagination-bullet-active": {
    background: theme.palette.primary.main,
  },
  "& .swiper-pagination": {
    bottom: "50px !important",
    textAlign: "right",
    paddingLeft: 30,
    paddingRight: 30,
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
      bottom: "30px !important",
    },
  },
}));

interface Props {
  panelList: Panel[];
}

const FullPageCarousel: React.FC<Props> = ({ panelList }: Props) => {
  return (
    <Box component="div" sx={{ height: "100%" }}>
      <CustomSwiper
        modules={[Navigation, Pagination]}
        navigation={{
          enabled: true,
          prevEl: ".prev",
          nextEl: ".next",
        }}
        allowTouchMove={false}
        pagination
      >
        {panelList.map((panel: Panel) => (
          <SwiperSlide key={panel.key} style={{ height: "100%" }}>
            {panel.component}
          </SwiperSlide>
        ))}
      </CustomSwiper>
      <Box
        component="div"
        sx={{
          position: "absolute",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          bottom: {
            xs: 70,
            lg: 30,
          },
          zIndex: 99,
          "& .swiper-button-disabled img": {
            display: "none",
          },
        }}
      >
        <div
          className="prev"
          style={{ cursor: "pointer", height: 48, width: 48, marginRight: 30 }}
        >
          <ArrowLeft />
        </div>
        <div
          className="next"
          style={{ cursor: "pointer", height: 48, width: 48 }}
        >
          <ArrowRight />
        </div>
      </Box>
    </Box>
  );
};

export default FullPageCarousel;
