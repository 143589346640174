import React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { ConnectStepper } from "./ConnectStepper";
import {
  WalletProviderList,
  WalletProviderListProps,
} from "./WalletProviderList";
import { WalletProviderItemProps } from "./WalletProviderItem";
import { ConnectionFailedDialog } from "./ConnectionFailedDialog";
import { BaseModal, BaseModalProps } from "../BaseModal";
import { ButtonLarge } from "../CustomButtons";
export interface ConnectWalletModalProps
  extends Omit<BaseModalProps, "children" | "title"> {
  open: boolean;
  availableConnectors: WalletProviderItemProps[];
  selectedConnector?: WalletProviderItemProps;
  status: WalletProviderListProps["status"];
  onClose: () => void;
  onSelect: (index: number) => void;
  onRetry: () => void;
  onReset: () => void;
}

const DEFAULT_CLOSING_COUNTDOWN = 3;

const ConnectWalletModal: React.FC<ConnectWalletModalProps> = ({
  open,
  availableConnectors,
  status,
  selectedConnector,
  onClose,
  onSelect,
  onRetry,
  onReset,
}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [countdownInterval, setCountdownInterval] =
    React.useState<NodeJS.Timer>();
  const [countdown, setCountdown] = React.useState<number>(-1);

  const onChange = (index: number) => {
    setActiveStep(1);
    onSelect(index);
  };

  const handleOnReset = () => {
    setCountdown(-1);
    setCountdownInterval(undefined);
    setActiveStep(0);
    onReset();
  };

  React.useEffect(() => {
    if (selectedConnector) {
      setActiveStep(1);
    }
  }, [selectedConnector]);

  React.useEffect(() => {
    if (status === "success") {
      setActiveStep(2);
      setCountdown(DEFAULT_CLOSING_COUNTDOWN);
      setCountdownInterval(
        setInterval(() => {
          setCountdown((counting: number) => counting - 1);
        }, 1000)
      );
    }
  }, [status]);

  React.useEffect(() => {
    if (countdown === 0) {
      onClose();
      clearInterval(countdownInterval);
      setCountdownInterval(undefined);
    }
  }, [countdown, countdownInterval, onClose]);

  React.useEffect(() => {
    if (!open) {
      handleOnReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <BaseModal open={open} onClose={onClose} title="Connect Wallet">
        <ConnectStepper activeStep={activeStep} />
        <WalletProviderList
          list={availableConnectors}
          selected={selectedConnector?.id}
          status={status}
          onChange={onChange}
        />
        {status === "loading" && (
          <ButtonLarge
            fullWidth
            variant="contained"
            onClick={handleOnReset}
            sx={{
              marginTop: "1rem",
            }}
          >
            Back
          </ButtonLarge>
        )}
        {status === "success" && (
          <Typography
            variant="body2"
            component="p"
            sx={{
              marginTop: "0.5rem",
              color: theme.palette.grey[500],
              textAlign: "center",
            }}
          >
            Closing in {countdown}...
          </Typography>
        )}
      </BaseModal>
      <ConnectionFailedDialog
        open={status === "error"}
        onClose={onClose}
        onRetry={onRetry}
        onBack={handleOnReset}
      />
    </>
  );
};

export { ConnectWalletModal };
