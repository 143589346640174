import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import {
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useMemo,
} from "react";
import { Column, useBlockLayout, useTable } from "react-table";
import { FixedSizeList } from "react-window";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export interface InfiniteTableListProps<T extends object = any> {
  onRowClick: (index: number) => void;
  onLoadMore: () => Promise<void>;
  isItemLoaded: (index: number) => boolean;
  columns: Column<T>[];
  data?: T[];
  error?: Error;
  height: string | number;
}

const InfiniteTableList: React.FC<InfiniteTableListProps> = ({
  onRowClick,
  onLoadMore,
  isItemLoaded,
  columns,
  data,
  error,
  height,
}: InfiniteTableListProps) => {
  const memoizedColumns = useMemo(() => columns, [columns]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data: data || [], columns: memoizedColumns }, useBlockLayout);

  // All of the React Window libraries use the "Function-as-Child" pattern.
  const fixedSizeListChild = ({ index, style }: any) => {
    // Render a placeholder in each cell when the user scrolls to the bottom.
    // For this to work, you have to set itemCount to greater than rows.length in FixedSizeList.
    if (!isItemLoaded(index)) {
      return (
        // Note that we ARE converting all table elements to divs/spans, but it's done globally in the theme.
        <TableRow sx={{ width: "100%" }} style={style}>
          {columns.map(({ width }, i) => (
            <TableCell
              key={`skeleton-${i}`}
              sx={{
                display: "table-cell",
                boxSizing: "border-box",
                width,
              }}
            >
              <Skeleton variant="rectangular" animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      );
    }

    const row = rows[index];
    prepareRow(row);

    return (
      <TableRow
        {...row.getRowProps({ style })}
        onClick={() => onRowClick(index)}
      >
        {row.cells.map((cell) => {
          return (
            <TableCell
              {...cell.getCellProps()}
              sx={{
                overflow: "hidden",
                display: "table-cell",
                textOverflow: "ellipsis",
                width: cell.column.width,
              }}
            >
              {cell.render("Cell")}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const autoSizerChild = ({ height, width }: any) => {
    const infiniteLoaderChild = ({ onItemsRendered, ref }: any) => (
      <FixedSizeList
        height={height}
        width={width}
        itemSize={56}
        itemCount={rows.length + 20}
        onItemsRendered={onItemsRendered}
        ref={ref}
      >
        {fixedSizeListChild}
      </FixedSizeList>
    );

    return (
      <InfiniteLoader
        // Item count here is different than the prop passed to FixedSizeList.
        // This is when to stop loading more rows.
        // In the real world, this would would be a "count" or "total" property in your API response. E.g.,
        //   { items: [], total: 0 }
        itemCount={Infinity}
        // Threshold of 20 means start loading the next rows when the user is within 20 rows of the bottom.
        // Default is 15.
        threshold={20}
        isItemLoaded={isItemLoaded}
        loadMoreItems={onLoadMore}
      >
        {infiniteLoaderChild}
      </InfiniteLoader>
    );
  };

  const HeaderGroup = ({ headerGroup }: any) => (
    <TableRow {...headerGroup.getHeaderGroupProps()} sx={{ width: "100%" }}>
      {headerGroup.headers.map(
        (header: {
          getHeaderProps: () => JSX.IntrinsicAttributes & TableCellProps;
          render: (
            arg0: string
          ) =>
            | string
            | number
            | boolean
            | ReactElement<any, string | JSXElementConstructor<any>>
            | ReactFragment
            | ReactPortal
            | null
            | undefined;
          width: string;
        }) => (
          <TableCell
            {...header.getHeaderProps()}
            style={{ display: "table-cell", width: header.width }}
          >
            {header.render("Header")}
          </TableCell>
        )
      )}
    </TableRow>
  );

  // Or use yet another Brian Vaughn package, react-error-boundary.
  if (!!error) return <span>{(error as any)?.message}</span>;

  return (
    // Somewhere you have to set a fixed height, otherwise AutoSizer will set height to zero.
    // In the real world, this means accounting for navbars, footers, margin, etc., using calc().
    // Also, the Table component must be told to fill all available space with height: 100%.
    <TableContainer sx={{ height }}>
      <Table {...getTableProps()} sx={{ height: "100%", width: "100%" }}>
        <TableHead>
          {headerGroups.map((headerGroup, i) => (
            <HeaderGroup key={`header-group-${i}`} headerGroup={headerGroup} />
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          <AutoSizer>{autoSizerChild}</AutoSizer>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { InfiniteTableList };
