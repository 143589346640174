import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

export interface StoreItemProps {
  imageUrl: string;
  storeName: string;
  storeLocation?: string;
  images: string[];
  onClick?: () => void;
}

const StoreItem: React.FC<StoreItemProps> = ({
  imageUrl,
  storeName,
  storeLocation,
  onClick,
}: StoreItemProps) => {
  return (
    <Card
      sx={{
        display: "flex",
        borderRadius: "1rem",
        p: 2,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <CardMedia
        component="img"
        sx={{ width: 100, p: 1, borderRadius: "1.5rem" }}
        image={imageUrl}
        alt={storeName}
      />
      <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h6">
            {storeName}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {storeLocation}
          </Typography>
        </CardContent>
      </Box>
      <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
        <ArrowForwardIosRoundedIcon />
      </Box>
    </Card>
  );
};

export { StoreItem };
