import React from "react";
import { Box, styled, useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { GenesisBoxItem } from "./GenesisBoxItem";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";

const BoxCarousel = styled(Swiper)(({ theme }) => ({
  height: "100%",
  width: "100%",
  // padding: '0 2rem',
}));

export interface GenesisBoxCarouselProps {
  boxCount: number;
  boxArray: number[];
  value: number[];
  onChange: (selected: number[]) => void;
}

const GenesisBoxCarousel: React.FC<GenesisBoxCarouselProps> = ({
  boxCount,
  boxArray,
  value,
  onChange,
}: GenesisBoxCarouselProps) => {
  const isMobileView: boolean = useMediaQuery("(max-width:375px)");
  // const boxArray: number[] = new Array(boxCount).fill(0);

  const onItemCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: number[] = [...value];

    const itemValue: number = Number(event.target.value);
    if (event.target.checked) {
      newValue.push(itemValue);
    } else {
      newValue = newValue.filter((item: number) => item !== itemValue);
    }
    onChange(newValue);
  };

  return (
    <Box
      component="div"
      sx={{ width: "100%", display: "flex", alignItems: "center" }}
    >
      <Box
        component="div"
        className="box-carousel-prev"
        sx={{
          cursor: "pointer",
          height: 25,
          width: 25,
        }}
      >
        <ArrowLeft />
      </Box>
      <BoxCarousel
        slidesPerView={boxArray.length > 1 ? 2 : 1}
        spaceBetween={isMobileView ? 50 : 40}
        navigation={{
          enabled: true,
          prevEl: ".box-carousel-prev",
          nextEl: ".box-carousel-next",
        }}
        modules={[Navigation]}
        allowTouchMove={false}
        loop={boxArray.length > 1}
      >
        {boxArray.map((item: number, index: number) => (
          <div key={index}>
            <SwiperSlide style={{ height: "100%" }}>
              <GenesisBoxItem
                name="genesis-box"
                onChange={onItemCheck}
                value={item}
                checked={value.includes(item)}
              />
            </SwiperSlide>
          </div>
        ))}
      </BoxCarousel>
      <Box
        component="div"
        className="box-carousel-next"
        style={{ cursor: "pointer", height: 25, width: 25 }}
      >
        <ArrowRight />
      </Box>
    </Box>
  );
};

export default GenesisBoxCarousel;
