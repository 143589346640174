import React from "react";
import { BaseModal, BaseModalProps } from "../BaseModal";
import { PandaCarousel } from "./PandaCarousel";
import { IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface PandaModalProps
  extends Omit<BaseModalProps, "children" | "title"> {
  pandas: PandaNFT[];
}

const PandaModal: React.FC<PandaModalProps> = ({
  pandas,
  ...props
}: PandaModalProps) => {
  return (
    <BaseModal {...props} title="">
      <h2>Minted Successfully</h2>
      <PandaCarousel pandas={pandas} />
      <Box component="div" sx={{ textAlign: "center" }}>
        <IconButton
          onClick={props.onClose}
          sx={{
            borderRadius: "50%",
            border: (theme) => `1px solid ${theme.palette.text.primary}`,
            margin: "2rem auto",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </BaseModal>
  );
};

export { PandaModal };
