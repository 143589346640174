import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import Bloomberg from "../assets/pages/home/companies/bloomberg.svg";
import Forbes from "../assets/pages/home/companies/forbes.svg";
import Investing from "../assets/pages/home/companies/investing.svg";
import TechBullion from "../assets/pages/home/companies/tech-bullion.svg";
import CryptoVest from "../assets/pages/home/companies/crypto-vest.svg";
import ApaOts from "../assets/pages/home/companies/apa-ots.svg";
import FreedmanClub from "../assets/pages/home/companies/freedman-club.svg";
import MeThemerkle from "../assets/pages/home/companies/me-themerkle.svg";
import Steemit from "../assets/pages/home/companies/steemit.svg";
import KryptoMoney from "../assets/pages/home/companies/krypto-money.svg";
import Cision from "../assets/pages/home/companies/cision.svg";
import IcoBench from "../assets/pages/home/companies/ico-bench.svg";
import Leaprate from "../assets/pages/home/companies/leaprate.svg";
import WebIndia from "../assets/pages/home/companies/web-india.svg";
import BitcoinExchange from "../assets/pages/home/companies/bitcoin-exchange.svg";
import Chipin from "../assets/pages/home/companies/chipin.svg";
import BitcoinInsider from "../assets/pages/home/companies/bitcoin-insider.svg";
import CoinWisdom from "../assets/pages/home/companies/coin-wisdom.svg";
import Coindoo from "../assets/pages/home/companies/coindoo.svg";
import YahooFinance from "../assets/pages/home/companies/yahoo-fin.svg";
import YahooMoney from "../assets/pages/home/companies/yahoo-money.svg";
import YahooNews from "../assets/pages/home/companies/yahoo-news.svg";
import CryptoTrendings from "../assets/pages/home/companies/crypto-trendings.svg";
import DefiDaily from "../assets/pages/home/companies/defi-daily.svg";
import AsiaToken from "../assets/pages/home/companies/asia-token.svg";
import WorldCryptoTimes from "../assets/pages/home/companies/world-crypto-times.svg";
import CryptoHopes from "../assets/pages/home/companies/crypto-hopes.svg";
import TechInCats from "../assets/pages/home/companies/tech-in-cats.svg";
import CryptoGlobe from "../assets/pages/home/companies/crypto-globe.svg";
import Scoopnix from "../assets/pages/home/companies/scoopnix.svg";
import BlockoNews from "../assets/pages/home/companies/blocko-news.svg";
import NftNews from "../assets/pages/home/companies/nft-news.svg";
import FastAvow from "../assets/pages/home/companies/fast-avow.svg";
import Bnp from "../assets/pages/home/companies/bnp.svg";
import TheFounderDaily from "../assets/pages/home/companies/the-founder-daily.svg";
import ZyCrypto from "../assets/pages/home/companies/zy-crypto.svg";
import OmgFintech from "../assets/pages/home/companies/omg-fintech.svg";
import BullishNet from "../assets/pages/home/companies/bullish-net.svg";
import C from "../assets/pages/home/companies/c.svg";
import KryptoWings from "../assets/pages/home/companies/krypto-wings.svg";
import eBits from "../assets/pages/home/companies/eBits.svg";
import BloombergLight from "../assets/pages/home/companies/light/bloomberg.svg";
import ForbesLight from "../assets/pages/home/companies/light/forbes.svg";
import InvestingLight from "../assets/pages/home/companies/light/investing.svg";
import TechBullionLight from "../assets/pages/home/companies/light/tech-bullion.svg";
import CryptoVestLight from "../assets/pages/home/companies/light/crypto-vest.svg";
import ApaOtsLight from "../assets/pages/home/companies/light/apa-ots.svg";
import FreedmanClubLight from "../assets/pages/home/companies/light/freedman-club.svg";
import MeThemerkleLight from "../assets/pages/home/companies/light/me-themerkle.svg";
import SteemitLight from "../assets/pages/home/companies/light/steemit.svg";
import KryptoMoneyLight from "../assets/pages/home/companies/light/krypto-money.svg";
import CisionLight from "../assets/pages/home/companies/light/cision.svg";
import IcoBenchLight from "../assets/pages/home/companies/light/ico-bench.svg";
import LeaprateLight from "../assets/pages/home/companies/light/leaprate.svg";
import WebIndiaLight from "../assets/pages/home/companies/light/web-india.svg";
import BitcoinExchangeLight from "../assets/pages/home/companies/light/bitcoin-exchange.svg";
import ChipinLight from "../assets/pages/home/companies/light/chipin.svg";
import BitcoinInsiderLight from "../assets/pages/home/companies/light/bitcoin-insider.svg";
import CoinWisdomLight from "../assets/pages/home/companies/light/coin-wisdom.svg";
import CoindooLight from "../assets/pages/home/companies/light/coindoo.svg";
import YahooFinanceLight from "../assets/pages/home/companies/light/yahoo-fin.svg";
import YahooMoneyLight from "../assets/pages/home/companies/light/yahoo-money.svg";
import YahooNewsLight from "../assets/pages/home/companies/light/yahoo-news.svg";
import CryptoTrendingsLight from "../assets/pages/home/companies/light/crypto-trendings.svg";
import DefiDailyLight from "../assets/pages/home/companies/light/defi-daily.svg";
import AsiaTokenLight from "../assets/pages/home/companies/light/asia-token.svg";
import WorldCryptoTimesLight from "../assets/pages/home/companies/light/world-crypto-times.svg";
import CryptoHopesLight from "../assets/pages/home/companies/light/crypto-hopes.svg";
import TechInCatsLight from "../assets/pages/home/companies/light/tech-in-cats.svg";
import CryptoGlobeLight from "../assets/pages/home/companies/light/crypto-globe.svg";
import ScoopnixLight from "../assets/pages/home/companies/light/scoopnix.svg";
import BlockoNewsLight from "../assets/pages/home/companies/light/blocko-news.svg";
import NftNewsLight from "../assets/pages/home/companies/light/nft-news.svg";
import FastAvowLight from "../assets/pages/home/companies/light/fast-avow.svg";
import BnpLight from "../assets/pages/home/companies/light/bnp.svg";
import TheFounderDailyLight from "../assets/pages/home/companies/light/the-founder-daily.svg";
import ZyCryptoLight from "../assets/pages/home/companies/light/zy-crypto.svg";
import OmgFintechLight from "../assets/pages/home/companies/light/omg-fintech.svg";
import BullishNetLight from "../assets/pages/home/companies/light/bullish-net.svg";
import CLight from "../assets/pages/home/companies/light/c.svg";
import KryptoWingsLight from "../assets/pages/home/companies/light/krypto-wings.svg";
import eBitsLight from "../assets/pages/home/companies/light/eBits.svg";
import FashionTv from "../assets/pages/home/partners/fashion-tv.svg";
import AbitCapital from "../assets/pages/home/partners/abit-capital.svg";
import BlockchainCentre from "../assets/pages/home/partners/blockchain-centre.svg";
import OsVenture from "../assets/pages/home/partners/os-venture.svg";
import XakeFoundry from "../assets/pages/home/partners/xake-foundry.svg";
import ZetVentures from "../assets/pages/home/partners/zet-ventures.svg";
import S6Capital from "../assets/pages/home/partners/s6-capital.svg";
import SectionHeader from "./SectionHeader";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../features/appStateSlice";

const FeaturedCompanies: React.FC = () => {
  const tabletView = useMediaQuery("(max-width:960px)");
  const { darkMode } = useAppSelector(selectAppState);

  const companiesList = darkMode
    ? [
        Bloomberg,
        Forbes,
        Investing,
        TechBullion,
        CryptoVest,
        ApaOts,
        FreedmanClub,
        MeThemerkle,
        Steemit,
        KryptoMoney,
        Cision,
        IcoBench,
        Leaprate,
        WebIndia,
        BitcoinExchange,
        Chipin,
        BitcoinInsider,
        CoinWisdom,
        Coindoo,
        YahooFinance,
        YahooMoney,
        YahooNews,
        CryptoTrendings,
        DefiDaily,
        AsiaToken,
        WorldCryptoTimes,
        CryptoHopes,
        TechInCats,
        CryptoGlobe,
        Scoopnix,
        BlockoNews,
        NftNews,
        FastAvow,
        Bnp,
        TheFounderDaily,
        ZyCrypto,
        OmgFintech,
        BullishNet,
        C,
        KryptoWings,
        eBits,
      ]
    : [
        BloombergLight,
        ForbesLight,
        InvestingLight,
        TechBullionLight,
        CryptoVestLight,
        ApaOtsLight,
        FreedmanClubLight,
        MeThemerkleLight,
        SteemitLight,
        KryptoMoneyLight,
        CisionLight,
        IcoBenchLight,
        LeaprateLight,
        WebIndiaLight,
        BitcoinExchangeLight,
        ChipinLight,
        BitcoinInsiderLight,
        CoinWisdomLight,
        CoindooLight,
        YahooFinanceLight,
        YahooMoneyLight,
        YahooNewsLight,
        CryptoTrendingsLight,
        DefiDailyLight,
        AsiaTokenLight,
        WorldCryptoTimesLight,
        CryptoHopesLight,
        TechInCatsLight,
        CryptoGlobeLight,
        ScoopnixLight,
        BlockoNewsLight,
        NftNewsLight,
        FastAvowLight,
        BnpLight,
        TheFounderDailyLight,
        ZyCryptoLight,
        OmgFintechLight,
        BullishNetLight,
        CLight,
        KryptoWingsLight,
        eBitsLight,
      ];

  const partnersList = [
    FashionTv,
    AbitCapital,
    BlockchainCentre,
    OsVenture,
    XakeFoundry,
    ZetVentures,
    S6Capital,
  ];

  return (
    <>
      <SectionHeader content={["As", "Featured In"]} />
      <Box
        component="div"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-evenly",
          alignItems: "center",
          mb: 12,
          mt: {
            xs: 3,
            sm: 0,
          },
        }}
      >
        {companiesList.map((icon, index) => (
          <Box
            component="div"
            key={index}
            sx={{
              width: {
                xs: "25%",
                sm: "16.6666666%",
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 5,
            }}
          >
            <img
              src={icon}
              alt={index.toString()}
              style={{ maxWidth: tabletView ? "70px" : "unset" }}
            />
          </Box>
        ))}
      </Box>

      <SectionHeader content={["Our", "Partners & Backers"]} />
      <Box
        component="div"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: {
            xs: "100%",
            md: "70%",
          },
          justifyContent: "space-evenly",
          alignItems: "center",
          mb: 16,
        }}
      >
        {partnersList.map((icon, index) => (
          <Box
            component="div"
            key={index}
            sx={{
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 5,
              mt: {
                xs: 3,
                sm: 0,
              },
            }}
          >
            <img
              src={icon}
              alt={index.toString()}
              style={{ maxWidth: tabletView ? "70px" : "unset" }}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default FeaturedCompanies;
