import React from "react";
import { Marker } from "react-simple-maps";
import CountriesList from "../../assets/maps/countries-list.json";
import { CountryFlag } from "./CountryFlag";

type Country = typeof CountriesList[0] & {
	flagIcon: any;
};

const countriesWithFlag: Country[] = CountriesList.map((country) => ({
	...country,
	flagIcon: require(`/node_modules/circle-flags/flags/${country.code.toLocaleLowerCase()}.svg`),
}));

const CountryNodes: React.FC = () => {
	return (
		<>
			{countriesWithFlag.map(
				({ flagIcon, latitude, longitude }: Country, index: number) => (
					<Marker key={index} coordinates={[longitude, latitude]}>
						<CountryFlag filepath={flagIcon} />
					</Marker>
				)
			)}
		</>
	);
};

export { CountryNodes };
