import React from "react";
import { Annotation, Marker } from "react-simple-maps";
import { useTheme } from "@mui/material";
import { StoreList, StoreListProps } from "./StoreList";
import { StoreItemProps } from "./StoreItem";

export interface CityNodeProps {
  latitude: number;
  longitude: number;
  name: string;
  country: string;
  stores: StoreListProps["list"];
  onStoreClick?: (store: StoreItemProps) => void;
}

const CityNode: React.FC<CityNodeProps> = ({
  name,
  latitude,
  longitude,
  stores,
  country,
  onStoreClick,
}: CityNodeProps) => {
  const { palette } = useTheme();
  const anchorElement = React.useRef<SVGPathElement>(null);
  const storeListRef = React.useRef<HTMLElement>(null);
  const [showList, setShowList] = React.useState<boolean>(false);

  return (
    <>
      <Marker
        ref={anchorElement}
        coordinates={[longitude, latitude]}
        onMouseEnter={() => setShowList(true)}
        onMouseLeave={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
          if (
            storeListRef.current?.contains?.(event.relatedTarget as HTMLElement)
          ) {
            return;
          }
          setShowList(false);
        }}
        className="position-relative"
      >
        <circle r={3} fill={palette.primary.main} />
      </Marker>
      <Annotation
        subject={[longitude, latitude]}
        dx={0}
        dy={10}
        connectorProps={{
          stroke: "transparent",
          strokeWidth: 0,
          strokeLinecap: "round",
        }}
      >
        <text
          x="-8"
          textAnchor="end"
          alignmentBaseline="middle"
          fill={palette.primary.contrastText}
        >
          {name}
        </text>
      </Annotation>
      <StoreList
        open={showList}
        anchorEl={anchorElement.current}
        location={`${name}, ${country}`}
        list={stores}
        ref={storeListRef}
        onMouseLeave={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          if (
            event.relatedTarget instanceof Node &&
            anchorElement.current?.contains?.(
              event.relatedTarget as HTMLElement
            )
          ) {
            return;
          }
          setShowList(false);
        }}
        onItemClick={(index: number) => onStoreClick?.(stores[index])}
      />
    </>
  );
};

export { CityNode };
