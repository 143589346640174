import React from "react";

const ArrowRight: React.FC = () => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="23.5" stroke="currentColor" />
      <path
        d="M19.8809 34.5604L28.5742 25.8671C29.6009 24.8404 29.6009 23.1604 28.5742 22.1338L19.8809 13.4404"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
