import React from "react";
import {
  Box,
  Fade,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ButtonSmall, ButtonLarge, NavBar } from "../components";
import LandingBg from "../assets/graphics/landing-dark.gif";
import LandingBgLight from "../assets/graphics/landing-light.gif";
import { useNavigate } from "react-router-dom";
import Panda from "../assets/pages/landing/panda.svg";
import TwoPanda from "../assets/pages/landing/two-panda.svg";
import Underline from "../assets/pages/landing/underline.svg";
import YellowStar from "../assets/pages/landing/yellow-star.svg";
import BlueStar from "../assets/pages/landing/blue-star.svg";
import WhiteStar from "../assets/pages/landing/white-star.svg";
// import NFTCalender from "../assets/pages/landing/nft-calender.png";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../features/appStateSlice";
// import { Banner } from "../components/Banner";
// import Lootbox from "../assets/graphics/lootbox.gif";
// import { useCountdown } from "../utils/hooks/useCountdown";
// import { COUNTDOWN_START_DATE } from "../app/constants";
// import { useMintBoxContract } from "../utils/hooks/useMintBoxContract";

const Landing: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileView = useMediaQuery("(max-width:600px)");
  // const {
  //   countdownTimer: mintingStartCountdown,
  //   isCompleted: isMintingStartCountdownCompleted,
  // } = useCountdown(new Date(COUNTDOWN_START_DATE));

  // const { mintLeft } = useMintBoxContract({
  //   onReadError: () => {},
  //   mintQuantity: 0,
  //   mintConfig: {
  //     enabled: false,
  //     onError: () => {},
  //     onPrepareError: () => {},
  //   },
  // });

  const { darkMode } = useAppSelector(selectAppState);

  // const renderCountdown = () => {
  //   if (isMintingStartCountdownCompleted && mintLeft > 0) {
  //     return `${mintLeft} boxes left`;
  //   }
  //   if (isMintingStartCountdownCompleted && mintLeft <= 0) {
  //     return "Sold out";
  //   }
  //   return mintingStartCountdown;
  // };

  return (
    <Box
      component="div"
      sx={{
        height: window.innerHeight,
        backgroundColor: theme.palette.background.default,
        backgroundImage: `url(${
          darkMode ? LandingBg : LandingBgLight
        }), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 19.34%, rgba(0, 0, 0, 0) 84.55%, rgba(0, 0, 0, 0) 100%)`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "bottom",
        backgroundSize: "cover",
      }}
    >
      <Fade in={true} timeout={600}>
        <Box component="div" sx={{ height: "100%" }}>
          <NavBar />
          <Box component="div" sx={{ height: "100%" }}>
            <Toolbar sx={{ height: 120 }} />
            <Box
              component="div"
              sx={{
                height: "calc(100% - 120px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                pt: "15vh",
              }}
            >
              <Box
                component="div"
                sx={{
                  width: "fit-content",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant={mobileView ? "h4" : "h2"}
                  sx={{
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  Collect,{" "}
                  <img
                    src={Panda}
                    alt="Panda"
                    style={{ margin: "0 16px" }}
                    height={mobileView ? 70 : 138}
                  />
                  Participate
                </Typography>
                <Typography
                  variant={mobileView ? "h4" : "h2"}
                  sx={{
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  And Earn{" "}
                  <img
                    src={TwoPanda}
                    alt="Panda"
                    style={{ marginLeft: 16 }}
                    width={mobileView ? 150 : 310}
                  />
                </Typography>
                {/* {mobileView ? (
                  <ButtonLarge
                    variant="contained"
                    onClick={() => {
                      window.open(
                        "https://www.premint.xyz/nftv-panda-phase-3/",
                        "_blank"
                      );
                    }}
                    sx={{
                      ml: 3,
                      mr: 2,
                      mt: {
                        xs: 5,
                        sm: 7,
                      },
                      backgroundColor: "#FF6961",
                    }}
                  >
                    <Typography variant="h6">Apply For Whitelist</Typography>
                  </ButtonLarge>
                ) : null} */}

                <ButtonSmall
                  variant="contained"
                  sx={{
                    mt: {
                      xs: 4,
                      sm: 3,
                    },
                    background:
                      "linear-gradient(180deg,#6e80f3,#FF6961) !important",
                  }}
                  onClick={() =>
                    window.open("https://pandawarriors.club", "_blank")
                  }
                >
                  <Typography variant="h6">Enter PandaWarrior Club</Typography>
                </ButtonSmall>

                <ButtonSmall
                  variant="contained"
                  sx={{
                    mt: {
                      xs: 4,
                      sm: 3,
                    },
                    background: "#FFA07A !important",
                  }}
                  onClick={() => window.open("https://metamine.dev", "_blank")}
                >
                  <Typography variant="h6">Download Metamine</Typography>
                </ButtonSmall>

                <ButtonLarge
                  variant="contained"
                  sx={{
                    mt: {
                      xs: 2,
                      sm: 5,
                    },
                  }}
                  onClick={() => navigate("/app")}
                >
                  <Typography variant="h6">Enter NFTv Club</Typography>
                </ButtonLarge>
                <img
                  src={Underline}
                  alt="underline"
                  style={{
                    position: "absolute",
                    top: mobileView ? 55 : 110,
                    right: mobileView ? 0 : -20,
                  }}
                  width={mobileView ? 100 : 251}
                />
                <img
                  src={YellowStar}
                  alt="yellow-star"
                  style={{
                    position: "absolute",
                    bottom: mobileView ? 130 : 140,
                    left: mobileView ? -10 : -60,
                  }}
                  height={mobileView ? 30 : 86}
                />
                <img
                  src={BlueStar}
                  alt="blue-star"
                  style={{
                    position: "absolute",
                    top: mobileView ? 0 : 30,
                    right: mobileView ? -10 : -30,
                  }}
                />
                <img
                  src={WhiteStar}
                  alt="white-star"
                  style={{ position: "absolute", right: 0, bottom: 70 }}
                  height={mobileView ? 20 : 31}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
      {/* <Banner
        show
        withBorder
        withTransparent
        inline
        icon={
          <Box
            component="img"
            src={Lootbox}
            alt="Lootbox"
            sx={{
              width: {
                xs: "4.5rem",
                md: "6.5rem",
              },
            }}
          />
        }
        body={
          <Box component="div" sx={{ position: "relative" }}>
            <Typography
              sx={{
                fontSize: {
                  xs: "0.75rem",
                  md: "1.5rem",
                },
                fontWeight: "bold",
              }}
            >
              {renderCountdown()}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "0.85rem",
                  md: "1.5rem",
                  lg: "2rem",
                },
                background:
                  "linear-gradient(93.5deg, #D01AEE 1.14%, #9747FF 100.09%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                paddingRight: {
                  xs: "4rem",
                  md: "8rem",
                  lg: "10rem",
                },
              }}
            >
              Minting Phase 3
            </Typography>
            <ButtonSmall
              variant="contained"
              sx={{
                position: "absolute",
                right: {
                  xs: "0",
                  md: "1rem",
                },
                top: "20%",
                fontSize: {
                  xs: "0.4rem",
                  md: "1.1rem",
                  lg: "1.5rem",
                },
                translateY: "-20%",
                fontWeight: 600,
              }}
              onClick={() => navigate("/app/mint")}
            >
              LFG!
            </ButtonSmall>
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "align-left",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle2">As seen on</Typography>
              <Box
                component="img"
                src={NFTCalender}
                alt="NFTCalender"
                sx={{
                  width: {
                    xs: "3rem",
                    md: "4rem",
                  },
                  cursor: "pointer",
                }}
                onClick={() => window.open("https://nftcalendar.io", "_blank")}
              />
            </Box>
          </Box>
        }
        position="bottom-right"
      /> */}
    </Box>
  );
};

export default Landing;
