// export const NFTvGenesisBoxContractAddressGoerli =
//   "0x09F1Af5aD7ea91F3001E306224ddAEe184567cD2";
export const NFTvGenesisBoxContractAddressGoerli =
  "0xDdd269e12C8e1c92A08c2bfAf22d3157dD71CB04";
export const NFTvGenesisBoxContractAddressMainnet =
  "0x63FCfC109C530ec1361A7ADC24DCb58346Eced94";
export const COUNTDOWN_START_DATE = "Feb 14, 2023 20:00:00+08:00";
export const COUNTDOWN_END_DATE = "Sept 14, 2023 20:00:00+08:00";
export const NFTvPandaContractAddressGoerli =
  "0x2ddb9658c8E7CF4230E68c54fBDa0aaa41BFA6d6";
export const NFTvPandaContractAddressMainnet =
  "0xd88351b7c990a40688bf65df3f98c0b6cbe3c05c";
