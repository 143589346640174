import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import { ButtonLarge } from "../CustomButtons";
import { TraitCard } from "../TraitCard";
import { useProductGalleryContext } from "./GalleryContext";

const ProductDetails: React.FC = () => {
  const { selectedProduct } = useProductGalleryContext();

  return (
    <>
      <Box
        component="div"
        sx={{
          maxWidth: "100%",
          overflowY: "auto",
          width: "100%",
          height: "calc(100vh - 15rem)",
        }}
      >
        <Box
          component="img"
          src={selectedProduct?.imageUrl}
          alt={selectedProduct?.name}
          sx={{
            width: "100%",
            height: "auto",
            mb: "1rem",
          }}
        />
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mb: "0.5rem",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              flex: 1,
              mr: "0.5rem",
              fontWeight: 500,
              fontSize: {
                sm: "1rem",
                md: "1.25rem",
              },
            }}
          >
            {selectedProduct?.name}
          </Typography>
          <Chip
            label={selectedProduct?.rarity}
            sx={{
              padding: {
                sm: "0.2rem 0.25rem",
              },
              background: "linear-gradient(90deg, #FF9589 0%, #FF6CF0 100%)",
              fontSize: {
                sm: "0.85rem",
              },
            }}
          />
        </Box>
        <Typography
          sx={{
            color: (theme) => theme.palette.grey[500],
            fontSize: {
              xs: "0.85rem",
              sm: "1rem",
            },
            mb: "0.5rem",
          }}
        >
          Owned by {selectedProduct?.owner}
        </Typography>
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
            mb: "2rem",
          }}
        >
          {selectedProduct?.traits?.map((item, index: number) => (
            <TraitCard key={index} inline />
          ))}
        </Box>
        <ButtonLarge variant="contained" fullWidth>
          View in Marketplace
        </ButtonLarge>
      </Box>
    </>
  );
};

export { ProductDetails };
