import { Box, CircularProgress, Link } from "@mui/material";
import React from "react";
import GenesisBoxCarousel, {
  GenesisBoxCarouselProps,
} from "./GenesisBoxCarousel";

export interface GenesisBoxSelectionProps extends GenesisBoxCarouselProps {
  isWalletConnected: boolean;
  isLoading: boolean;
}

const GenesisBoxSelection: React.FC<GenesisBoxSelectionProps> = ({
  isWalletConnected,
  isLoading,
  boxCount,
  boxArray,
  onChange,
  value,
}: GenesisBoxSelectionProps) => {
  const renderContent = () => {
    if (isWalletConnected && boxCount === 0) {
      return (
        <>
          You have no genesis box. <Link href="/app/mint">Mint here</Link>{" "}
          {/** TODO: add mint box link */}
        </>
      );
    }
    if (isWalletConnected && boxCount > 0) {
      return (
        <GenesisBoxCarousel
          boxCount={boxCount}
          boxArray={boxArray}
          onChange={onChange}
          value={value}
        />
      );
    }
    if (isLoading) {
      return (
        <Box
          component="div"
          sx={{
            display: "inline-flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            color="primary"
            size="1.25rem"
            sx={{ marginRight: "1rem" }}
          />
          Loading...
        </Box>
      );
    }
    return <>Please connect your wallet.</>;
  };
  return (
    <Box
      component="div"
      sx={{
        height: "15rem",
        width: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {renderContent()}
    </Box>
  );
};

export { GenesisBoxSelection };
