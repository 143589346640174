/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 Chart.glb --transform --types
*/

import * as THREE from "three";
import React, { useEffect, useRef } from "react";
import { Line, Text, Torus, useAnimations, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import {
  Select,
  EffectComposer,
  SelectiveBloom,
  Vignette,
  Selection,
} from "@react-three/postprocessing";
import { useFrame } from "@react-three/fiber";

// Import glb file
import chartObj from "./chart-transformed.glb";

interface TitleWrapperProps {
  titleChildren: string;
  titlePosition: [number, number, number];
  titleAnchorX: "left" | "center" | "right";
  titleFontSize: number;
  iconArgs?: [number, number, number, number];
  iconPosition?: [number, number, number];
  iconColor?: string;
}

type GLTFResult = GLTF & {
  nodes: {
    sphere_ball_1: THREE.Mesh;
    sphere_ball_4: THREE.Mesh;
    sphere_ball_3: THREE.Mesh;
    sphere_ring_0: THREE.Mesh;
    sphere_ring_1: THREE.Mesh;
    sphere_ring_2: THREE.Mesh;
    sphere_ring_3: THREE.Mesh;
    sphere_ring_4: THREE.Mesh;
    sphere_ring_inner: THREE.Mesh;
  };
  materials: {
    material_balls: THREE.MeshStandardMaterial;
    material_base: THREE.MeshStandardMaterial;
    material_2: THREE.MeshStandardMaterial;
    material_3: THREE.MeshStandardMaterial;
    material_4: THREE.MeshStandardMaterial;
    material_5: THREE.MeshStandardMaterial;
    material_inner: THREE.MeshStandardMaterial;
  };
};

type ActionName =
  | "action_sphere_ball_1"
  | "action_sphere_ball_3"
  | "action_sphere_ball_4"
  | "sphere_ring_0"
  | "sphere_ring_1"
  | "sphere_ring_2"
  | "sphere_ring_3"
  | "sphere_ring_4"
  | "sphere_ring_inner";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export function Chart(
  props: JSX.IntrinsicElements["group"] & { darkMode: boolean }
) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials, animations } = useGLTF(chartObj) as GLTFResult;
  // @ts-ignore
  const { actions } = useAnimations<GLTFActions>(animations, group);

  // Play animation
  useEffect(() => {
    // @ts-ignore
    actions.action_sphere_ball_1.play();
    // @ts-ignore
    actions.action_sphere_ball_3.play();
    // @ts-ignore
    actions.action_sphere_ball_4.play();
  });

  const TitleWrapper = ({
    titleChildren,
    titlePosition,
    titleAnchorX,
    titleFontSize,
    iconArgs,
    iconPosition,
    iconColor,
  }: TitleWrapperProps) => {
    const textRef = useRef();
    const meshRef = useRef();

    useFrame(({ camera }) => {
      // @ts-ignore
      meshRef.current?.lookAt(camera.position);
      // @ts-ignore
      textRef.current?.lookAt(camera.position);
    });

    return (
      <>
        <Text
          ref={textRef}
          font="https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5mZNCzc.woff"
          fontSize={titleFontSize}
          position={titlePosition}
          anchorX={titleAnchorX}
          material={new THREE.MeshBasicMaterial({ color: "white" })}
          color={props.darkMode ? "white" : "black"}
        >
          {titleChildren}
          {iconArgs && (
            <Torus
              args={iconArgs}
              position={iconPosition}
              receiveShadow={false}
              castShadow={false}
            >
              <meshBasicMaterial color={iconColor} />
            </Torus>
          )}
        </Text>
      </>
    );
  };

  return (
    <Selection>
      <EffectComposer autoClear={false}>
        <SelectiveBloom
          luminanceThreshold={0}
          luminanceSmoothing={0.9}
          height={300}
        />
        <Vignette eskil={false} offset={0.1} darkness={1.1} />
      </EffectComposer>
      <Select enabled>
        <group ref={group} {...props} dispose={null}>
          <group name="Scene">
            <mesh
              name="sphere_ball_1"
              geometry={nodes.sphere_ball_1.geometry}
              material={materials.material_balls}
              position={[-0.63, 1.05, 1.12]}
              scale={1.31}
              material-emissive="#1EA2FF"
              material-emissiveIntensity={1}
              material-toneMapped={false}
            />
            <mesh
              name="sphere_ball_4"
              geometry={nodes.sphere_ball_4.geometry}
              material={materials.material_balls}
              position={[0.89, -0.31, -0.88]}
              material-emissive="#1EA2FF"
              material-emissiveIntensity={1}
              material-toneMapped={false}
            />
            <mesh
              name="sphere_ball_3"
              geometry={nodes.sphere_ball_3.geometry}
              material={materials.material_balls}
              position={[0.97, -0.05, 0.37]}
              scale={0.51}
              material-emissive="#1EA2FF"
              material-emissiveIntensity={1}
              material-toneMapped={false}
              // visible={false}
            />
            <mesh
              name="sphere_ring_0"
              geometry={nodes.sphere_ring_0.geometry}
              material={materials.material_base}
              position={[0, 0.11, -0.18]}
              rotation={[1.86, 0, 0]}
              material-emissive="#234CE7"
              material-emissiveIntensity={2}
              material-toneMapped={false}
            />
            <mesh
              name="sphere_ring_1"
              geometry={nodes.sphere_ring_1.geometry}
              material={materials.material_2}
              position={[-0.01, -0.14, 0.73]}
              rotation={[1.86, 0, 0]}
              scale={1.32}
              material-emissive="#6D38E7"
              material-emissiveIntensity={0.5}
              material-toneMapped={false}
            />
            <mesh
              name="sphere_ring_2"
              geometry={nodes.sphere_ring_2.geometry}
              material={materials.material_3}
              position={[0, -0.23, 0.98]}
              rotation={[1.86, 0, 0]}
              scale={1.04}
              material-emissive="#FF94D5"
              material-emissiveIntensity={0.5}
              material-toneMapped={false}
            />
            <mesh
              name="sphere_ring_3"
              geometry={nodes.sphere_ring_3.geometry}
              material={materials.material_4}
              position={[-0.03, -0.3, 1.16]}
              rotation={[1.86, 0, 0]}
              scale={0.76}
              material-emissive="#5B9FFF"
              material-emissiveIntensity={0.5}
              material-toneMapped={false}
            />
            <mesh
              name="sphere_ring_4"
              geometry={nodes.sphere_ring_4.geometry}
              material={materials.material_5}
              position={[-0.02, -0.34, 1.33]}
              rotation={[-0.09, 0, 0]}
              scale={0.22}
              material-emissive="#36E7CC"
              material-emissiveIntensity={0.75}
              material-toneMapped={false}
            />
            <mesh
              name="sphere_ring_inner"
              geometry={nodes.sphere_ring_inner.geometry}
              material={materials.material_inner}
              position={[-0.1, 0.05, 0.21]}
              rotation={[1.84, 0, -Math.PI]}
              scale={[-0.81, -0.01, -0.81]}
            />
          </group>
        </group>
      </Select>
      <Select position={[-1.05, 0, 0]}>
        <group>
          <Line
            points={[
              [-0.97, -0.5, 0.9],
              [-0.65, -0.75, 1.35],
              [-0.3, -0.75, 1.35],
            ]}
            color={props.darkMode ? "#ffffff" : "#000000"}
            lineWidth={1.5}
          />
          <TitleWrapper
            titlePosition={[-0.1, -0.75, 1.35]}
            titleAnchorX="left"
            titleFontSize={0.085}
            titleChildren="Seed Round 2%"
            iconArgs={[0.02, 0.01, 2, 90]}
            iconPosition={[-0.08, 0, 0]}
            iconColor="#49E0C9"
          />
        </group>
        <group>
          <Line
            points={[
              [-0.83, -0.2, 0.95],
              [-0.5, -0.5, 1.5],
              [-0.15, -0.5, 1.5],
            ]}
            color={props.darkMode ? "#ffffff" : "#000000"}
            lineWidth={1.5}
          />
          <TitleWrapper
            titlePosition={[0.05, -0.5, 1.5]}
            titleAnchorX="left"
            titleFontSize={0.085}
            titleChildren="Strategic Partners - 3%"
            iconArgs={[0.02, 0.01, 2, 90]}
            iconPosition={[-0.08, 0, 0]}
            iconColor="#8CBBFF"
          />
        </group>
        <group>
          <Line
            points={[
              [-0.8, 0.25, 0.75],
              [-0.4, 0.75, 1.15],
              [-0.1, 0.75, 1.15],
            ]}
            color={props.darkMode ? "#ffffff" : "#000000"}
            lineWidth={1.5}
          />
          <TitleWrapper
            titlePosition={[0.1, 0.75, 1.15]}
            titleAnchorX="left"
            titleFontSize={0.085}
            titleChildren="Private - 5%"
            iconArgs={[0.02, 0.01, 2, 90]}
            iconPosition={[-0.08, 0, 0]}
            iconColor="#FF94D5"
          />
        </group>
        <group>
          <Line
            points={[
              [-0.72, 0.4, 0.6],
              [-0.4, 1, 1],
              [-0.1, 1, 1],
            ]}
            color={props.darkMode ? "#ffffff" : "#000000"}
            lineWidth={1.5}
          />
          <TitleWrapper
            titlePosition={[0.075, 1, 1]}
            titleAnchorX="left"
            titleFontSize={0.085}
            titleChildren=" Team - 5%"
            iconArgs={[0.02, 0.01, 2, 90]}
            iconPosition={[-0.08, 0, 0]}
            iconColor="#6D38E7"
          />
        </group>
        <group>
          <Line
            points={[
              [0.2, 0, 0.75],
              [0.5, 0.4, 1.5],
              [0.7, 0.4, 1.5],
            ]}
            color={props.darkMode ? "#ffffff" : "#000000"}
            lineWidth={1.5}
          />
          <TitleWrapper
            titlePosition={[0.9, 0.4, 1.5]}
            titleAnchorX="left"
            titleFontSize={0.085}
            titleChildren="DAO Ecosystem - 85%"
            iconArgs={[0.02, 0.01, 2, 90]}
            iconPosition={[-0.08, 0, 0]}
            iconColor="#234CE7"
          />
        </group>
        <group>
          <Line
            points={[
              [0.2, 0, 0.75],
              [0.7, 0.05, 1.75],
            ]}
            color={props.darkMode ? "#ffffff" : "#000000"}
            lineWidth={1.5}
          />
          <TitleWrapper
            titlePosition={[0.8, 0.15, 1.75]}
            titleAnchorX="left"
            titleFontSize={0.2}
            titleChildren="NFTv TOKEN"
          />
          <TitleWrapper
            titlePosition={[0.8, -0.1, 1.75]}
            titleAnchorX="left"
            titleFontSize={0.2}
            titleChildren="2.1 Billions"
          />
        </group>
      </Select>
    </Selection>
  );
}

// useGLTF.preload("/Chart-transformed.glb");
