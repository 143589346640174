import React from "react";

interface Props {
  color: string;
}

const IconRound: React.FC<Props> = ({ color }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none">
      <circle cx="6" cy="6" r="5" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default IconRound;
