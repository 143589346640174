import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Skeleton,
} from "@mui/material";
import React from "react";
import { GridChildComponentProps } from "react-window";

export interface GridItemProps extends GridChildComponentProps {
  image: string;
  name: string;
  loading?: boolean;
  withCaption?: boolean;
  gutterSize: number;
  onClick: () => void;
}

const GridItem: React.FC<GridItemProps> = ({
  image,
  data,
  name,
  style,
  loading,
  withCaption,
  gutterSize,
  onClick,
}: GridItemProps) => {
  return (
    <Card
      sx={{
        background: "none",
      }}
      style={{
        ...style,
        left: `calc(${style?.left}px + ${gutterSize}px)`,
        top: `calc(${style?.top}px + ${gutterSize}px)`,
        width: `calc(${style?.width}px - ${gutterSize}px)`,
        height: withCaption
          ? "auto"
          : `calc(${style?.height}px - ${gutterSize}px)`,
      }}
    >
      <CardActionArea onClick={onClick}>
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height="100%" />
        ) : (
          <CardMedia
            component="img"
            image={image}
            sx={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              objectFit: "contain",
            }}
          />
        )}
        {withCaption && (
          <CardContent>
            <Typography gutterBottom variant="body1" component="h5">
              {name}
            </Typography>
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
};

export { GridItem };
