import React from "react";
import { Box, SxProps, Typography } from "@mui/material";

const PandaCarouselItem: React.FC<PandaNFT> = ({
  image,
  attributes,
  name,
}: PandaNFT) => {
  const alignText: SxProps = {
    textAlign: "center",
  };
  const rarity: any = React.useMemo(
    () => attributes?.find((item: any) => item.trait_type === "rarity")?.value,
    [attributes]
  );
  return (
    <Box component="div" sx={alignText}>
      <Box
        component="img"
        src={image}
        alt={name}
        sx={{
          width: {
            xs: "10rem",
            sm: "15rem",
          },
          marginBottom: "1rem",
        }}
      />
      <Box component="div">
        <Typography
          sx={{
            background: `linear-gradient(90deg, #FF9589 0%, #FF6CF0 100%);`,
            borderRadius: "3rem",
            display: "inline-block",
            padding: "0.5rem 1.5rem",
            fontSize: "1rem",
          }}
        >
          {rarity}
        </Typography>
      </Box>

      <Typography
        sx={{
          marginTop: "1rem",
          fontSize: "1.2rem",
        }}
      >
        Panda {name}
      </Typography>
    </Box>
  );
};

export { PandaCarouselItem };
