import React from "react";
import { useTheme } from "@mui/material";

const ArrowLeft: React.FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="23.5" stroke={theme.palette.text.primary} />
      <path
        d="M28.0004 34.5604L19.3071 25.8671C18.2804 24.8404 18.2804 23.1604 19.3071 22.1338L28.0004 13.4404"
        stroke={theme.palette.text.primary}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeft;
