import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

export interface WalletConnectionStatusProps {
  status?: "error" | "success" | "idle" | "loading";
}

const WalletConnectionStatus: React.FC<WalletConnectionStatusProps> = ({
  status,
}: WalletConnectionStatusProps) => {
  const theme = useTheme();

  const renderStatus = () => {
    switch (status) {
      case "loading":
        return (
          <Typography variant="overline" component="div">
            Connecting...
            <CircularProgress size="0.75rem" sx={{ marginLeft: "0.5rem" }} />
          </Typography>
        );
      case "success":
        return <CheckIcon sx={{ color: theme.palette.success.main }} />;
      default:
        return null;
    }
  };

  return (
    <Box
      component="div"
      sx={{
        position: "absolute",
        right: "1rem",
        top: "30%",
        translateY: "-30%",
      }}
    >
      {renderStatus()}
    </Box>
  );
};

export { WalletConnectionStatus };
