import React, { useState } from "react";
import {
  AppBar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import Logo from "../assets/logo.png";
import { ReactComponent as DiscordIcon } from "../assets/icons/discord.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/facebook.svg";
// import { ReactComponent as GithubIcon } from "../assets/icons/github.svg";
import { ReactComponent as GitBookIcon } from "../assets/icons/gitbook.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/twitter-square.svg";
import { ReactComponent as TiktokIcon } from "../assets/icons/tiktok.svg";
import { ReactComponent as OpenseaIcon } from "../assets/icons/opensea.svg";
// import { ReactComponent as EtherscanIcon } from "../assets/icons/etherscan.svg";
import { ReactComponent as MetamineIcon } from "../assets/icons/metamine.svg";
// import { ReactComponent as TelegramIcon } from "../assets/icons/telegram.svg";
import Wallet from "../assets/icons/wallet.svg";
import { useWeb3Context } from "../utils/hooks/useWeb3Context";
import { Link } from "react-router-dom";
import { ButtonLarge, ButtonSmall } from "./CustomButtons";
import { Close, CloseRounded, MenuRounded } from "@mui/icons-material";
import { submodules } from "../Router";
import { Footer } from "../components";
import ThemeSwitch from "./ThemeSwitch";
import { temporaryFunctionForLink } from "../modules/AppLayout";

const socialLinks = [
  {
    Icon: FacebookIcon,
    alt: "facebook",
    link: "https://www.facebook.com/nftv.social",
  },
  {
    Icon: TwitterIcon,
    alt: "twitter",
    link: "https://twitter.com/nftvsocial",
  },
  {
    Icon: InstagramIcon,
    alt: "instagram",
    link: "https://www.instagram.com/nftv.social/",
  },
  {
    Icon: DiscordIcon,
    alt: "discord",
    link: "https://discord.gg/nftvsocial",
  },
  {
    Icon: TiktokIcon,
    alt: "tiktok",
    link: "https://www.tiktok.com/@nftvsocialofficial",
  },
  // {
  //   Icon: GithubIcon,
  //   alt: "github",
  //   link: "https://github.com/Nftvsocial",
  // },
  {
    Icon: GitBookIcon,
    alt: "gitbook",
    link: "https://whitepaper.nftv.social/",
  },
  {
    Icon: OpenseaIcon,
    alt: "opensea",
    link: "https://opensea.io/collection/nftvgenesisbox",
  },
  {
    Icon: MetamineIcon,
    alt: "metamine",
    link: "https://metamine.dev",
  },
  // {
  //   Icon: EtherscanIcon,
  //   alt: "etherscan",
  //   link: "https://etherscan.io/token/0x40CEcAE57aEA36bDD4F1101C6723B7101BcE90F0",
  // },
  // {
  //   Icon: TelegramIcon,
  //   alt: "telegram",
  //   link: "https://t.me/nftvofficial",
  // },
];

const NavBar: React.FC = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery("(max-width:600px)");
  const {
    accountAddress: address,
    connected,
    disconnect,
    initConnection,
  } = useWeb3Context();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  return (
    <AppBar position="fixed" color="transparent" sx={{ boxShadow: "none" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: {
            xs: 80,
            md: 120,
          },
        }}
      >
        <Link
          to="/"
          style={{ textDecoration: "none", color: theme.palette.text.primary }}
        >
          <Box component="div" sx={{ display: "flex", alignItems: "baseline" }}>
            <img src={Logo} alt="NFTV" height={mobileView ? 20 : 36} />
            <Box
              component="div"
              sx={{
                fontWeight: 500,
                fontSize: {
                  xs: "1.2rem",
                  md: "2.2rem",
                },
              }}
            >
              .social
            </Box>
          </Box>
        </Link>
        <Box
          component="div"
          sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
        >
          <ThemeSwitch />
          {socialLinks.map(({ Icon, alt, link }) => (
            <IconButton
              key={alt}
              href={link}
              sx={{
                mx: 1,
                color: (theme) => theme.palette.text.primary,
                height: "2.5rem",
                width: "2.5rem",
              }}
              target="_blank"
            >
              <Icon style={{ height: "100%", width: "100%" }} />
            </IconButton>
          ))}
          {/* <ButtonLarge
            variant="contained"
            onClick={() => {
              window.open(
                "https://www.premint.xyz/nftv-panda-phase-2/",
                "_blank"
              );
            }}
            sx={{
              ml: 3,
              mr: 2,
              backgroundColor: "#FF6961",
            }}
          >
            <Typography variant="h6">Apply For Whitelist</Typography>
          </ButtonLarge> */}
          {connected ? (
            <Tooltip title="Disconnect">
              <ButtonLarge variant="contained" onClick={disconnect}>
                <Typography variant="h6">
                  {`${address?.slice(0, 5)} ... ${address?.slice(-5)}`}
                </Typography>
              </ButtonLarge>
            </Tooltip>
          ) : (
            <ButtonLarge variant="contained" onClick={initConnection}>
              <Typography variant="h6">Connect Wallet</Typography>
            </ButtonLarge>
          )}
        </Box>

        <Box
          component="div"
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
          }}
        >
          <Box component="div" sx={{ mt: 1 }}>
            <ThemeSwitch />
          </Box>
          {connected ? (
            <ButtonSmall
              variant="contained"
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
              endIcon={<Close />}
              onClick={disconnect}
            >
              {address?.slice(-5)}
            </ButtonSmall>
          ) : (
            <IconButton
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                },
              }}
              onClick={initConnection}
            >
              <img src={Wallet} alt="wallet" />
            </IconButton>
          )}
          <IconButton
            sx={{
              ml: 1,
              display: {
                xs: "block",
                md: "none",
              },
            }}
            onClick={() => setMobileDrawerOpen(!mobileDrawerOpen)}
          >
            <MenuRounded />
          </IconButton>
        </Box>

        <SwipeableDrawer
          anchor="left"
          open={mobileDrawerOpen}
          onClose={() => setMobileDrawerOpen(false)}
          onOpen={() => setMobileDrawerOpen(true)}
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
            "& .MuiPaper-root": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: window.innerHeight,
              backgroundColor: `${theme.palette.background.default} !important`,
              backgroundImage: "none",
              p: 1,
            },
          }}
        >
          <Box component="div">
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: 80,
                width: {
                  xs: "100vw",
                  sm: 524,
                },
              }}
            >
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.text.primary,
                }}
              >
                <Box
                  component="div"
                  sx={{ display: "flex", alignItems: "baseline" }}
                >
                  <img src={Logo} alt="NFTV" height={20} />
                  <Box
                    component="div"
                    sx={{ fontWeight: 500, fontSize: "1.2rem" }}
                  >
                    .social
                  </Box>
                </Box>
              </Link>
              <IconButton
                onClick={() => setMobileDrawerOpen(false)}
                sx={{ mr: { xs: 2, sm: 0 } }}
              >
                <CloseRounded />
              </IconButton>
            </Toolbar>
            <List>
              {submodules.map(({ index, label, path }) => (
                <ListItem key={index} disablePadding>
                  {index === 6 ? (
                    <ListItemButton
                      href={temporaryFunctionForLink(index)}
                      target="_blank"
                    >
                      <ListItemText primary={label} />
                    </ListItemButton>
                  ) : (
                    <ListItemButton href={`/app${path}`}>
                      <ListItemText primary={label} />
                    </ListItemButton>
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
          <Box component="div">
            {/* <ButtonLarge
              variant="contained"
              onClick={() => {
                window.open(
                  "https://www.premint.xyz/nftv-panda-phase-2/",
                  "_blank"
                );
              }}
              sx={{
                ml: 2,
                mr: 2,
                backgroundColor: "#FF6961",
              }}
            >
              <Typography variant="h6">Apply For Whitelist</Typography>
            </ButtonLarge> */}
            <Box
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 6,
                ml: 1,
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {socialLinks.map(({ Icon, alt, link }) => (
                <IconButton
                  key={alt}
                  href={link}
                  sx={{
                    mr: 2,
                    color: (theme) => theme.palette.text.primary,
                    height: "3rem",
                    width: "3rem",
                  }}
                  target="_blank"
                >
                  <Icon style={{ height: "100%", width: "100%" }} />
                </IconButton>
              ))}
            </Box>
            <Box component="div" sx={{ p: 2 }}>
              <Footer mobile />
            </Box>
          </Box>
        </SwipeableDrawer>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
