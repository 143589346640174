import { Skeleton, SkeletonProps } from '@mui/material';
import React from 'react';

export interface LoadingSkeletonProps extends SkeletonProps {
	isLoading: boolean;
	children?: React.ReactNode;
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({
	isLoading,
	children,
}: LoadingSkeletonProps) => {
	return isLoading ? (
		<Skeleton variant="text" sx={{ fontSize: '1rem' }}>
			{children}
		</Skeleton>
	) : (
		<>{children}</>
	);
};

export { LoadingSkeleton };
