/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 platform.glb --types --transform
*/

import * as THREE from "three";
// import { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import {
  EffectComposer,
  Vignette,
  SelectiveBloom,
} from "@react-three/postprocessing";

// Import glb file
import platformObj from "./platform-transformed.glb";

type GLTFResult = GLTF & {
  nodes: {
    Cone: THREE.Mesh;
    Cone003: THREE.Mesh;
    Cone006: THREE.Mesh;
    Cone008: THREE.Mesh;
    Cone002: THREE.Mesh;
    文本: THREE.Mesh;
    文本001: THREE.Mesh;
    文本002: THREE.Mesh;
    文本003: THREE.Mesh;
    Sphere001_1: THREE.Mesh;
    Sphere001_2: THREE.Mesh;
    Torus002: THREE.Mesh;
    柱体001: THREE.Mesh;
    立方体027: THREE.Mesh;
    Cylinder001_1: THREE.Mesh;
    Cylinder004: THREE.Mesh;
    立方体042: THREE.Mesh;
    Cylinder010: THREE.Mesh;
    Cylinder010_1: THREE.Mesh;
    spana: THREE.Mesh;
    Sphere007: THREE.Mesh;
    Sphere007_1: THREE.Mesh;
    Torus_1: THREE.Mesh;
    Torus_2: THREE.Mesh;
    bulb_shine: THREE.Mesh;
    Plane001: THREE.Mesh;
    Plane001_1: THREE.Mesh;
    Cylinder003: THREE.Mesh;
    Cylinder003_1: THREE.Mesh;
    Cylinder003_2: THREE.Mesh;
    平面001: THREE.Mesh;
    平面002_1: THREE.Mesh;
    Cube035: THREE.Mesh;
    Torus: THREE.Mesh;
    Torus001: THREE.Mesh;
    Torus004: THREE.Mesh;
    Torus005: THREE.Mesh;
    Torus006: THREE.Mesh;
    头: THREE.Mesh;
    Cube100: THREE.Mesh;
    Cube100_1: THREE.Mesh;
    Cube098_1: THREE.Mesh;
    Cube098_2: THREE.Mesh;
    Sphere011: THREE.Mesh;
    Sphere011_1: THREE.Mesh;
    Cylinder002: THREE.Mesh;
    Cylinder002_1: THREE.Mesh;
    圆环: THREE.Mesh;
    圆环_1: THREE.Mesh;
    立方体001: THREE.Mesh;
    立方体: THREE.Mesh;
    立方体003: THREE.Mesh;
    柱体005: THREE.Mesh;
    柱体007: THREE.Mesh;
    Cylinder056: THREE.Mesh;
    Cylinder056_1: THREE.Mesh;
    Cube053: THREE.Mesh;
    Torus003: THREE.Mesh;
    头001: THREE.Mesh;
    Cube117_1: THREE.Mesh;
    Cube117_2: THREE.Mesh;
    Cube119: THREE.Mesh;
    Cube119_1: THREE.Mesh;
    Sphere009: THREE.Mesh;
    Sphere009_1: THREE.Mesh;
    receipt: THREE.Mesh;
    文本005: THREE.Mesh;
    柱体_1: THREE.Mesh;
    柱体_2: THREE.Mesh;
    贝塞尔曲线: THREE.Mesh;
    立方体002: THREE.Mesh;
    柱体002: THREE.Mesh;
    立方体004: THREE.Mesh;
    柱体006: THREE.Mesh;
    柱体003_1: THREE.Mesh;
    立方体005_1: THREE.Mesh;
    立方体005_2: THREE.Mesh;
    柱体004: THREE.Mesh;
    Cube054: THREE.Mesh;
    头002: THREE.Mesh;
    Cube122_1: THREE.Mesh;
    Cube122_2: THREE.Mesh;
    Cube125: THREE.Mesh;
    Cube125_1: THREE.Mesh;
    White: THREE.Mesh;
    柱体001_1: THREE.Mesh;
    柱体001_2: THREE.Mesh;
    柱体001_3: THREE.Mesh;
    Black001: THREE.Mesh;
    Cylinder025: THREE.Mesh;
    Cylinder025_1: THREE.Mesh;
    Cylinder025_2: THREE.Mesh;
    Curve001: THREE.Mesh;
    Curve001_1: THREE.Mesh;
    Curve031: THREE.Mesh;
    文本004: THREE.Mesh;
    文本006: THREE.Mesh;
    文本008: THREE.Mesh;
    Cube001: THREE.Mesh;
    Cube006: THREE.Mesh;
    Cube122: THREE.Mesh;
    Cube013: THREE.Mesh;
    Cube018: THREE.Mesh;
    Cube021: THREE.Mesh;
    Cube022: THREE.Mesh;
    Cube024: THREE.Mesh;
    Cube025: THREE.Mesh;
    Cube028: THREE.Mesh;
    Cube029: THREE.Mesh;
    Cube071: THREE.Mesh;
    Cube030: THREE.Mesh;
    Cube031: THREE.Mesh;
    Cube032: THREE.Mesh;
    Cube033: THREE.Mesh;
    Cube034: THREE.Mesh;
    Cube036: THREE.Mesh;
    Cube038: THREE.Mesh;
    Cube039: THREE.Mesh;
    Cube040: THREE.Mesh;
    Cube041: THREE.Mesh;
    Cube042: THREE.Mesh;
    Cube043: THREE.Mesh;
    Cube044: THREE.Mesh;
    Cube045: THREE.Mesh;
    Cube046: THREE.Mesh;
    Cube047: THREE.Mesh;
    Cube050: THREE.Mesh;
    Cube051: THREE.Mesh;
    Cube066: THREE.Mesh;
    Cube072: THREE.Mesh;
    Cube056: THREE.Mesh;
    Cube057: THREE.Mesh;
    Cube062: THREE.Mesh;
    Cube058: THREE.Mesh;
    Cube059: THREE.Mesh;
    Cube060: THREE.Mesh;
    Cube061: THREE.Mesh;
    Cube063: THREE.Mesh;
    Cube065: THREE.Mesh;
    Cube067: THREE.Mesh;
    Cube068: THREE.Mesh;
    Cube069: THREE.Mesh;
    Cube070: THREE.Mesh;
    Cube074: THREE.Mesh;
    Cube076: THREE.Mesh;
    Cube077: THREE.Mesh;
    Cube075: THREE.Mesh;
    Cube078: THREE.Mesh;
    Cube009: THREE.Mesh;
    Cube081: THREE.Mesh;
    Cube082: THREE.Mesh;
    Cube084: THREE.Mesh;
    Cube089: THREE.Mesh;
    Cube092: THREE.Mesh;
    Cube097: THREE.Mesh;
    Cube098: THREE.Mesh;
    Cube048: THREE.Mesh;
    Cube049: THREE.Mesh;
    Cube052: THREE.Mesh;
    Cube102: THREE.Mesh;
    Cube105: THREE.Mesh;
    Cube106: THREE.Mesh;
    Cube111: THREE.Mesh;
    Cube113: THREE.Mesh;
    Cube114: THREE.Mesh;
    Cube117: THREE.Mesh;
    Cube118: THREE.Mesh;
    Cube121: THREE.Mesh;
    Cube055: THREE.Mesh;
    Cube064: THREE.Mesh;
    立方体020: THREE.Mesh;
    立方体022: THREE.Mesh;
    立方体024: THREE.Mesh;
    立方体019: THREE.Mesh;
    立方体031: THREE.Mesh;
    立方体033: THREE.Mesh;
    立方体043: THREE.Mesh;
    立方体044: THREE.Mesh;
    立方体045: THREE.Mesh;
    立方体049: THREE.Mesh;
    立方体050: THREE.Mesh;
    立方体052: THREE.Mesh;
    立方体054: THREE.Mesh;
    立方体055: THREE.Mesh;
    立方体057: THREE.Mesh;
    立方体059: THREE.Mesh;
    立方体061: THREE.Mesh;
    立方体046: THREE.Mesh;
    立方体023: THREE.Mesh;
    立方体034: THREE.Mesh;
    立方体035: THREE.Mesh;
    立方体036: THREE.Mesh;
    立方体029: THREE.Mesh;
    立方体030: THREE.Mesh;
    立方体041: THREE.Mesh;
    立方体062: THREE.Mesh;
    Arrow024: THREE.Mesh;
    Arrow030: THREE.Mesh;
    Arrow043: THREE.Mesh;
    Arrow044: THREE.Mesh;
    Arrow045: THREE.Mesh;
    Arrow046: THREE.Mesh;
    Arrow018: THREE.Mesh;
    Arrow023: THREE.Mesh;
    Arrow028: THREE.Mesh;
    Arrow034: THREE.Mesh;
    Arrow038: THREE.Mesh;
    Arrow040: THREE.Mesh;
    Arrow047: THREE.Mesh;
    Arrow033: THREE.Mesh;
    Arrow041: THREE.Mesh;
    Arrow042: THREE.Mesh;
    立方体032: THREE.Mesh;
    立方体040: THREE.Mesh;
    立方体047: THREE.Mesh;
    立方体048: THREE.Mesh;
    立方体063: THREE.Mesh;
    立方体021: THREE.Mesh;
    立方体028: THREE.Mesh;
    Cube002: THREE.Mesh;
    Cube012: THREE.Mesh;
    文本007: THREE.Mesh;
    Cube016: THREE.Mesh;
    Cube020: THREE.Mesh;
    Cube037: THREE.Mesh;
    Cube073: THREE.Mesh;
    Cube019: THREE.Mesh;
    Cube079: THREE.Mesh;
    empty: THREE.Mesh;
    自发光: THREE.Mesh;
  };
  materials: {
    Green: THREE.MeshPhysicalMaterial;
    ["Icon-Yellow 1"]: THREE.MeshStandardMaterial;
    ["Icon-green"]: THREE.MeshStandardMaterial;
    Skin: THREE.MeshStandardMaterial;
    ["Icon-pink"]: THREE.MeshStandardMaterial;
    ["Icon-white"]: THREE.MeshPhysicalMaterial;
    ["Icon-red"]: THREE.MeshStandardMaterial;
    ["Icon-dark grey"]: THREE.MeshStandardMaterial;
    ["Icon-dark purple"]: THREE.MeshStandardMaterial;
    Black: THREE.MeshStandardMaterial;
    ["Icon-grey"]: THREE.MeshStandardMaterial;
    ["body-black.001"]: THREE.MeshStandardMaterial;
    ["Face.001"]: THREE.MeshStandardMaterial;
    ["Icon-blue"]: THREE.MeshStandardMaterial;
    ["Icon- light blue"]: THREE.MeshStandardMaterial;
    ["Icon-Yellow 2"]: THREE.MeshStandardMaterial;
    ["Icon-Yellow 3"]: THREE.MeshStandardMaterial;
    wallet: THREE.MeshStandardMaterial;
    ["Cash-green 2"]: THREE.MeshStandardMaterial;
    ["Cash-green 1"]: THREE.MeshStandardMaterial;
    ["NFTv-purple"]: THREE.MeshStandardMaterial;
    ["NFTv-blue"]: THREE.MeshStandardMaterial;
    ["NFTv-dark purple"]: THREE.MeshStandardMaterial;
    自发光: THREE.MeshStandardMaterial;
    ["Floor-purple"]: THREE.MeshStandardMaterial;
    ["Floor-line"]: THREE.MeshStandardMaterial;
    ["Icon-Yellow 4"]: THREE.MeshStandardMaterial;
    ["Icon-blue 2"]: THREE.MeshStandardMaterial;
  };
};

// interface FaceViewProps {
//   geometry: THREE.BufferGeometry;
//   material: THREE.Material;
//   position: [number, number, number];
//   rotation: [number, number, number];
//   scale: [number, number, number];
//   materialEmissive: string;
//   materialEmissiveIntensity: number;
//   materialToneMapped: boolean;
// }

// function FaceView({
//   geometry,
//   material,
//   position,
//   rotation,
//   scale,
//   materialEmissive,
//   materialEmissiveIntensity,
//   materialToneMapped,
// }: FaceViewProps) {
//   const meshRef = useRef();

//   useFrame(({ camera }) => {
//     // @ts-ignore
//     meshRef.current?.lookAt(camera.position);
//   });

//   return (
//     <>
//       <mesh
//         // @ts-ignore
//         ref={meshRef}
//         geometry={geometry}
//         material={material}
//         position={position}
//         rotation={rotation}
//         scale={scale}
//         material-emissive={materialEmissive}
//         material-emissiveIntensity={materialEmissiveIntensity}
//         material-toneMapped={materialToneMapped}
//       />
//     </>
//   );
// }

export function Platform(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(platformObj) as GLTFResult;
  return (
    <>
      <EffectComposer autoClear={false}>
        <Vignette offset={0.1} darkness={1} />
        <SelectiveBloom />
      </EffectComposer>
      <group {...props} dispose={null}>
        <mesh
          geometry={nodes.Cone.geometry}
          material={materials.Green}
          position={[-3.64, 0.4, -3.64]}
          scale={[0.61, 0.46, 0.61]}
        />
        <mesh
          geometry={nodes.Cone003.geometry}
          material={materials.Green}
          position={[-6.76, 0.4, 2.6]}
          scale={[0.61, 0.46, 0.61]}
        />
        <mesh
          geometry={nodes.Cone006.geometry}
          material={materials.Green}
          position={[-2.6, 0.4, 6.76]}
          scale={[0.61, 0.46, 0.61]}
        />
        <mesh
          geometry={nodes.Cone008.geometry}
          material={materials.Green}
          position={[-3.64, 0.4, 2.6]}
          scale={[0.61, 0.46, 0.61]}
        />
        <mesh
          geometry={nodes.Cone002.geometry}
          material={materials.Green}
          position={[5.72, 0.4, -4.68]}
          scale={[0.61, 0.46, 0.61]}
        />
        <mesh
          geometry={nodes.文本.geometry}
          material={materials["Icon-Yellow 1"]}
          position={[-3.64, 0.63, 0.52]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.84}
        />
        <mesh
          geometry={nodes.文本001.geometry}
          material={materials["Icon-Yellow 1"]}
          position={[-0.52, 0.63, 3.64]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.84}
        />
        <mesh
          geometry={nodes.文本002.geometry}
          material={materials["Icon-Yellow 1"]}
          position={[2.6, 0.63, 2.6]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.84}
        />
        <mesh
          geometry={nodes.文本003.geometry}
          material={materials["Icon-Yellow 1"]}
          position={[-0.52, 0.63, -3.64]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.84}
        />
        <group position={[0.52, 0.77, 3.63]} scale={0.06}>
          <mesh
            geometry={nodes.Sphere001_1.geometry}
            material={materials["Icon-green"]}
          />
          <mesh
            geometry={nodes.Sphere001_2.geometry}
            material={materials.Skin}
          />
        </group>
        <mesh
          geometry={nodes.Torus002.geometry}
          material={materials["Icon-pink"]}
          position={[0.52, 0.77, 3.64]}
          rotation={[Math.PI / 2, -0.06, 0]}
          scale={0.31}
        />
        <mesh
          geometry={nodes.柱体001.geometry}
          material={materials["Icon-pink"]}
          position={[0.52, 0.46, 3.64]}
          scale={[0.03, 0.06, 0.03]}
        />
        <mesh
          geometry={nodes.立方体027.geometry}
          material={materials["Icon-pink"]}
          position={[-4.68, 0.62, -0.52]}
          scale={[0.31, 0.23, 0.06]}
        >
          {/* <mesh
            geometry={nodes.Cylinder001_1.geometry}
            material={materials["Icon-white"]}
            position={[-0.1, 0, 0]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1.15, 8.42, 0.85]}
          /> */}
        </mesh>
        <mesh
          geometry={nodes.Cylinder004.geometry}
          material={materials["Icon-white"]}
          position={[-2.63, 0.62, -0.52]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={[0.26, 0.47, 0.26]}
        >
          <mesh
            geometry={nodes.立方体042.geometry}
            material={materials["Icon-pink"]}
            position={[0, 0, -0.11]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            scale={[1.18, 0.87, 0.12]}
          />
        </mesh>
        <group
          position={[3.6, 0.53, -4.69]}
          rotation={[Math.PI / 2, 0.96, -Math.PI / 2]}
          scale={0.85}
        >
          <mesh
            geometry={nodes.Cylinder010.geometry}
            material={materials["Icon-red"]}
          />
          <mesh
            geometry={nodes.Cylinder010_1.geometry}
            material={materials["Icon-dark grey"]}
          />
        </group>
        <mesh
          geometry={nodes.spana.geometry}
          material={materials["Icon-dark grey"]}
          position={[3.71, 0.5, -4.74]}
          rotation={[Math.PI / 2, -0.96, -Math.PI]}
          scale={0.21}
        />
        <group position={[6.25, 0.63, 0]} scale={0.51}>
          <mesh
            geometry={nodes.Sphere007.geometry}
            material={materials["Icon-white"]}
          />
          <mesh
            geometry={nodes.Sphere007_1.geometry}
            material={materials["Icon-pink"]}
          />
        </group>
        <group
          position={[5.77, 1.52, -0.17]}
          rotation={[1.2, 0.33, 0.07]}
          scale={[0.03, 0.03, 0.08]}
        >
          <mesh
            geometry={nodes.Torus_1.geometry}
            material={materials["Icon-dark purple"]}
          />
          <mesh
            geometry={nodes.Torus_2.geometry}
            material={materials["Icon-Yellow 1"]}
          />
          <mesh
            geometry={nodes.bulb_shine.geometry}
            material={nodes.bulb_shine.material}
            position={[0.01, 0.2, -3.25]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.22, 0.38, 0.22]}
          />
        </group>
        <group
          position={[6.54, 0.69, 0.36]}
          rotation={[0.03, 0.63, -0.21]}
          scale={[0.19, 0.18, 0.12]}
        >
          <mesh geometry={nodes.Plane001.geometry} material={materials.Black} />
          <mesh
            geometry={nodes.Plane001_1.geometry}
            material={materials["Icon-white"]}
          />
        </group>
        <group
          position={[5.89, 0.84, 0.21]}
          rotation={[0.52, -0.99, -1.65]}
          scale={[0.18, 0.32, 0.18]}
        >
          <mesh
            geometry={nodes.Cylinder003.geometry}
            material={materials["Icon-red"]}
          />
          <mesh
            geometry={nodes.Cylinder003_1.geometry}
            material={materials.Skin}
          />
          <mesh
            geometry={nodes.Cylinder003_2.geometry}
            material={materials["Icon-dark purple"]}
          />
        </group>
        <mesh
          geometry={nodes.平面001.geometry}
          material={materials["Icon-white"]}
          position={[6.71, 0.83, 0.04]}
          rotation={[1.52, -0.62, 0.91]}
          scale={[0.09, 0.09, 0.11]}
        >
          <mesh
            geometry={nodes.平面002_1.geometry}
            material={materials["Icon-grey"]}
            position={[-0.42, -0.21, -0.26]}
          />
        </mesh>
        <mesh
          geometry={nodes.Cube035.geometry}
          material={materials["body-black.001"]}
          position={[5.97, 1.36, -0.02]}
          scale={[0.4, 0.4, 0.41]}
        />
        <mesh
          geometry={nodes.Torus.geometry}
          material={materials["Icon-grey"]}
          position={[6.26, 1.04, 0.22]}
          rotation={[0.01, 0.11, -0.05]}
          scale={[0.08, 0.08, 0.04]}
        />
        <mesh
          geometry={nodes.Torus001.geometry}
          material={materials["Icon-grey"]}
          position={[6.26, 0.99, 0.19]}
          rotation={[0.49, 0.17, 0.07]}
          scale={[0.08, 0.08, 0.03]}
        />
        <mesh
          geometry={nodes.Torus004.geometry}
          material={materials["Icon-grey"]}
          position={[0.01, 0.99, 6.48]}
          rotation={[0.49, 0.17, 0.07]}
          scale={[0.08, 0.08, 0.03]}
        />
        <mesh
          geometry={nodes.Torus005.geometry}
          material={materials["Icon-grey"]}
          position={[-7.34, 1.04, 0.21]}
          rotation={[0.01, 0.11, -0.05]}
          scale={[0.08, 0.08, 0.04]}
        />
        <mesh
          geometry={nodes.Torus006.geometry}
          material={materials["Icon-grey"]}
          position={[-7.34, 0.99, 0.18]}
          rotation={[0.49, 0.17, 0.07]}
          scale={[0.08, 0.08, 0.03]}
        />
        <mesh
          geometry={nodes.头.geometry}
          material={materials["Face.001"]}
          position={[6.25, 1.18, -0.02]}
          scale={[0.42, 0.42, 0.43]}
        />
        <group
          position={[6.09, 1.2, 0.14]}
          rotation={[-0.21, -0.62, -0.3]}
          scale={0.39}
        >
          <mesh
            geometry={nodes.Cube100.geometry}
            material={materials["Face.001"]}
          />
          <mesh
            geometry={nodes.Cube100_1.geometry}
            material={materials["body-black.001"]}
          />
        </group>
        <group position={[6.25, 1.1, 0.26]} scale={[0.42, 0.42, 0.43]}>
          <mesh
            geometry={nodes.Cube098_1.geometry}
            material={materials["Face.001"]}
          />
          <mesh
            geometry={nodes.Cube098_2.geometry}
            material={materials["body-black.001"]}
          />
        </group>
        <group position={[0, 0.63, 6.3]} scale={0.51}>
          <mesh
            geometry={nodes.Sphere011.geometry}
            material={materials["Icon-dark purple"]}
          />
          <mesh
            geometry={nodes.Sphere011_1.geometry}
            material={materials["Icon-white"]}
          />
        </group>
        <group
          position={[-0.52, 0.82, 6.53]}
          rotation={[-1.84, 0.05, 2.97]}
          scale={[-0.02, -1.36, -0.02]}
        >
          <mesh
            geometry={nodes.Cylinder002.geometry}
            material={materials.Skin}
          />
          <mesh
            geometry={nodes.Cylinder002_1.geometry}
            material={materials["Icon-red"]}
          />
        </group>
        <group
          position={[-0.48, 0.96, 6.26]}
          rotation={[1.3, -0.05, 0.14]}
          scale={0.2}
        >
          <mesh
            geometry={nodes.圆环.geometry}
            material={materials["Icon-red"]}
          />
          <mesh
            geometry={nodes.圆环_1.geometry}
            material={materials["Icon-white"]}
          />
        </group>
        <mesh
          geometry={nodes.立方体001.geometry}
          material={materials["Icon-blue"]}
          position={[-0.46, 1.64, 6.13]}
          scale={[0.15, 0.12, 0.02]}
        />
        <mesh
          geometry={nodes.立方体.geometry}
          material={materials["Icon-red"]}
          position={[-7.87, 1.59, -0.22]}
          scale={[0.15, 0.12, 0.02]}
        />
        <mesh
          geometry={nodes.立方体003.geometry}
          material={materials["Icon-white"]}
          position={[-0.46, 1.64, 6.16]}
          scale={[0.03, 0.09, 0.01]}
        />
        <mesh
          geometry={nodes.柱体005.geometry}
          material={materials["Icon-green"]}
          position={[0.23, 0.8, 6.67]}
          rotation={[0, 0.7, 0.71]}
          scale={[0.12, 0.03, 0.12]}
        />
        <mesh
          geometry={nodes.柱体007.geometry}
          material={materials["Icon-blue"]}
          position={[0.23, 0.84, 6.65]}
          rotation={[0, 0.7, 0.71]}
          scale={[0.12, 0.03, 0.12]}
        />
        <group
          position={[0.4, 1.1, 6.2]}
          rotation={[-0.5, -1.03, -1.84]}
          scale={0.07}
        >
          <mesh
            geometry={nodes.Cylinder056.geometry}
            material={materials["Icon-red"]}
          />
          <mesh
            geometry={nodes.Cylinder056_1.geometry}
            material={materials["Icon-white"]}
          />
        </group>
        <mesh
          geometry={nodes.Cube053.geometry}
          material={materials["body-black.001"]}
          position={[-0.28, 1.36, 6.28]}
          scale={[0.4, 0.4, 0.41]}
        />
        <mesh
          geometry={nodes.Torus003.geometry}
          material={materials["Icon-grey"]}
          position={[0, 1.04, 6.51]}
          rotation={[0.01, 0.11, -0.05]}
          scale={[0.08, 0.08, 0.04]}
        />
        <mesh
          geometry={nodes.头001.geometry}
          material={materials["Face.001"]}
          position={[0, 1.18, 6.28]}
          scale={[0.42, 0.42, 0.43]}
        />
        <group
          position={[-0.16, 1.2, 6.47]}
          rotation={[-0.21, -0.62, -0.3]}
          scale={0.39}
        >
          <mesh
            geometry={nodes.Cube117_1.geometry}
            material={materials["Face.001"]}
          />
          <mesh
            geometry={nodes.Cube117_2.geometry}
            material={materials["body-black.001"]}
          />
        </group>
        <group position={[0, 1.1, 6.55]} scale={[0.42, 0.42, 0.43]}>
          <mesh
            geometry={nodes.Cube119.geometry}
            material={materials["Face.001"]}
          />
          <mesh
            geometry={nodes.Cube119_1.geometry}
            material={materials["body-black.001"]}
          />
        </group>
        <group position={[-7.34, 0.63, 0]} scale={0.51}>
          <mesh
            geometry={nodes.Sphere009.geometry}
            material={materials["Icon- light blue"]}
          />
          <mesh
            geometry={nodes.Sphere009_1.geometry}
            material={materials["Icon- light blue"]}
          />
        </group>
        <mesh
          geometry={nodes.receipt.geometry}
          material={nodes.receipt.material}
          position={[-7.07, 0.75, 0.32]}
          rotation={[-2.74, -1.02, 2.79]}
          scale={[0.35, 0.27, 0.07]}
        />
        <mesh
          geometry={nodes.文本005.geometry}
          material={materials["Icon-Yellow 2"]}
          position={[-6.71, 1.23, -0.03]}
          rotation={[1.39, -0.05, 0.28]}
          scale={0.12}
        />
        <group
          position={[-6.7, 1.23, -0.04]}
          rotation={[1.39, -0.05, 0.28]}
          scale={[0.11, 0.03, 0.11]}
        >
          <mesh
            geometry={nodes.柱体_1.geometry}
            material={materials["Icon-Yellow 2"]}
          />
          <mesh
            geometry={nodes.柱体_2.geometry}
            material={materials["Icon-Yellow 3"]}
          />
        </group>
        <mesh
          geometry={nodes.贝塞尔曲线.geometry}
          material={materials["Icon-white"]}
          position={[-7.87, 1.6, -0.2]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={0.06}
        />
        <mesh
          geometry={nodes.立方体002.geometry}
          material={materials.wallet}
          position={[-7.65, 0.68, 0.31]}
          rotation={[-Math.PI, 0.61, -Math.PI]}
          scale={[0.16, 0.13, 0.06]}
        >
          <mesh
            geometry={nodes.柱体002.geometry}
            material={materials["Icon-white"]}
            position={[-0.47, 0, 1.16]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.13, 0.04, 0.16]}
          />
          <mesh
            geometry={nodes.立方体004.geometry}
            material={materials.wallet}
            position={[-0.49, 0, 0]}
            scale={[-0.58, -0.23, -1.17]}
          >
            <mesh
              geometry={nodes.柱体006.geometry}
              material={materials["Icon-white"]}
              position={[-0.04, 0, 0.99]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[-0.22, -0.03, -0.72]}
            />
          </mesh>
          <group
            position={[-0.16, 0.73, -0.07]}
            rotation={[0, 0, 1.07]}
            scale={[0.4, 0.56, 0.06]}
          >
            <mesh
              geometry={nodes.立方体005_1.geometry}
              material={materials["Cash-green 2"]}
            />
            <mesh
              geometry={nodes.立方体005_2.geometry}
              material={materials["Cash-green 1"]}
            />
            <mesh
              geometry={nodes.柱体003_1.geometry}
              material={materials["Cash-green 1"]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[0.44, 1.16, 0.28]}
            />
          </group>
          <group
            position={[0.05, 0.94, 0.09]}
            rotation={[0, 0, 1.07]}
            scale={[0.4, 0.56, 0.06]}
          >
            <mesh
              geometry={nodes.立方体005_1.geometry}
              material={materials["Cash-green 2"]}
            />
            <mesh
              geometry={nodes.立方体005_2.geometry}
              material={materials["Cash-green 1"]}
            />
            <mesh
              geometry={nodes.柱体004.geometry}
              material={materials["Cash-green 1"]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[0.44, 1.16, 0.28]}
            />
          </group>
        </mesh>
        <mesh
          geometry={nodes.Cube054.geometry}
          material={materials["body-black.001"]}
          position={[-7.63, 1.36, -0.02]}
          scale={[0.4, 0.4, 0.41]}
        />
        <mesh
          geometry={nodes.头002.geometry}
          material={materials["Face.001"]}
          position={[-7.35, 1.18, -0.02]}
          scale={[0.42, 0.42, 0.43]}
        />
        <group
          position={[-7.51, 1.2, 0.17]}
          rotation={[-0.21, -0.62, -0.3]}
          scale={0.39}
        >
          <mesh
            geometry={nodes.Cube122_1.geometry}
            material={materials["Face.001"]}
          />
          <mesh
            geometry={nodes.Cube122_2.geometry}
            material={materials["body-black.001"]}
          />
        </group>
        <group position={[-7.35, 1.1, 0.25]} scale={[0.42, 0.42, 0.43]}>
          <mesh
            geometry={nodes.Cube125.geometry}
            material={materials["Face.001"]}
          />
          <mesh
            geometry={nodes.Cube125_1.geometry}
            material={materials["body-black.001"]}
          />
        </group>
        <mesh
          geometry={nodes.White.geometry}
          material={materials["Icon-white"]}
          position={[-5.72, 0.61, 0.59]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.29, 0.36, 0.29]}
        />
        <group
          position={[-5.72, 0.61, 0.52]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.15, 0.07, 0.15]}
        >
          <mesh
            geometry={nodes.柱体001_1.geometry}
            material={materials["Icon-dark purple"]}
          />
          <mesh
            geometry={nodes.柱体001_2.geometry}
            material={materials.Black}
          />
          <mesh
            geometry={nodes.柱体001_3.geometry}
            material={materials["Icon-white"]}
          />
        </group>
        <mesh
          geometry={nodes.Black001.geometry}
          material={materials.Black}
          position={[-5.72, 0.61, 0.52]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.15, 0.07, 0.15]}
        />
        <group
          position={[5.72, 0.61, 4.77]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={0.11}
        >
          <mesh
            geometry={nodes.Cylinder025.geometry}
            material={materials["Icon-red"]}
          />
          <mesh
            geometry={nodes.Cylinder025_1.geometry}
            material={materials["Icon-white"]}
          />
          <mesh
            geometry={nodes.Cylinder025_2.geometry}
            material={materials["Icon-white"]}
          />
        </group>
        <group position={[-0.25, 1.44, 0]}>
          <mesh
            geometry={nodes.Curve001.geometry}
            material={materials["NFTv-purple"]}
            material-color="#9850FF"
          />
          <mesh
            geometry={nodes.Curve001_1.geometry}
            material={materials["NFTv-blue"]}
            material-color="#4736FF"
          />
        </group>
        <mesh
          geometry={nodes.Curve031.geometry}
          material={materials["NFTv-dark purple"]}
          position={[0, 0.07, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1.92, 0.26, 1.92]}
          material-color="#7F238F"
        />
        {/* <FaceView
          geometry={nodes.文本004.geometry}
          material={materials.自发光}
          position={[0, 0.62, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.38, 0.23, 0.38]}
          materialEmissive="#D4A8FF"
          materialEmissiveIntensity={8}
          materialToneMapped={false}
        /> */}
        <mesh
          geometry={nodes.文本004.geometry}
          material={materials.自发光}
          position={[0, 0.62, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.38, 0.23, 0.38]}
          material-emissive="#D4A8FF"
          material-emissiveIntensity={8}
          material-toneMapped={false}
        />
        <mesh
          geometry={nodes.文本006.geometry}
          material={materials["Icon-white"]}
          position={[-7.35, 0.13, 0.74]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.21, 0.14, 0.23]}
        />
        <mesh
          geometry={nodes.文本008.geometry}
          material={materials["Icon-white"]}
          position={[6.25, 0.13, 0.74]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.21, 0.14, 0.23]}
        />
        <mesh
          geometry={nodes.Cube001.geometry}
          material={materials["Floor-purple"]}
          position={[-4.68, 0.03, -0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube006.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, -5.12]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube122.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, -0.96]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube013.geometry}
          material={materials["Floor-purple"]}
          position={[-6.76, 0.03, 3.64]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube018.geometry}
          material={materials["Floor-purple"]}
          position={[-3.64, 0.03, 2.6]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube021.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, -0.95]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube022.geometry}
          material={materials.Black}
          position={[-5.72, 0.03, 4.68]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube024.geometry}
          material={materials["Floor-purple"]}
          position={[-3.64, 0.03, 6.76]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube025.geometry}
          material={materials["Floor-purple"]}
          position={[-5.72, 0.02, 0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube028.geometry}
          material={materials["Floor-purple"]}
          position={[-4.68, 0.02, 0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube029.geometry}
          material={materials["Floor-purple"]}
          position={[-3.64, 0.02, 0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube071.geometry}
          material={materials["Floor-purple"]}
          position={[-4.68, 0.02, -0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube030.geometry}
          material={materials["Floor-purple"]}
          position={[-2.6, 0.02, 0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube031.geometry}
          material={materials["Floor-purple"]}
          position={[-2.6, 0.02, -0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube032.geometry}
          material={materials["Floor-purple"]}
          position={[-5.72, 0.02, -0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube033.geometry}
          material={materials["Floor-purple"]}
          position={[-3.64, 0.02, -0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube034.geometry}
          material={materials["Floor-purple"]}
          position={[2.6, 0.02, -4.68]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube036.geometry}
          material={materials.Black}
          position={[-0.52, 0.02, -4.68]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube038.geometry}
          material={materials.Black}
          position={[-2.04, 0.02, -2]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube039.geometry}
          material={materials["Floor-purple"]}
          position={[3.64, 0.02, 0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube040.geometry}
          material={materials["Floor-purple"]}
          position={[2.6, 0.02, 0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube041.geometry}
          material={materials["Floor-purple"]}
          position={[4.68, 0.02, 0.52]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube042.geometry}
          material={materials["Floor-purple"]}
          position={[-0.52, 0.02, 2.6]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube043.geometry}
          material={materials["Floor-purple"]}
          position={[0.52, 0.02, 2.6]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube044.geometry}
          material={materials["Floor-purple"]}
          position={[-0.52, 0.02, 3.64]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube045.geometry}
          material={materials["Floor-purple"]}
          position={[0.52, 0.02, 3.64]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube046.geometry}
          material={materials["Floor-purple"]}
          position={[-0.52, 0.02, 4.68]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube047.geometry}
          material={materials["Floor-purple"]}
          position={[0.52, 0.02, 4.68]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube050.geometry}
          material={materials["Floor-purple"]}
          position={[4.68, 0.02, 2.6]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube051.geometry}
          material={materials["Floor-purple"]}
          position={[5.72, 0.02, 1.56]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube066.geometry}
          material={materials["Floor-purple"]}
          position={[5.72, 0.02, -1.56]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube072.geometry}
          material={materials["Floor-purple"]}
          position={[5.72, 0.02, -2.6]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube056.geometry}
          material={materials["Floor-purple"]}
          position={[3.64, 0.02, 2.6]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube057.geometry}
          material={materials["Floor-purple"]}
          position={[2.6, 0.02, 2.6]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube062.geometry}
          material={materials["Floor-purple"]}
          position={[2.6, 0.02, 6.76]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube058.geometry}
          material={materials["Floor-purple"]}
          position={[2.6, 0.02, 3.64]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube059.geometry}
          material={materials["Floor-purple"]}
          position={[2.6, 0.02, 4.68]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube060.geometry}
          material={materials["Floor-purple"]}
          position={[2.6, 0.02, 5.72]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube061.geometry}
          material={materials["Floor-purple"]}
          position={[1.56, 0.02, 5.72]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube063.geometry}
          material={materials["Floor-purple"]}
          position={[1.56, 0.02, 6.76]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube065.geometry}
          material={materials["Floor-purple"]}
          position={[3.64, 0.02, 6.76]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube067.geometry}
          material={materials["Floor-purple"]}
          position={[4.68, 0.02, 6.76]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube068.geometry}
          material={materials["Floor-purple"]}
          position={[4.68, 0.02, 5.72]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube069.geometry}
          material={materials["Floor-purple"]}
          position={[4.68, 0.02, 4.68]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube070.geometry}
          material={materials.Black}
          position={[5.72, 0.02, 4.68]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube074.geometry}
          material={materials["Floor-purple"]}
          position={[5.72, 0.02, 2.6]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube076.geometry}
          material={materials["Floor-purple"]}
          position={[1.56, 0.03, -4.68]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube077.geometry}
          material={materials["Floor-purple"]}
          position={[6.77, 0.02, 3.49]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube075.geometry}
          material={materials["Floor-purple"]}
          position={[6.77, 0.02, 4.53]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube078.geometry}
          material={materials["Floor-purple"]}
          position={[-3.64, 0.03, -3.64]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube009.geometry}
          material={materials.Black}
          position={[5.72, 0.03, -4.68]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube081.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, 0.09]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube082.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, -0.95]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube084.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, 0.09]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube089.geometry}
          material={materials["Floor-purple"]}
          position={[-4.12, 0.02, 0.09]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube092.geometry}
          material={materials["Floor-purple"]}
          position={[-4.12, 0.02, 4.25]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube097.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, 0.09]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube098.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, 0.09]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube048.geometry}
          material={materials["Floor-purple"]}
          position={[2.12, 0.02, 0.09]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube049.geometry}
          material={materials["Floor-purple"]}
          position={[2.12, 0.02, 1.13]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube052.geometry}
          material={materials["Floor-purple"]}
          position={[3.17, 0.02, 1.13]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube102.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, 0.09]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube105.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, 0.09]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube106.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, 0.09]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube111.geometry}
          material={materials.Black}
          position={[-2.04, 0.02, -0.95]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube113.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, -0.95]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube114.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, -0.95]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube117.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, 0.09]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube118.geometry}
          material={materials["Floor-purple"]}
          position={[-2.04, 0.02, 0.09]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube121.geometry}
          material={materials["Floor-purple"]}
          position={[-0.99, 0.02, -1.99]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube055.geometry}
          material={materials["Floor-purple"]}
          position={[5.72, 0.02, 1.56]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.Cube064.geometry}
          material={materials["Floor-purple"]}
          position={[5.72, 0.02, 2.6]}
          rotation={[0, -1.57, 0]}
          scale={[2.08, 0.08, 2.08]}
        />
        <mesh
          geometry={nodes.立方体020.geometry}
          material={materials["Floor-line"]}
          position={[3.64, 0.01, -3.64]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体022.geometry}
          material={materials["Floor-line"]}
          position={[4.68, 0.01, 5.72]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体024.geometry}
          material={materials["Floor-line"]}
          position={[2.6, 0.01, 4.68]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体019.geometry}
          material={materials["Floor-line"]}
          position={[2.6, 0.01, 6.76]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体031.geometry}
          material={materials["Floor-line"]}
          position={[3.64, 0.01, 6.76]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体033.geometry}
          material={materials["Floor-line"]}
          position={[6.77, 0.01, 3.64]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体043.geometry}
          material={materials["Floor-line"]}
          position={[3.64, 0.01, 0.52]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体044.geometry}
          material={materials["Floor-line"]}
          position={[0.52, 0.01, -4.68]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体045.geometry}
          material={materials["Floor-line"]}
          position={[2.6, 0.01, -4.68]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体049.geometry}
          material={materials["Floor-line"]}
          position={[-3.64, 0.01, -0.52]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体050.geometry}
          material={materials["Floor-line"]}
          position={[-4.68, 0.01, 0.52]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体052.geometry}
          material={materials["Floor-line"]}
          position={[0.52, 0.01, 3.64]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体054.geometry}
          material={materials["Floor-line"]}
          position={[-0.52, 0.01, 3.64]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体055.geometry}
          material={materials["Floor-line"]}
          position={[-3.64, 0.01, 0.52]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体057.geometry}
          material={materials["Floor-line"]}
          position={[-4.68, 0.01, -0.52]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体059.geometry}
          material={materials["Floor-line"]}
          position={[-0.52, 0.01, -3.64]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体061.geometry}
          material={materials["Floor-line"]}
          position={[1.56, 0.01, -4.68]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体046.geometry}
          material={materials["Floor-line"]}
          position={[6.77, 0.01, 2.6]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体023.geometry}
          material={materials["Floor-line"]}
          position={[4.68, 0.01, -2.6]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体034.geometry}
          material={materials["Floor-line"]}
          position={[3.64, 0.01, 2.6]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体035.geometry}
          material={materials["Floor-line"]}
          position={[4.68, 0.01, 2.6]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体036.geometry}
          material={materials["Floor-line"]}
          position={[5.72, 0.01, 4.68]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体029.geometry}
          material={materials["Floor-line"]}
          position={[2.6, 0.08, 5.72]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体030.geometry}
          material={materials["Floor-line"]}
          position={[5.73, 0.08, 2.6]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体041.geometry}
          material={materials["Floor-line"]}
          position={[4.68, 0.08, 6.76]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体062.geometry}
          material={materials["Floor-line"]}
          position={[6.78, 0.08, 4.68]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.Arrow024.geometry}
          material={materials["Icon-Yellow 4"]}
          position={[1.52, 0.01, 5.73]}
          rotation={[0, -1.57, 0]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow030.geometry}
          material={materials["Icon-Yellow 4"]}
          position={[-0.51, 0.01, -2.55]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow043.geometry}
          material={materials["Icon-Yellow 4"]}
          position={[-5.77, 0.01, -0.51]}
          rotation={[0, -1.57, 0]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow044.geometry}
          material={materials["Icon-Yellow 4"]}
          position={[-2.55, 0.01, 0.51]}
          rotation={[-Math.PI, 1.57, -Math.PI]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow045.geometry}
          material={materials["Icon-Yellow 4"]}
          position={[0.51, 0.01, 2.55]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow046.geometry}
          material={materials["Icon-Yellow 4"]}
          position={[-0.51, 0.01, 4.73]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow018.geometry}
          material={materials["Icon-Yellow 4"]}
          position={[6.76, 0.01, 1.53]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow023.geometry}
          material={materials["Icon-Yellow 4"]}
          position={[4.71, 0.01, 0.5]}
          rotation={[-Math.PI, 1.57, -Math.PI]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow028.geometry}
          material={materials["Icon-blue 2"]}
          position={[2.63, 0.01, 0.5]}
          rotation={[-Math.PI, 1.57, -Math.PI]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow034.geometry}
          material={materials["Icon-blue 2"]}
          position={[-5.69, 0.01, 0.5]}
          rotation={[-Math.PI, 1.57, -Math.PI]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow038.geometry}
          material={materials["Icon-blue 2"]}
          position={[-2.63, 0.01, -0.5]}
          rotation={[0, -1.57, 0]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow040.geometry}
          material={materials["Icon-blue 2"]}
          position={[0.5, 0.01, 4.65]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow047.geometry}
          material={materials["Icon-blue 2"]}
          position={[-0.5, 0.01, 2.63]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow033.geometry}
          material={materials["Icon-blue 2"]}
          position={[5.74, 0.01, 1.59]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow041.geometry}
          material={materials["Icon-blue 2"]}
          position={[1.59, 0.01, 6.74]}
          rotation={[-Math.PI, 1.57, -Math.PI]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.Arrow042.geometry}
          material={materials["Icon-blue 2"]}
          position={[5.71, 0.01, -1.59]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.3, 0.07, 0.17]}
        />
        <mesh
          geometry={nodes.立方体032.geometry}
          material={materials["Floor-line"]}
          position={[2.6, 0.01, 3.64]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体040.geometry}
          material={materials["Floor-line"]}
          position={[4.68, 0.08, 4.68]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体047.geometry}
          material={materials["Floor-line"]}
          position={[3.64, 0.08, -4.68]}
          rotation={[0, 1.57, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体048.geometry}
          material={materials["Floor-line"]}
          position={[-0.52, 0.08, -4.68]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体063.geometry}
          material={materials["Floor-line"]}
          position={[2.6, 0.08, 2.59]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体021.geometry}
          material={materials["Floor-line"]}
          position={[5.73, 0.08, -2.6]}
          rotation={[0, 1.57, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.立方体028.geometry}
          material={materials["Floor-line"]}
          position={[3.64, 0.08, -2.59]}
          rotation={[0, -1.57, 0]}
          scale={[0.06, 0.07, 0.18]}
        />
        <mesh
          geometry={nodes.Cube002.geometry}
          material={materials.Black}
          position={[6.25, 0, 0]}
          scale={[1, 0.08, 1]}
        />
        <mesh
          geometry={nodes.Cube012.geometry}
          material={materials.Black}
          position={[0, 0.16, 6.3]}
          rotation={[0, -1.57, 0]}
          scale={[0.75, 0.1, 0.75]}
        >
          <mesh
            geometry={nodes.文本007.geometry}
            material={materials["Icon-white"]}
            position={[1, -0.34, 0.01]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={[0.29, 0.18, 2.26]}
          />
        </mesh>
        <mesh
          geometry={nodes.Cube016.geometry}
          material={materials.Black}
          position={[-7.34, 0, 0]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[1, 0.08, 1]}
        />
        <mesh
          geometry={nodes.Cube020.geometry}
          material={materials.Black}
          position={[0, 0, 6.3]}
          rotation={[0, -1.57, 0]}
          scale={[1, 0.08, 1]}
        />
        <mesh
          geometry={nodes.Cube037.geometry}
          material={materials.Black}
          position={[-7.34, 0.16, 0]}
          rotation={[0, -1.57, 0]}
          scale={[0.75, 0.1, 0.75]}
        />
        <mesh
          geometry={nodes.Cube073.geometry}
          material={materials.Black}
          position={[6.25, 0.16, 0]}
          rotation={[0, -1.57, 0]}
          scale={[0.75, 0.1, 0.75]}
        />
        <mesh
          geometry={nodes.Cube019.geometry}
          material={materials.Black}
          position={[0, 0.09, 0]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[2, 0.08, 2]}
        >
          <mesh
            geometry={nodes.Cube079.geometry}
            material={materials.Black}
            position={[0, -1.26, 0]}
          />
          <mesh
            geometry={nodes.empty.geometry}
            material={materials.Black}
            position={[-0.01, 1, 0]}
            scale={[0.92, 0.77, 0.92]}
          />
          <mesh
            geometry={nodes.自发光.geometry}
            material={materials.自发光}
            position={[0, 1.95, 0]}
            scale={[0.98, 0.16, 0.98]}
            material-emissive="#D4A8FF"
            material-emissiveIntensity={8}
            material-toneMapped={false}
          />
        </mesh>
      </group>
    </>
  );
}

// useGLTF.preload("/platform-transformed.glb");
