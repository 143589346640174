import React from "react";
import { FullPageCarousel, GradientTypography } from "../../components";
import { Panel } from "../../models";
import { NftvMap } from "../../components/nftv-map";
import { Banner } from "../../components/Banner";
import { ReactComponent as FTVClubIcon } from "../../assets/icons/ftv-club.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/icons/long-arrow.svg";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  CollectionShowcase,
  CollectionShowcaseItem,
} from "../../components/CollectionShowcase";

const COLLECTIONS: CollectionShowcaseItem[] = [
  {
    thumbnail:
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_1-1280x720.jpg",
    title: "Series 1",
    images: [
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_2-1280x720.jpg",
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_3-1280x720.jpg",
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_1-1280x720.jpg",
    ],
  },
  {
    thumbnail:
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_2-1280x720.jpg",
    title: "Series 2",
    images: [
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_2-1280x720.jpg",
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_3-1280x720.jpg",
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_1-1280x720.jpg",
    ],
  },
  {
    thumbnail:
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_3-1280x720.jpg",
    title: "Series 3",
    images: [
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_2-1280x720.jpg",
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_3-1280x720.jpg",
      "https://files.company.fashiontv.com/wp-content/uploads/2015/04/ilovefashion_1-1280x720.jpg",
    ],
  },
];

const Merchandise: React.FC = () => {
  const isTabletView = useMediaQuery("(max-width:960px)");
  const [showBanner, setShowBanner] = React.useState<boolean>(true);
  const [currentCollectionIndex, setCurrentCollectionIndex] =
    React.useState<number>(0);

  const panelList: Panel[] = [
    {
      key: "The NFTv Marketplace",
      component: (
        <>
          <NftvMap onMoveStart={() => setShowBanner(false)} />
          <Banner
            show={showBanner}
            icon={<FTVClubIcon />}
            header="NFTV X FTV Club &amp; Merchandise"
            body="By holding our panda NFT have exclusive
								membership to fashion TV club all around the
								world."
          />
        </>
      ),
    },
    {
      key: "NFTv Merchandise",
      component: (
        <>
          <Box
            component="div"
            sx={{
              mt: "15vh",
              px: {
                xs: "2.5rem",
                sm: "5rem",
                md: "7.5rem",
                lg: "10rem",
              },
              display: "flex",
            }}
          >
            <Box component="div" sx={{ flex: 1 }}>
              <Typography
                sx={{ paddingY: 1 }}
                component="span"
                variant={isTabletView ? "h4" : "h3"}
              >
                <b>Merchandise</b>
              </Typography>
              <Box
                component="div"
                sx={{
                  paddingY: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component="div"
                  sx={{
                    paddingX: "2rem",
                  }}
                >
                  <RightArrowIcon />
                </Box>
                <GradientTypography
                  component="span"
                  variant={isTabletView ? "h4" : "h3"}
                >
                  <b>Collections</b>
                </GradientTypography>
              </Box>
            </Box>
            <Box
              component="div"
              sx={{
                flex: "0 0 15rem",
                paddingX: "3rem",
                alignSelf: "flex-end",
              }}
            >
              <Typography component="h3" variant={"h5"}>
                <b>Series</b>
              </Typography>
              <Typography component="h4" variant="subtitle1">
                {COLLECTIONS[currentCollectionIndex]?.title}
              </Typography>
            </Box>
          </Box>
          <CollectionShowcase
            list={COLLECTIONS}
            onActiveCollectionIndexChange={setCurrentCollectionIndex}
          />
        </>
      ),
    },
  ];

  return <FullPageCarousel panelList={panelList} />;
};

export default Merchandise;
