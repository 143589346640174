import { Suspense, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { Html, OrbitControls } from "@react-three/drei";
import { Platform } from "../gltfjsx/Platform";

function Lights() {
  const lightOne = useRef<THREE.SpotLight>(null);
  const lightTwo = useRef<THREE.SpotLight>(null);
  return (
    <>
      <spotLight
        ref={lightOne}
        intensity={2}
        rotation={[0, -1, 0]}
        color={"#FFAAE8"}
        position={[-2, -2, 0]}
        shadow-mapSize-width={64}
        shadow-mapSize-height={64}
        castShadow
        shadow-bias={-0.001}
      />
      <spotLight
        ref={lightTwo}
        intensity={1}
        rotation={[0, -1, 0]}
        color={"#B6F6FF"}
        position={[-2, 8, 0]}
        shadow-mapSize-width={64}
        shadow-mapSize-height={64}
        castShadow
        shadow-bias={-0.001}
      />
    </>
  );
}

const PlatformScene = () => {
  const Loader = () => {
    return (
      <Html
        center
        style={{
          backgroundColor: "#9850ff",
          borderRadius: "100%",
          color: "#ffffff",
          width: "6rem",
          height: "6rem",
          pointerEvents: "none",
          animation: "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 999,
        }}
      >
        Loading
      </Html>
    );
  };

  return (
    <Canvas
      className="canvas__draggable"
      shadows
      camera={{
        fov: 50,
        position: [-60, 60, 70],
      }}
      style={{
        height: "60vh",
      }}
    >
      <ambientLight intensity={0.5} color="#F7ACCD" />
      <Lights />
      <Suspense fallback={<Loader />}>
        <Platform position={[0, 2, 0]} />
      </Suspense>
      <OrbitControls
        rotateSpeed={0.1}
        panSpeed={0.5}
        zoomSpeed={0.2}
        maxDistance={15}
        minDistance={4}
      />
    </Canvas>
  );
};

export default PlatformScene;
