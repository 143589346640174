import React from "react";

export function useCountdown(dateToCountdownTo: Date): {
  countdownTimer: string;
  isCompleted: boolean;
} {
  const [countdownTimer, setCountdownTimer] = React.useState<string>("");
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  React.useEffect(() => {
    const countdownDate = dateToCountdownTo.getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const difference = countdownDate - now;
      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = formatDigit(
        Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      const minutes = formatDigit(
        Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      );
      const seconds = formatDigit(
        Math.floor((difference % (1000 * 60)) / 1000)
      );
      
      setCountdownTimer(`${days > 0 ? `${days}d ` : ''}${hours}:${minutes}:${seconds}`);
      setIsCompleted(difference <= 0);
      if (difference <= 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [dateToCountdownTo]);

  return {
    countdownTimer,
    isCompleted,
  };
}

function formatDigit(digit: number): string {
  return digit.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}
