import React from "react";
import { useAccount, useConnect, useDisconnect, useNetwork } from "wagmi";
import { ConnectWalletModal } from "../../components/connect-wallet-modal";
import { WalletProviderItemProps } from "../../components/connect-wallet-modal/WalletProviderItem";
import MetaMaskIcon from "../../assets/icons/metamask.svg";
import WalletConnectIcon from "../../assets/icons/wallet-connect.svg";
import { ConnectorName } from "../../components/WagmiWrapper";
import {
  NFTvGenesisBoxContractAddressGoerli,
  NFTvGenesisBoxContractAddressMainnet,
  NFTvPandaContractAddressGoerli,
  NFTvPandaContractAddressMainnet,
} from "../../app/constants";

interface IWeb3Context {
  initConnection: () => void;
  onConnectorSelect: (index: number) => void;
  disconnect: () => void;
  getTransactionLink: (hash: string) => string;
  connected: boolean;
  accountAddress: `0x${string}` | undefined;
  status: WalletProviderItemProps["status"];
  nftvContractAddress: string;
  nftvPandaContractAddress: string;
  chainId: number | undefined;
}

interface IWeb3ContextProviderProps {
  children: React.ReactNode;
}

const Web3Context = React.createContext<IWeb3Context | undefined>(undefined);

const Web3ContextProvider: React.FC<IWeb3ContextProviderProps> = ({
  children,
}: IWeb3ContextProviderProps) => {
  const { connect, connectors, reset, status: connectionStatus } = useConnect();
  const { chain } = useNetwork();

  const { address: accountAddress } = useAccount();
  const { disconnect: onWagmiDisconnect } = useDisconnect();
  const [availableConnectors, setAvailableConnectors] = React.useState<
    WalletProviderItemProps[]
  >([]);
  const [showConnectWalletModal, setShowConnectWalletModal] =
    React.useState<boolean>(false);
  const [status, setStatus] =
    React.useState<WalletProviderItemProps["status"]>(connectionStatus);
  const [selectedConnector, setSelectedConnector] =
    React.useState<WalletProviderItemProps>();
  const [selectedIndex, setSelectedIndex] = React.useState<number>();
  const connected: boolean = React.useMemo(
    () => !!accountAddress,
    [accountAddress]
  );
  const nftvContractAddress: string = React.useMemo(
    () =>
      process.env.REACT_APP_WEB3NETWORK === "ethereum"
        ? NFTvGenesisBoxContractAddressMainnet
        : NFTvGenesisBoxContractAddressGoerli,
    []
  );

  const nftvPandaContractAddress: string = React.useMemo(
    () =>
      process.env.REACT_APP_WEB3NETWORK === "ethereum"
        ? NFTvPandaContractAddressMainnet
        : NFTvPandaContractAddressGoerli,
    []
  );

  const chainId = chain?.id;

  const initConnection = (): void => {
    setShowConnectWalletModal(true);
  };

  const onConnectorSelect = (index: number) => {
    setSelectedIndex(index);
    setSelectedConnector(availableConnectors[index]);
    setStatus("loading");
    connect({
      connector: connectors[index],
    });
  };

  const disconnect = () => {
    onWagmiDisconnect();
    setSelectedIndex(undefined);
    setSelectedConnector(undefined);
    setStatus("idle");
  };

  const onRetry = () => {
    selectedIndex !== undefined &&
      connect({
        connector: connectors[selectedIndex],
      });
  };

  const onReset = () => {
    setSelectedIndex(undefined);
    setSelectedConnector(undefined);
    setStatus("idle");
    reset();
  };

  const getTransactionLink = (hash: string) => {
    return process.env.REACT_APP_WEB3NETWORK === "ethereum"
      ? `https://etherscan.io/tx/${hash}`
      : `https://${chain?.network}.etherscan.io/tx/${hash}`;
  };

  React.useEffect(() => {
    setAvailableConnectors(
      connectors.map(({ id, name }) => {
        return {
          id,
          logo: getConnectorIcon(id as ConnectorName),
          name,
        };
      })
    );
  }, [connectors]);

  React.useEffect(() => {
    setStatus(connectionStatus);
  }, [connectionStatus]);

  return (
    <Web3Context.Provider
      value={{
        initConnection,
        disconnect,
        onConnectorSelect,
        getTransactionLink,
        connected,
        accountAddress,
        status,
        nftvContractAddress,
        nftvPandaContractAddress,
        chainId,
      }}
    >
      {children}
      <ConnectWalletModal
        open={showConnectWalletModal}
        availableConnectors={availableConnectors}
        onSelect={onConnectorSelect}
        onClose={() => setShowConnectWalletModal(false)}
        onReset={onReset}
        onRetry={onRetry}
        status={status}
        selectedConnector={selectedConnector}
      />
    </Web3Context.Provider>
  );
};

function useWeb3Context(): IWeb3Context {
  const ctx = React.useContext(Web3Context);
  if (!ctx)
    throw Error("useWeb3Context can only be used inside Web3ContextProvider!");
  return ctx;
}

function getConnectorIcon(name: ConnectorName): string | null {
  switch (name) {
    case "metaMask":
      return MetaMaskIcon;
    case "walletConnect":
      return WalletConnectIcon;
    default:
      return null;
  }
}

export { Web3ContextProvider, useWeb3Context };
