import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  FullPageCarousel,
  // InputCheckbox,
  InputImage,
  PanelWrapper,
  SectionHeader,
} from "../../components";
import DebitCardMockup from "../../assets/graphics/nftv-kyc-debit-card.png";
import indicatorTick from "../../assets/icons/indicator-tick.svg";
import indicatorClose from "../../assets/icons/indicator-close.svg";
import submissionSucessIcon from "../../assets/icons/submission-success.svg";
import { Panel } from "../../models";
import { ButtonLarge } from "../../components/CustomButtons";
import axios from "axios";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { BaseModal } from "../../components/BaseModal";
import { useWeb3Context } from "../../utils/hooks/useWeb3Context";
import { useContractReads, useNetwork } from "wagmi";
import PandaWarriorABI from "../../assets/contracts/ABI/PandaWarriorClanPassABI.json";
import { ethers } from "ethers";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  postCode: number | null;
  creditLimitMoreThanFiveThousand: Boolean;
};

const DebitCardKYC: React.FC = () => {
  const [activeForm, setActiveForm] = useState(false);
  const [boxChecked] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [imageData, setImageData] = useState<File | null>();
  const { connected, accountAddress } = useWeb3Context();

  const { chain } = useNetwork();

  const clanPass = [0, 1, 2, 3, 4, 5, 6, 7];

  const clanPassValue: any = useMemo(() => {
    return {
      0: {
        value: 100,
        name: "Bronze",
      },
      1: {
        value: 500,
        name: "Silver",
      },
      2: {
        value: 1000,
        name: "Gold",
      },
      3: {
        value: 5000,
        name: "Emerald",
      },
      4: {
        value: 10000,
        name: "Platinum",
      },
      5: {
        value: 50000,
        name: "Diamond",
      },
      6: {
        value: 100000,
        name: "Obsidian",
      },
      7: {
        value: 10,
        name: "Classic",
      },
    };
  }, []);

  const changeAddress = useCallback((chainID: number) => {
    switch (chainID) {
      case 1:
        return "0x45D66E786418b36d0579f005d825DD651FD11f73";
      case 56:
        return "0xA40bC33fbdB63A21562696B25827D9B0FB6f4C0c";
      case 137:
        return "0x3620e472bf29030ae9201fcd5316d06c930afbae";
      default:
        return "0x45D66E786418b36d0579f005d825DD651FD11f73";
    }
  }, []);

  const { data } = useContractReads({
    contracts: clanPass.map((item) => ({
      address: changeAddress(chain?.id as number),
      abi: PandaWarriorABI,
      functionName: "userBurnt",
      args: [accountAddress, item],
    })) as any,
  });

  useEffect(() => {
    if (data && accountAddress) {
      console.log(data);
      let highestValuePass = 0;
      let currentIndex = 0;
      let clanPassAmountBurnt = 0;
      for (let i = 0; i < data.length; i++) {
        clanPassAmountBurnt = parseInt(
          ethers.utils.formatUnits(data[i] as any, "wei")
        );
        if (clanPassAmountBurnt) {
          if (highestValuePass < clanPassValue[i].value) {
            highestValuePass = clanPassValue[i].value;
            currentIndex = i;
          }
        }

        if (i === data.length - 1) {
          setNftOwned(clanPassValue[currentIndex]);
        }
      }
    }
  }, [data, clanPassValue, accountAddress]);

  // If NFT owned, use setNftOwned's setter
  // If wallet connected, use setConnectionStatus's setter
  const [nftOwned, setNftOwned] = useState("");

  const theme = useTheme();

  const styles = {
    border: "1px solid #ccc",
    background: "transparent",
    borderRadius: "8px",
    borderColor: "#373539",
    padding: "0.6rem 1rem",
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontFamily: "inherit",
    width: "100%",
  };

  // Paper grid component
  const PaperCard = ({
    title,
    content,
    walletConnected,
    nftOwned,
  }: {
    title: string;
    content: string;
    walletConnected?: boolean;
    nftOwned?: any;
  }) => {
    return (
      <Paper
        variant="outlined"
        sx={{
          padding: 2,
          display: "flex",
          borderRadius: "8px",
          flexDirection: "column",
          gap: 1,
          minHeight: "120px",
          height: "100%",
        }}
      >
        <Typography variant="body1">{title}</Typography>
        <Typography variant="body1">{content}</Typography>
        {walletConnected !== undefined && (
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <img
              src={walletConnected ? indicatorTick : indicatorClose}
              height="12px"
              alt="Wallet connected"
            />
            <Typography
              variant="body2"
              sx={{
                color: walletConnected ? "#17CF1E" : "#FF2E2E",
              }}
            >
              {walletConnected ? "Connected" : "Not connected"}
            </Typography>
          </Box>
        )}
        {nftOwned !== undefined && (
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <img
              src={
                parseInt(nftOwned.value) > 5000 ? indicatorTick : indicatorClose
              }
              height="12px"
              alt="Wallet connected"
            />
            <Typography
              variant="body2"
              sx={{
                color: parseInt(nftOwned.value) > 5000 ? "#17CF1E" : "#FF2E2E",
              }}
            >
              {nftOwned?.name}
            </Typography>
          </Box>
        )}
      </Paper>
    );
  };

  const panelList: Panel[] = [
    {
      key: "NFTv KYC Debit Card",
      component: (
        <PanelWrapper gutterLeft gutterRight scrollShadow>
          <Box
            component="div"
            sx={{
              my: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
            }}
          >
            <Box component="div">
              <SectionHeader content={["NFTv.social", "Debit Card"]} />
              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  rowGap: 4,
                  justifyContent: "space-between",
                  mb: 5,
                }}
              >
                <Box
                  component="div"
                  sx={{
                    width: {
                      xs: "100%",
                      md: "50%",
                    },
                    height: {
                      xs: "fit-content",
                      md: "100%",
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 6,
                    pr: {
                      xs: 0,
                      md: 3,
                    },
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    <Typography variant="body1" sx={{ mb: 2, pt: 1 }}>
                      Apply for a NFTV Debit Card today! You can load any amount
                      you earn through our NFTV platforms onto this card and use
                      it anywhere.
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <PaperCard
                          title="Step 1"
                          content="Connect Wallet"
                          walletConnected={connected}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <PaperCard
                          title="Step 2"
                          content="Buy a Pandawarrior clan pass"
                          nftOwned={nftOwned}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <PaperCard
                          title="Step 3"
                          content="Proceed to submit KYC form to complete the registration."
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <PaperCard
                          title="Step 4"
                          content="Done! We will verify your KYC submission and inform you soon."
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    {connected && (
                      <ButtonLarge
                        variant="contained"
                        onClick={() => setActiveForm(!activeForm)}
                      >
                        <Typography variant="body1">Apply Now</Typography>
                      </ButtonLarge>
                    )}
                  </Box>
                </Box>
                <Box
                  component="div"
                  sx={{
                    width: {
                      xs: "100%",
                      md: "50%",
                    },
                    height: {
                      xs: "fit-content",
                      md: "100%",
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    pl: {
                      xs: 0,
                      md: 3,
                    },
                  }}
                >
                  <img
                    src={DebitCardMockup}
                    alt="NFTv.social debit card"
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
  ];

  // Handle the success dialog
  const handleFormClose = () => {
    setSubmitSuccess(!submitSuccess);
  };

  // Handle the form dialog
  const handleForm = () => {
    setActiveForm(!activeForm);
  };

  // Handle the checkbox change
  // const handleCheckboxChange = () => {
  //   setboxChecked(!boxChecked);
  // };

  // Handle the image upload
  const handleImageUpload = (data: File) => {
    setImageData(data);
  };

  const form = useForm<FormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      postCode: null,
      creditLimitMoreThanFiveThousand: boxChecked,
    },
  });

  const { register, handleSubmit, formState, reset } = form;
  const { errors } = formState;

  // Form submit function which will invoke after successful validation
  const onSubmit = (data: FormValues) => {
    // If the checkbox is checked, the image must be uploaded
    if (boxChecked && !imageData) {
      alert("Error: Please upload an image before submitting.");
      return;
    }

    const dataPayload = {
      ...data,
      walletAddress: accountAddress,
      image: boxChecked ? imageData : null,
    };

    // console.log("Data payload: ", dataPayload);

    try {
      const response = axios.post(
        "https://api.nftv.social/kyc/data",
        dataPayload
      );

      // If all OK, reset & close the form and open the success dialog
      reset();
      handleForm();
      handleFormClose();

      console.log("Response: ", response);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <>
      <BaseModal open={activeForm} onClose={handleForm} title="KYC Form">
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            minWidth: {
              xs: "200px",
              sm: "360px",
              md: "100%",
            },
          }}
        >
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: 3,
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
              }}
            >
              <TextField
                autoComplete="off"
                type="text"
                placeholder="First Name"
                inputProps={{
                  style: styles,
                }}
                {...register("firstName", {
                  required: "First name is required",
                })}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
              <TextField
                autoComplete="off"
                type="text"
                placeholder="Last Name"
                inputProps={{
                  style: styles,
                }}
                {...register("lastName", {
                  required: "Last name is required",
                })}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
            </Box>
          </Box>
          <Box component="div">
            <TextField
              autoComplete="off"
              type="email"
              placeholder="Email"
              sx={{
                width: "100%",
              }}
              inputProps={{
                style: styles,
              }}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Please enter a valid email address",
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Box>
          <Box component="div">
            <TextField
              type="text"
              placeholder="Address"
              sx={{
                width: "100%",
              }}
              inputProps={{
                style: styles,
              }}
              {...register("address", {
                required: "Address is required",
              })}
              error={!!errors.address}
              helperText={errors.address?.message}
            />
          </Box>
          <Box component="div">
            <TextField
              type="text"
              placeholder="Address"
              sx={{
                width: "100%",
              }}
              inputProps={{
                style: styles,
              }}
              {...register("address2", {
                required: "Address 2 is required",
              })}
              error={!!errors.address2}
              helperText={errors.address2?.message}
            />
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                columnGap: 3,
                rowGap: {
                  xs: 3,
                  md: 0,
                },
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
              }}
            >
              <TextField
                type="text"
                placeholder="City"
                inputProps={{
                  style: styles,
                }}
                {...register("city", {
                  required: "City is required",
                })}
                error={!!errors.city}
                helperText={errors.city?.message}
              />
              <TextField
                type="text"
                placeholder="State"
                inputProps={{
                  style: styles,
                }}
                {...register("state", {
                  required: "State is required",
                })}
                error={!!errors.state}
                helperText={errors.state?.message}
              />
              <TextField
                type="number"
                placeholder="Postal Code"
                inputProps={{
                  style: styles,
                }}
                {...register("postCode", {
                  required: "Postal Code is required",
                })}
                error={!!errors.postCode}
                helperText={errors.postCode?.message}
              />
            </Box>
          </Box>
          {/* <Box component="div" sx={{ display: "flex" }}>
            <InputCheckbox
              id="creditLimit"
              body="> 5000 Credit Limit"
              checked={boxChecked}
              onChange={handleCheckboxChange}
            />
          </Box> */}
          {boxChecked && <InputImage onImageUpload={handleImageUpload} />}
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <ButtonLarge
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              type="submit"
            >
              <Typography variant="body1">Submit</Typography>
            </ButtonLarge>
          </Box>
        </Box>
      </BaseModal>
      <BaseModal open={submitSuccess} onClose={handleFormClose} title="">
        <Box
          component="div"
          sx={{
            ".MuiPaper-root": {
              display: "flex",
              background: "#171519",
              rowGap: {
                xs: 2,
                sm: 2,
                md: 3,
              },
              padding: 3,
              borderRadius: 3,
              minWidth: {
                xs: "200px",
                sm: "360px",
              },
            },

            height: "80%",
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexDirection: "column",
              gap: 4,

              height: "100%",
              justifyContent: "center",
            }}
          >
            <img
              src={submissionSucessIcon}
              alt="Submission Sucess icon"
              height={100}
            />
            <Typography variant="h6" sx={{ padding: 0, margin: 0 }}>
              Submission Successful!
            </Typography>
            <ButtonLarge
              variant="contained"
              sx={{
                width: "100%",
              }}
              onClick={() => setSubmitSuccess(!submitSuccess)}
            >
              <Typography variant="body1">Back to Home</Typography>
            </ButtonLarge>
          </Box>
        </Box>
      </BaseModal>
      <FullPageCarousel panelList={panelList} />
    </>
  );
};

export default DebitCardKYC;
