import React from "react";
import { FullPageCarousel } from "../../components";
// import ComingSoon from "../../assets/graphics/coming-soon.svg";
// import ComingSoonMobile from "../../assets/graphics/coming-soon-mobile.png";
import { Panel } from "../../models";
// import { useMediaQuery } from "@mui/material";
import { BybitNftvSocial } from "../../features/BybitNftvSocial";
import { MintGenesisBox } from "../../features/MintGenesisBox";
import { MintPanda } from "../../features/MintPanda";

const NftvMinting: React.FC = () => {
  //   const tabletView = useMediaQuery("(max-width:960px)");

  const panelList: Panel[] = [
    {
      key: "Genesis Box Minting Page",
      component: <MintGenesisBox />,
    },
    {
      key: "Panda Minting Page",
      component: <MintPanda />,
    },
    {
      key: "Bybit x NFTv.social",
      component: <BybitNftvSocial />,
    },
  ];

  return <FullPageCarousel panelList={panelList} />;
};

export default NftvMinting;
