import React from 'react';
import {
	Alert,
	AlertTitle,
	lighten,
	Step,
	StepLabel,
	Stepper,
	styled,
	Typography,
} from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { selectAppState } from '../../features/appStateSlice';
import { blue } from '@mui/material/colors';

export const CustomStepper = styled(Stepper)(({ theme }) => ({
	'& .MuiStep-root': {
		padding: 0,
		'&:first-of-type': {
			paddingLeft: 0,
		},
		'&:last-of-type': {
			paddingRight: 0,
		},
		'& .MuiStepLabel-iconContainer': {
			padding: 0,
		},
	},
	'& .MuiStepConnector-root': {
		'& .MuiStepConnector-line ': {
			borderColor: lighten(theme.palette.primary.main, 0.5),
			borderTopWidth: '2px',
		},
	},
	'& .MuiStepConnector-root.Mui-active': {
		'& .MuiStepConnector-line ': {
			borderColor: theme.palette.primary.main,
		},
	},
	'& .MuiSvgIcon-root': {
		width: '1rem',
		height: '1rem',
		color: lighten(theme.palette.primary.main, 0.5),
		'&.Mui-active, &.Mui-completed': {
			color: theme.palette.primary.main,
		},
	},
	'& .MuiStepIcon-text': {
		display: 'none',
	},
}));

type StepInfo = {
	title: string;
	description: string;
};

type WalletConnectStep = {
	title: string;
	info: StepInfo;
};

const steps: WalletConnectStep[] = [
	{
		title: 'Step 1: Connect Your Wallet',
		info: {
			title: 'Get Started',
			description:
				'Crypto wallet is a very important key to access to Web3.  Select your wallet and start your journey with us!',
		},
	},
	{
		title: 'Step 2: Connecting to MetaMask',
		info: {
			title: 'Approve Connection',
			description:
				'Please approve the connection in your wallet and authorize access to continue.',
		},
	},
	{
		title: 'Connection Successful',
		info: {
			title: 'Connection Successful!',
			description:
				'Your connection has been established, you can continue your journey now!',
		},
	},
];

export interface ConnectStepperProps {
	activeStep: number;
}

const ConnectStepper: React.FC<ConnectStepperProps> = ({
	activeStep = 0,
}: ConnectStepperProps) => {
	const { darkMode } = useAppSelector(selectAppState);
	const activeStepInfo: WalletConnectStep = React.useMemo(
		() => steps[activeStep],
		[activeStep]
	);
	return (
		<div>
			<Typography
				id="modal-modal-title"
				variant="subtitle1"
				component="h3"
				sx={{
					marginBottom: '1rem',
				}}
			>
				{activeStepInfo.title}
			</Typography>
			<CustomStepper activeStep={activeStep}>
				{steps.map((_, index: number) => (
					<Step key={index}>
						<StepLabel />
					</Step>
				))}
			</CustomStepper>
			<Alert
				severity="info"
				icon={false}
				sx={{
					marginTop: '1rem',
					borderRadius: '0.5rem',
					border: `${darkMode ? blue[100] : blue[900]} solid 1px`,
					color: darkMode ? blue[100] : blue[900],
					backgroundColor: darkMode ? blue[900] : blue[100],
				}}
			>
				<AlertTitle>{activeStepInfo.info.title}</AlertTitle>
				{activeStepInfo.info.description}
			</Alert>
		</div>
	);
};

export { ConnectStepper };
