import { Button, styled } from "@mui/material";

export const ButtonLarge = styled(Button)(({ theme }) => ({
  borderRadius: 40,
  padding: "8px 36px",
}));

export const ButtonSmall = styled(Button)(({ theme }) => ({
  borderRadius: 40,
  padding: "4px 24px",
}));
