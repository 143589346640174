import React from "react";
import { Box, Typography } from "@mui/material";
import MaximillianWeiss from "../assets/pages/about/team/core/members-PNG-01.png";
import BarbaraAparo from "../assets/pages/about/team/core/members-PNG-02.png";
import ManivelMalone from "../assets/pages/about/team/core/members-PNG-03.png";
import MariaLisowska from "../assets/pages/about/team/core/members-PNG-04.png";
import MariaMogsolova from "../assets/pages/about/team/core/members-PNG-05.png";
import AlexanderShumsky from "../assets/pages/about/team/core/members-PNG-06.png";
import NeluMirea from "../assets/pages/about/team/core/members-PNG-07.png";
import JanishKhurana from "../assets/pages/about/team/core/members-PNG-08.png";
import SergioSalerni from "../assets/pages/about/team/core/members-PNG-09.png";
import StavrosStavrou from "../assets/pages/about/team/core/members-PNG-10.png";
import BadalSaboo from "../assets/pages/about/team/core/members-PNG-11.png";
import GeorgesDimons from "../assets/pages/about/team/core/members-PNG-12.png";
import YotamLederNonaka from "../assets/pages/about/team/core/members-PNG-13.png";
import Jotaro from "../assets/pages/about/team/core/members-PNG-14.png";
import TimMorley from "../assets/pages/about/team/core/members-PNG-15.png";
import AniaJ from "../assets/pages/about/team/core/members-PNG-16.png";

const CoreTeam: React.FC = () => {
  const coreTeamList = [
    {
      name: "Maxillian Weiss",
      image: MaximillianWeiss,
      title: "Commercial Officer & Administrator",
    },
    {
      name: "Barbara Aparo",
      image: BarbaraAparo,
      title: "Art Director",
    },
    {
      name: "Manivel Malone",
      image: ManivelMalone,
      title: "Chief Revenue Officer",
    },
    {
      name: "Maria Lisowska",
      image: MariaLisowska,
      title: "Chief Sales Officer",
    },
    {
      name: "Maria Mogsolova",
      image: MariaMogsolova,
      title: "Marketing and PR. advisor for Russia and CIS countries",
    },

    {
      name: "Alexander Shumsky",
      image: AlexanderShumsky,
      title: "Advisor Russia & President of Mercedes Benz Fashion Week Russia",
    },
    {
      name: "Nelu Mirea",
      image: NeluMirea,
      title: "Director of NFTV Model Events in Central Europe & Romania",
    },

    {
      name: "Janish Khurana",
      image: JanishKhurana,
      title: "Franchise & Investments - Measa",
    },
    {
      name: "Sergio Salerni",
      image: SergioSalerni,
      title: "President of NFTV Italy",
    },

    {
      name: "Stavros E. Stavrou",
      image: StavrosStavrou,
      title: "President of F- Beverages Advisor for Cyprus& Greece",
    },
    {
      name: "Badal Saboo",
      image: BadalSaboo,
      title: "Advisor India",
    },
    {
      name: "Georges Dimons",
      image: GeorgesDimons,
      title: "PR & Marketing Paris",
    },

    {
      name: "Yotam Leder Nonaka",
      image: YotamLederNonaka,
      title: "Advisor China",
    },

    {
      name: "Jotaro",
      image: Jotaro,
      title: "Advisor Japan",
    },
    {
      name: "Tim Morley",
      image: TimMorley,
      title: "Advisor Monte Carlo Yachts & Charters",
    },
    {
      name: "Ania J",
      image: AniaJ,
      title: "Singer & Voice of NFTV",
    },
  ];

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        alignItems: "center",
        mb: 16,
      }}
    >
      {coreTeamList.map((coreTeamMember, index) => (
        <Box
          component="div"
          key={index}
          sx={{
            width: {
              xs: "50%",
              sm: "33%",
              md: "25%",
            },
            textAlign: "center",
            mb: 5,
          }}
        >
          <img
            src={coreTeamMember.image}
            height="124px"
            alt={index.toString()}
          />
          <Typography variant="subtitle1" sx={{ mt: 2 }} gutterBottom>
            {coreTeamMember.name}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            sx={{ pl: 2, pr: 2 }}
          >
            {coreTeamMember.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CoreTeam;
