import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Html, OrbitControls } from "@react-three/drei";
import { Panda } from "../gltfjsx/Panda";

const ChartScene = () => {
  const Loader = () => {
    return (
      <Html
        center
        style={{
          backgroundColor: "#9850ff",
          borderRadius: "100%",
          color: "#ffffff",
          width: "6rem",
          height: "6rem",
          pointerEvents: "none",
          animation: "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 999,
        }}
      >
        Loading
      </Html>
    );
  };

  const Lights = () => {
    return (
      <>
        <ambientLight intensity={0.25} color="#F7ACCD" />
        <pointLight position={[0, 500, 15]} intensity={3} color="#FFA570" />
        <pointLight position={[10, 0, 10]} intensity={5} color="#806491" />
        <pointLight position={[0, -15, 0]} intensity={2} color="#FFE989" />
      </>
    );
  };

  return (
    <>
      <Canvas
        className="canvas__draggable"
        shadows
        camera={{
          fov: 50,
        }}
        style={{
          height: "60vh",
          backgroundColor: "#14121600",
        }}
      >
        <Suspense fallback={<Loader />}>
          <Lights />
          <Panda position={[0, -2.5, 0]} />
        </Suspense>
        <OrbitControls
          autoRotate
          enableZoom={false}
          enablePan={false}
          maxDistance={6}
          minDistance={2.5}
          minPolarAngle={Math.PI / 2}
          maxPolarAngle={Math.PI / 2}
        />
      </Canvas>
    </>
  );
};

export default ChartScene;
