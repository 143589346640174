import React from "react";
import {
  FixedSizeGrid as Grid,
  GridChildComponentProps,
  GridOnItemsRenderedProps,
} from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { GridItem } from "./GridItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { useProductGalleryContext } from "./GalleryContext";

export interface GridGalleryProps {
  itemsPerRow: number;
}

const GUTTER_SIZE = 5;
const LARGE_GUTTER_SIZE = 15;

const GridGallery: React.FC<GridGalleryProps> = ({
  itemsPerRow,
}: GridGalleryProps) => {
  const isLargerGrid: boolean = itemsPerRow <= 4;
  const gutterSize: number = isLargerGrid ? LARGE_GUTTER_SIZE : GUTTER_SIZE;
  const { onRowClick, loadMoreItems, isItemLoaded, data } =
    useProductGalleryContext();

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={Infinity}
      loadMoreItems={loadMoreItems}
    >
      {({ ref: loaderRef, onItemsRendered }) => (
        <AutoSizer>
          {({ width, height }: { height: number; width: number }) => (
            <Grid
              className="List"
              height={height}
              width={width}
              itemData={data}
              rowCount={(data?.length || 0) / itemsPerRow + 20}
              rowHeight={isLargerGrid ? width / itemsPerRow - gutterSize : 250}
              innerElementType={(innerProps) => (
                <InnerElementType {...innerProps} gutterSize={gutterSize} />
              )}
              columnCount={itemsPerRow}
              columnWidth={width / itemsPerRow - gutterSize}
              onItemsRendered={(props: GridOnItemsRenderedProps) => {
                onItemsRendered({
                  overscanStartIndex: props.overscanRowStartIndex,
                  overscanStopIndex: props.overscanRowStopIndex,
                  visibleStartIndex: props.visibleRowStartIndex,
                  visibleStopIndex: props.visibleRowStopIndex,
                });
              }}
              ref={loaderRef}
              overscanCount={6}
            >
              {(itemProps: GridChildComponentProps) => {
                const index =
                  itemProps.rowIndex * itemsPerRow + itemProps.columnIndex;
                const data = itemProps.data[index];
                return (
                  <GridItem
                    {...itemProps}
                    name={data?.name}
                    image={data?.imageUrl}
                    withCaption={!isLargerGrid}
                    gutterSize={gutterSize}
                    onClick={() => onRowClick(index)}
                  />
                );
              }}
            </Grid>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};

const InnerElementType = React.forwardRef(
  ({ style, gutterSize, ...rest }: any, ref) => (
    <div
      ref={ref}
      style={{
        ...style,
        paddingLeft: gutterSize,
        paddingTop: gutterSize,
      }}
      {...rest}
    />
  )
);

export { GridGallery };
