import React from "react";
import { Box, Typography } from "@mui/material";

interface Props {
  mobile?: boolean;
}

const Footer: React.FC<Props> = ({ mobile }: Props) => {
  return (
    <Box
      component="div"
      sx={{
        display: {
          xs: mobile ? "block" : "none",
          lg: "block",
        },
        position: {
          xs: mobile ? "relative" : "absolute",
          lg: "absolute",
        },
        left: {
          xs: mobile ? 0 : "3%",
          lg: "3%",
        },
        bottom: {
          xs: mobile ? 0 : 35,
          lg: 35,
        },
        zIndex: 99,
      }}
    >
      <Typography variant={mobile ? "body2" : "body1"}>
        hello@nftv.social
      </Typography>
      <Typography variant={mobile ? "body2" : "body1"}>
        © COPYRIGHT NFTV {new Date().getFullYear()}. - ALL RIGHTS RESERVED.
      </Typography>
    </Box>
  );
};

export default Footer;
