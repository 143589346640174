import { ReactNode, Suspense, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Chart } from "../gltfjsx/Chart";
import * as THREE from "three";
import { Html, OrbitControls } from "@react-three/drei";

interface Props {
  darkMode: boolean;
}
interface RigProps {
  children: ReactNode;
}

const Lights = () => {
  const lightOne = useRef<THREE.SpotLight>(null);
  const lightTwo = useRef<THREE.SpotLight>(null);
  return (
    <>
      <spotLight
        ref={lightOne}
        intensity={1}
        rotation={[0, -1, 0]}
        color={"#FFAAE8"}
        position={[-2, -2, 0]}
        shadow-mapSize-width={64}
        shadow-mapSize-height={64}
        castShadow
        shadow-bias={-0.001}
      />
      <spotLight
        ref={lightTwo}
        intensity={3}
        rotation={[0, -1, 0]}
        color={"#B6F6FF"}
        position={[-2, 4, 0]}
        shadow-mapSize-width={64}
        shadow-mapSize-height={64}
        castShadow
        shadow-bias={-0.001}
      />
    </>
  );
};

function Rig({ children }: RigProps) {
  const ref = useRef<THREE.Camera>(null);

  useFrame((state) => {
    ref.current!.rotation.y = THREE.MathUtils.lerp(
      ref.current!.rotation.y + 0.35,
      (state.mouse.x * Math.PI) / 12,
      0.05
    );
    ref.current!.rotation.x = THREE.MathUtils.lerp(
      ref.current!.rotation.x,
      (state.mouse.y * Math.PI) / 12,
      0.05
    );
  });
  return <group ref={ref as any}>{children}</group>;
}

const ChartScene = ({ darkMode }: Props) => {
  const Loader = () => {
    return (
      <Html
        center
        style={{
          backgroundColor: "#9850ff",
          borderRadius: "100%",
          color: "#ffffff",
          width: "6rem",
          height: "6rem",
          pointerEvents: "none",
          animation: "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 999,
        }}
      >
        Loading
      </Html>
    );
  };

  return (
    <>
      <Canvas
        shadows
        style={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <ambientLight intensity={0.5} color="#F7ACCD" />
        <Lights />
        <Suspense fallback={<Loader />}>
          <Rig>
            <Chart
              position={[-1, 0, 0]}
              rotation={[0, -1.1, 0]}
              darkMode={darkMode}
            />
          </Rig>
        </Suspense>
        <OrbitControls
          enableZoom={false}
          enablePan={false}
          enableRotate={false}
          maxAzimuthAngle={0}
          minAzimuthAngle={Math.PI}
        />
      </Canvas>
    </>
  );
};

export default ChartScene;
