import React, { useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import ArrowLeftAlt from "../assets/icons/ArrowLeft";
import ArrowRightAlt from "../assets/icons/ArrowRight";
import Yotamled from "../assets/pages/about/team/yotamled.png";
import Francis from "../assets/pages/about/team/francis.png";
import HenryDu from "../assets/pages/about/team/henry-du.png";
import JimmyLi from "../assets/pages/about/team/jimmy-li.png";
// import MeteC from "../assets/pages/about/team/mete-c.png";
import MichelAdam from "../assets/pages/about/team/michel-adam.png";
import SHaronKnoller from "../assets/pages/about/team/sharon-knoller.png";
import NirNahum from "../assets/pages/about/team/nirnahum-cto.png";
import ShojiKonno from "../assets/pages/about/team/shojikonno-ceo.png";
import NaoYashimura from "../assets/pages/about/team/naoyashimura-coo.png";
import KiyotoKanda from "../assets/pages/about/team/kiyotokanda-advisor.png";
import JeffreyCheong from "../assets/pages/about/team/jeffrey-cheong.png";

const TeamCarousel: React.FC = () => {
  const mobileView = useMediaQuery("(max-width:600px)");
  const tabletView = useMediaQuery("(max-width:960px)");

  const teamList = [
    // {
    //   index: 1,
    //   key: "meteC",
    //   name: "Mete C",
    //   image: MeteC,
    //   title: "ADVISOR OF NFTV",
    //   description:
    //     "Passionate about over coming market failure and bringing innovation into operation. Current CEO of ABLE GLOBAL PROJECTS LTD and GALAXY NETWORK, chair man of China-Israel Science Technology & Innovation Center (CISTIC). Advice over 20 projects and high experience in Marketing & Business strategy.",
    // },
    {
      index: 1,
      key: "jayKoh",
      name: "Jay Koh",
      image: Francis,
      title: "ADVISOR OF NFTV",
      description:
        "Passionate about over coming market failure and bringing innovation into operation.",
    },
    {
      index: 2,
      key: "yotamled",
      name: "Yotamled",
      image: Yotamled,
      title: "CEO & FOUNDER OF NFTV",
      description: "CEO of ABLE GLOBAL PROJECTS LTD and GALAXY NETWORK",
    },
    {
      index: 3,
      key: "michelAdam",
      name: "Michel Adam",
      image: MichelAdam,
      title: "ADVISOR OF FASHIONTV",
      description:
        "Adam was in the textile business, where his company Eden Group worked with Walt Disney and Warner Brothers. After which, he moved to Paris and became the owner of the exclusive café Fashion in Paris. Due to Adam’s vast network in Fashion Industry, he succeeded to attract many designers to host events at his venue. 23 Years later, Adam is the President of FashionTV – one of the best distributed channels worldwide and the world’s most consumed fashion showcasing its Diamond logo in 193 countries and providing fashion and lifestyle clips, background stories, live-shows and trendy music to 500 million homes, 30 fashion cafes, clubs, hotels beach presentations.",
    },
    {
      index: 4,
      key: "sharonKnoller",
      name: "Sharon Knoller",
      image: SHaronKnoller,
      title: "CHIEF MARKETING OFFICER",
      description:
        "CEO, founder and advisor of various Web 3.0 companies in Europe and US, Founder, TheCryptoMediaGroup.",
    },
    {
      index: 5,
      key: "henryDu",
      name: "Henry Du",
      image: HenryDu,
      title: "ADVISOR OF NFTV",
      description: "Co-Founder of Crema Finance, a decentralized exchange",
    },
    {
      index: 6,
      key: "jimmyLi",
      name: "Jimmy Li",
      image: JimmyLi,
      title: "ADVISOR OF NFTV",
      description:
        "Founder, DG Ventures, a blockchain gaming platform, Founder, ShareMineAI, Founder, BC Capital",
    },
    {
      index: 7,
      key: "nirNahum",
      name: "Nir Nahum",
      image: NirNahum,
      title: "CHIEF TECHNOLOGY OFFICER",
      description:
        "Vast experience in leadership and management and Posseses multi-industry experience including cleantech, telecommunications, internet, financial services, government administration, management consulting, and NPOs",
    },
    {
      index: 8,
      key: "shojiKonno",
      name: "Shoji Konno",
      image: ShojiKonno,
      title: "CEO OF NFTV JAPAN",
      description:
        "Graduated from Gakushuin University, Owned and sold a business in telecommunications carrier, Manages several companies in Japan, including a real estate business",
    },
    {
      index: 9,
      key: "naoyaShimura",
      name: "Naoya Shimura",
      image: NaoYashimura,
      title: "COO OF NFTV JAPAN",
      description:
        "An experience asset management consultant for the  affluent market and over 10 years of project investment experience in the trading, financial, gaming, and IT industries and provides various consulting services",
    },
    {
      index: 10,
      key: "kiyotoKanda",
      name: "Kiyoto Kanda",
      image: KiyotoKanda,
      title: "ADVISOR OF NFTV",
      description:
        "Business Consultant, Specialist of Investor Relations business in Japan, Supporting the launch of DeFi and Web3 related businesses",
    },
    {
      index: 11,
      key: "jeffreyCheong",
      name: "Jeffrey Cheong",
      image: JeffreyCheong,
      title: "TECHNICAL ADVISOR OF NFTV",
      description:
        "Web3 subject matter expert. Solution Architect in blockchain and web 2 technologies. Certified Scrum Product Owner",
    },
  ];

  const [currentView, setCurrentView] = useState<number>(3);

  return (
    <Box component="div" sx={{ position: "relative" }}>
      <Swiper
        onSlideChange={(swiper) => {
          setCurrentView(swiper.realIndex + 1);
        }}
        slidesPerView={mobileView ? 1 : tabletView ? 3 : 5}
        spaceBetween={30}
        slidesPerGroup={1}
        centeredSlides={true}
        initialSlide={currentView - 1}
        loop={true}
        loopFillGroupWithBlank={true}
        modules={[Navigation]}
        navigation={{
          enabled: true,
          prevEl: ".prev-alt",
          nextEl: ".next-alt",
        }}
      >
        {teamList.map((teamMember) => (
          <SwiperSlide
            key={teamMember.name}
            style={{
              minHeight: 150,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={teamMember.image}
              alt={teamMember.name}
              height={teamMember.index === currentView ? "150px" : "80px"}
              style={{ transition: "height 0.3s" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        component="div"
        sx={{
          position: "absolute",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          top: 57.5,
          zIndex: 999999,
          "& .swiper-button-disabled img": {
            display: "none",
          },
        }}
      >
        <div
          className="prev-alt"
          style={{
            cursor: "pointer",
            height: 48,
            width: 48,
            marginRight: 200,
          }}
        >
          <ArrowLeftAlt />
        </div>
        <div
          className="next-alt"
          style={{ cursor: "pointer", height: 48, width: 48 }}
        >
          <ArrowRightAlt />
        </div>
      </Box>
      <Box component="div" sx={{ textAlign: "center", mt: 3 }}>
        <Typography
          variant={tabletView ? "subtitle1" : "h6"}
          sx={{ mb: 2, userSelect: "none" }}
        >
          {teamList[currentView - 1].name}
        </Typography>
        <Typography
          variant={tabletView ? "h6" : "h5"}
          sx={{ textTransform: "uppercase", mb: 2, userSelect: "none" }}
        >
          {teamList[currentView - 1].title}
        </Typography>
        <Typography
          variant={tabletView ? "body2" : "body1"}
          sx={{
            userSelect: "none",
            px: {
              xs: "calc(3% + 8px)",
              sm: "calc(3% + 32px)",
              md: "calc(3% + 80px + 48px)",
              lg: 36,
            },
            // eslint-disable-next-line no-useless-computed-key
            ["@media (max-height:900px)"]: {
              display: !tabletView ? "none" : "block",
            },
            pb: 16,
          }}
        >
          {teamList[currentView - 1].description}
        </Typography>
      </Box>
    </Box>
  );
};

export default TeamCarousel;
