import React, { useState, ReactElement, useEffect } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { ETHEREUM, Chain } from "../helpers/Chains";

export enum Web3Connection {
  Disconnected,
  ConnectedWrongChain,
  Connected,
}

// type Web3ChainStatus =
//   | {
//       connection: Web3Connection.Connected;
//       chain: Chain;
//       address: string;
//       provider: Web3Provider;
//     }
//   | {
//       connection: Web3Connection.ConnectedWrongChain;
//       switchChain: () => Promise<void>;
//       chain: Chain;
//     }
//   | {
//       connection: Web3Connection.Disconnected;
//       connect: () => Promise<void>;
//     };

interface ContextProps {
  connected?: Chain;
  address?: string;
  provider?: Web3Provider;
}
const Web3Chain = React.createContext<ContextProps | undefined>(undefined);

export const Web3ChainProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [chain, setChain] = useState<Chain>();
  const [address, setAddress] = useState<string>();
  const [provider] = useState<Web3Provider>();

  useEffect(() => {
    if (provider === undefined) {
      setChain(undefined);
      setAddress(undefined);
      return;
    }

    let isCanceled = false;
    (async function () {
      const chainId = await provider
        .getNetwork()
        .then((network) => network.chainId);

      if (chainId !== ETHEREUM.chainId) {
        return false;
      }
      const chain = ETHEREUM;
      const address = await provider.getSigner().getAddress();

      if (isCanceled) {
        return;
      }

      setChain(chain);
      setAddress(address);
    })();

    return () => {
      isCanceled = true;
    };
  }, [provider]);

  return (
    <Web3Chain.Provider value={{ connected: chain, address, provider }}>
      {children}
    </Web3Chain.Provider>
  );
};
// function switchNetwork(want: Chain): any {
//   throw new Error("Function not implemented.");
// }
