import React from "react";
import { Panel } from "../../models";
import {
  ButtonLarge,
  CoreTeam,
  FullPageCarousel,
  PanelWrapper,
  SectionHeader,
  TeamCarousel,
} from "../../components";
import { Box, Card, Typography, useMediaQuery } from "@mui/material";
import CreatorsParadiseNew from "../../assets/graphics/creators-paradise-new.png";
// import CreatorsParadise from "../../assets/graphics/creators-paradise.png";
import Strength1 from "../../assets/pages/about/strength/strength-1.svg";
import Strength2 from "../../assets/pages/about/strength/strength-2.svg";
import Strength3 from "../../assets/pages/about/strength/strength-3.svg";
import Strength4 from "../../assets/pages/about/strength/strength-4.svg";
import Strength5 from "../../assets/pages/about/strength/strength-5.svg";
import Strength6 from "../../assets/pages/about/strength/strength-6.svg";
import Strength1Light from "../../assets/pages/about/strength/light/strength-1.svg";
import Strength2Light from "../../assets/pages/about/strength/light/strength-2.svg";
import Strength3Light from "../../assets/pages/about/strength/light/strength-3.svg";
import Strength4Light from "../../assets/pages/about/strength/light/strength-4.svg";
import Strength5Light from "../../assets/pages/about/strength/light/strength-5.svg";
import Strength6Light from "../../assets/pages/about/strength/light/strength-6.svg";
import Unique1 from "../../assets/pages/about/uniques/unique-1.svg";
import Unique2 from "../../assets/pages/about/uniques/unique-2.svg";
import Unique3 from "../../assets/pages/about/uniques/unique-3.svg";
import Unique4 from "../../assets/pages/about/uniques/unique-4.svg";
import Unique5 from "../../assets/pages/about/uniques/unique-5.svg";
import Unique6 from "../../assets/pages/about/uniques/unique-6.svg";
import Unique2Light from "../../assets/pages/about/uniques/light/unique-2.svg";
import Unique3Light from "../../assets/pages/about/uniques/light/unique-3.svg";
import Unique4Light from "../../assets/pages/about/uniques/light/unique-4.svg";
import Unique5Light from "../../assets/pages/about/uniques/light/unique-5.svg";
import Unique6Light from "../../assets/pages/about/uniques/light/unique-6.svg";
import Feature1 from "../../assets/pages/about/features/feature-1.svg";
import Feature2 from "../../assets/pages/about/features/feature-2.svg";
import Feature3 from "../../assets/pages/about/features/feature-3.svg";
import Feature4 from "../../assets/pages/about/features/feature-4.svg";
import Feature1Light from "../../assets/pages/about/features/light/feature-1.svg";
import Feature2Light from "../../assets/pages/about/features/light/feature-2.svg";
import Feature3Light from "../../assets/pages/about/features/light/feature-3.svg";
import Feature4Light from "../../assets/pages/about/features/light/feature-4.svg";
import Position1 from "../../assets/pages/about/features/positions/position-1.svg";
import Position2 from "../../assets/pages/about/features/positions/position-2.svg";
import Position3 from "../../assets/pages/about/features/positions/position-3.svg";
import Position4 from "../../assets/pages/about/features/positions/position-4.svg";
import Position1Light from "../../assets/pages/about/features/positions/light/position-1.svg";
import Position2Light from "../../assets/pages/about/features/positions/light/position-2.svg";
import Position3Light from "../../assets/pages/about/features/positions/light/position-3.svg";
import Position4Light from "../../assets/pages/about/features/positions/light/position-4.svg";
import RoadmapDesktop from "../../assets/graphics/roadmap-desktop.png";
import RoadmapTablet from "../../assets/graphics/roadmap-tablet.png";
import RoadmapMobile from "../../assets/graphics/roadmap-mobile.png";
import { useAppSelector } from "../../app/hooks";
import { selectAppState } from "../../features/appStateSlice";

const AboutUs: React.FC = () => {
  const mobileView = useMediaQuery("(max-width:600px)");
  const tabletView = useMediaQuery("(max-width:960px)");
  const { darkMode } = useAppSelector(selectAppState);

  const contentVariant = tabletView ? "body2" : "h6";

  const uniqueSellingPointsList = [
    {
      title: "NFTS",
      content: "Linkage to the IPs on the platform",
      image: Unique1,
    },
    {
      title: "Community Driven",
      content: "Everyone has a role in building up the platform",
      image: darkMode ? Unique2 : Unique2Light,
    },
    {
      title: "Data Ownership",
      content: "User have the right on their content with privacy",
      image: darkMode ? Unique3 : Unique3Light,
    },
    {
      title: "Socialize as You Earn",
      content: "Users are rewarded for their time on the platform",
      image: darkMode ? Unique4 : Unique4Light,
    },
    {
      title: "Strong IP Collaboration",
      content:
        "Strong history and social media presence of FashionTV will attract the attention of global brands",
      image: darkMode ? Unique5 : Unique5Light,
    },
    {
      title: "Engagement",
      content: "Between influencers and users",
      image: darkMode ? Unique6 : Unique6Light,
    },
  ];

  const featuresOfBusinessModelList = [
    {
      title: "Decentralized exchange",
      content: "Enables 2 or more parties to exchange assets safely",
      image: darkMode ? Feature1 : Feature1Light,
    },
    {
      title: "Easy to use platform",
      content: "Proven UI from previous live streaming engagements",
      image: darkMode ? Feature2 : Feature2Light,
    },
    {
      title: "Utility of tokens",
      content:
        "Users can spend their tokens to connect with their stars and socialize with the community",
      image: darkMode ? Feature3 : Feature3Light,
    },
    {
      title: "Video Content",
      content:
        "Watch short videos just like Tik Tok, live streams or even video call your celebrities bridging the gaps between fans and celebs",
      image: darkMode ? Feature4 : Feature4Light,
    },
  ];

  const positioningList = [
    {
      content: "Low-Cost Ads for companies",
      image: darkMode ? Position1 : Position1Light,
    },
    {
      content:
        "Content creators and Users are incentivized to use a platform that pays them their true values",
      image: darkMode ? Position2 : Position2Light,
    },
    {
      content: "Ability for transparency of fees to be shown on the blockchain",
      image: darkMode ? Position3 : Position3Light,
    },
    {
      content: "No upfront costs for influencers to profit",
      image: darkMode ? Position4 : Position4Light,
    },
  ];

  const strengthsList = [
    {
      title: "Network of Communities",
      content: "Asia & Europe",
      image: darkMode ? Strength1 : Strength1Light,
    },
    {
      title: "Strong IP Collaborations",
      content: "Online & Offline IP Collabs",
      image: darkMode ? Strength2 : Strength2Light,
    },
    {
      title: "Open SDK Development",
      content: "Paving New Innovations",
      image: darkMode ? Strength3 : Strength3Light,
    },
    {
      title: "Social-FI DAO Ideology",
      content: "Rewarding Ecosystem",
      image: darkMode ? Strength4 : Strength4Light,
    },
    {
      title: "Extensive Ecosystem",
      content: "Collect, Participate, Earn",
      image: darkMode ? Strength5 : Strength5Light,
    },
    {
      title: "SocialFication Channels",
      content: "Expandable Social-Fi Options",
      image: darkMode ? Strength6 : Strength6Light,
    },
  ];

  const panelList: Panel[] = [
    {
      key: "About NFTv",
      component: (
        <PanelWrapper>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "12.5vh",
                md: "20vh",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="div"
              sx={{
                px: {
                  xs: "calc(3% + 8px)",
                  sm: "calc(3% + 32px)",
                  md: "calc(3% + 80px + 48px)",
                },
              }}
            >
              <SectionHeader content={["About", "NFTv"]} />
            </Box>
            <Box
              component="div"
              sx={{
                px: {
                  xs: "calc(3% + 8px)",
                  sm: "calc(3% + 32px)",
                  md: "calc(3% + 80px + 48px)",
                },
                mb: 5,
              }}
            >
              {tabletView ? (
                <>
                  <Typography variant={mobileView ? "body2" : "subtitle1"}>
                    NFTv is the Web3 solution for games, e-commerce, livestream
                    and talent collaboration platform.
                  </Typography>
                  <Typography variant={mobileView ? "body2" : "subtitle1"}>
                    Our vision is to create a mixed reality fashion space in the
                    Metaverse. We aim to empower individuals to be part of our
                    web3 fashion platform through ownership of digital assets.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6">
                    NFTv aims to create the Web3 platform in social engagement
                    online via the use of entertainment and fashion IP at its
                    core.
                  </Typography>
                  <ul>
                    <li>
                      <Typography
                        component="span"
                        style={{ color: "#d01aee" }}
                        variant="h6"
                      >
                        VISION &nbsp;
                      </Typography>
                      <Typography component="span" variant="h6">
                        - To be the world’s largest SocialFi platform with
                        strong IP rights
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        component="span"
                        style={{ color: "#d01aee" }}
                        variant="h6"
                      >
                        MISSION &nbsp;
                      </Typography>
                      <Typography component="span" variant="h6">
                        - To empower individuals by providing equitable
                        remuneration for their time spent on the platform
                        through ownership of digital assets
                      </Typography>
                    </li>
                  </ul>
                </>
              )}
            </Box>
            <TeamCarousel />
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "Our Core Team",
      component: (
        <PanelWrapper gutterLeft gutterRight scrollShadow>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "12.5vh",
                md: "20vh",
              },
              px: {
                xs: 1,
                lg: 16,
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SectionHeader content={["Our", "Core Team"]} />
            <CoreTeam />
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "Creators' Paradise",
      component: (
        <PanelWrapper
          background={darkMode ? CreatorsParadiseNew : CreatorsParadiseNew}
          gutter
          overlay
        >
          <Box
            component="div"
            sx={{
              px: {
                xs: 1,
                sm: 4,
                lg: 6,
              },
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              "@media (max-height: 750px)": {
                justifyContent: "flex-start",
              },
            }}
          >
            <SectionHeader content={["Creators'", "Paradise"]} />
            <Box component="div" sx={{ mb: 3 }}>
              <Typography variant={contentVariant} sx={{ mb: 3 }}>
                NFTv provides a rewarding platform for content creators to
                engage their fans who are NFTv Content Consumers as a whole.
                These creators can further create quality content on our rich
                media platform.
              </Typography>
              <Typography component="ul" variant={contentVariant}>
                <li>Posting of content owned by you</li>
                <li>Monitor online social activity of your contents</li>
                <li>Host exclusive events</li>
                <li>Post and share updates to your fans</li>
              </Typography>
            </Box>
            <ButtonLarge
              variant="contained"
              sx={{ maxWidth: 183 }}
              onClick={() => {
                window.open("https://whitepaper.nftv.social/", "_blank");
              }}
            >
              Read Whitepaper
            </ButtonLarge>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "Our Unique Selling Point",
      component: (
        <PanelWrapper gutterLeft gutterRight scrollShadow>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
            }}
          >
            <Box component="div" sx={{ textAlign: "center" }}>
              <SectionHeader content={["Our", "Unique Selling Point"]} />
            </Box>
            <Card
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                p: {
                  xs: 3,
                  md: "5vh",
                },
                borderRadius: 5,
                mb: 18,
              }}
            >
              {uniqueSellingPointsList.map(({ title, content, image }) => (
                <Box
                  component="div"
                  key={title}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "45%",
                      md: "31%",
                    },
                    mb: 5,
                  }}
                >
                  <img src={image} alt={title} height={64} />
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{ mt: 2 }}
                    gutterBottom
                  >
                    {title}
                  </Typography>
                  <Typography variant="subtitle2">{content}</Typography>
                </Box>
              ))}
            </Card>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "NFTv Features",
      component: (
        <PanelWrapper gutterLeft gutterRight scrollShadow>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
            }}
          >
            <Box component="div" sx={{ textAlign: "center" }}>
              <SectionHeader content={["NFTv", "Features"]} />
            </Box>
            <Card
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                p: {
                  xs: 3,
                  md: "5vh",
                },
                borderRadius: 5,
                mb: 5,
              }}
            >
              <Typography variant="h6" sx={{ width: "100%", mb: 5 }}>
                <b>Features of the Business Model</b>
              </Typography>
              {featuresOfBusinessModelList.map(({ title, content, image }) => (
                <Box
                  component="div"
                  key={title}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "45%",
                    },
                    mb: 5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={image} alt={title} height={64} />
                  <Box component="div" sx={{ ml: 3 }}>
                    <Typography
                      variant="h6"
                      color="primary"
                      sx={{ mt: 2 }}
                      gutterBottom
                    >
                      {title}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ pr: 8, mb: 2 }}>
                      {content}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Card>
            <Card
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                p: {
                  xs: 3,
                  md: "5vh",
                },
                borderRadius: 5,
                mb: 16,
              }}
            >
              <Typography variant="h6" sx={{ width: "100%", mb: 5 }}>
                <b>Features of the Business Model</b>
              </Typography>
              {positioningList.map(({ content, image }) => (
                <Box
                  component="div"
                  key={content}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "45%",
                      md: "25%",
                    },
                    mb: 5,
                  }}
                >
                  <img src={image} alt={content} height={64} />
                  <Typography variant="subtitle2" sx={{ pr: 8, mt: 3 }}>
                    {content}
                  </Typography>
                </Box>
              ))}
            </Card>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "Our Strengths",
      component: (
        <PanelWrapper gutterLeft gutterRight scrollShadow>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
            }}
          >
            <Box component="div" sx={{ textAlign: "center" }}>
              <SectionHeader content={["Our", "Strengths"]} />
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                mb: 5,
              }}
            >
              {strengthsList.map(({ title, content, image }) => (
                <Card
                  key={title}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "45%",
                      md: "30%",
                    },
                    py: 3,
                    textAlign: "center",
                    borderRadius: 5,
                    mb: {
                      xs: 2,
                      sm: 3,
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={image}
                    alt={title}
                    height={64}
                    sx={{ fill: "currentColor" }}
                  />
                  <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                    {title}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ mb: 2 }}>
                    {content}
                  </Typography>
                </Card>
              ))}
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "Our Roadmap",
      component: (
        <PanelWrapper gutterLeft gutterRight>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
            }}
          >
            <SectionHeader content={["Our", "Roadmap"]} />
            <Typography variant={contentVariant} sx={{ mb: { xs: 3, md: 3 } }}>
              Come and join our journey to the NFTV.social Metaverse with whole
              new experiences!
            </Typography>
            <Box
              component="div"
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                  lg: "none",
                },
              }}
            >
              <img
                src={RoadmapMobile}
                alt="roadmap"
                width="100%"
                style={{ marginBottom: 128 }}
              />
            </Box>
            <Box
              component="div"
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                  lg: "none",
                },
              }}
            >
              <img
                src={RoadmapTablet}
                alt="roadmap"
                width="100%"
                style={{ marginBottom: 128 }}
              />
            </Box>
            <Box
              component="div"
              sx={{
                display: {
                  xs: "none",
                  md: "none",
                  lg: "block",
                },
              }}
            >
              <img
                src={RoadmapDesktop}
                alt="roadmap"
                width="100%"
                style={{ marginBottom: 128 }}
              />
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
  ];

  return <FullPageCarousel panelList={panelList} />;
};

export default AboutUs;
