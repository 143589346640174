import { Card, CardActionArea, Box, CardContent, Typography, useTheme } from '@mui/material';
import React from 'react';
import { WalletConnectionStatus, WalletConnectionStatusProps } from './WalletConnectionStatus';


export interface WalletProviderItemProps {
	id: string;
	logo: any;
	name: string;
	status?: WalletConnectionStatusProps['status'];
  onClick?: () => void;
}

const WalletProviderItem: React.FC<WalletProviderItemProps> = ({ logo, name, status, onClick }: WalletProviderItemProps) => {
	const theme = useTheme();
  const isReady: boolean = React.useMemo(() => status === 'idle', [status]);

	return (
    <Card
      sx={{
        textAlign: "center",
        width: "100%",
        background: "transparent",
        boxShadow: 0,
        borderRadius: "1rem",
        border: "1px solid",
        borderColor:
          status === "success"
            ? theme.palette.success.main
            : theme.palette.grey[800],
      }}
    >
      <CardActionArea
        sx={{
          paddingTop: "1rem",
          paddingBottom: "0.5rem",
          display: isReady ? "initial" : "flex",
          justifyContent: isReady ? "center" : "start",
          alignItems: "center",
          paddingX: "1rem",
        }}
        onClick={onClick}
      >
        <Box
          component="div"
          sx={{
            margin: isReady ? "auto" : "0px",
          }}
        >
          <img src={logo} alt="" />
        </Box>
        <CardContent sx={{ padding: isReady ? "0.5rem 0 0 0" : "0 0 0 1rem" }}>
          <Typography variant="subtitle1" component="h5">
            {name}
          </Typography>

          <WalletConnectionStatus status={status} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export { WalletProviderItem };
