import React from "react";
import Modal, { ModalProps } from "@mui/material/Modal";
import { Box, Fade, IconButton, Typography, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../features/appStateSlice";

export interface BaseModalProps
  extends Partial<Omit<ModalProps, "onSelect" | "title" | "children">> {
  open: boolean;
  onClose: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
}

const BaseModal: React.FC<BaseModalProps> = ({
  open,
  onClose,
  title,
  children,
  ...props
}: BaseModalProps) => {
  const theme = useTheme();
  const { darkMode } = useAppSelector(selectAppState);
  return (
    <Modal {...props} open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <Box
          component="div"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            overflow: "auto",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "100%",
              sm: "100%",
              md: 500,
            },
            maxHeight: {
              xs: "100%",
              sm: "100%",
              md: "80%",
            },
            height: {
              xs: "100%",
              sm: "100%",
              md: "auto",
            },
            borderRadius: {
              xs: 0,
              sm: 0,
              md: "1.5rem",
            },
            backgroundColor: darkMode
              ? theme.palette.background.paper
              : theme.palette.background.default,
            boxShadow: 24,
            p: 4,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              position: "relative",
              marginBottom: "2rem",
            }}
          >
            {title}
            <IconButton
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </Typography>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export { BaseModal };
