import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import cloudLightIcon from "../assets/icons/kyc-cloud-light.svg";
import cloudDarkIcon from "../assets/icons/kyc-cloud-dark.svg";
import { useDropzone, FileWithPath } from "react-dropzone";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../features/appStateSlice";
interface InputCheckboxProps {
  id: string;
  body: string;
  checked: boolean;
  onChange: () => void;
}

const StyledCheckboxInput = styled.input`
  border-radius: 8px;
  accent-color: #9850ff;
`;

export const InputCheckbox = ({
  id,
  body,
  checked,
  onChange,
}: InputCheckboxProps) => {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: 1.5,
      }}
    >
      <StyledCheckboxInput
        type="checkbox"
        id={id}
        name={id}
        placeholder={id}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>
        <Typography variant="body1" sx={{ padding: 0, margin: 0 }}>
          {body}
        </Typography>
      </label>
    </Box>
  );
};

interface InputImageProps {
  onImageUpload: (imageData: File) => void;
}

export const InputImage = ({ onImageUpload }: InputImageProps) => {
  const theme = useTheme();
  const { darkMode } = useAppSelector(selectAppState);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    maxSize: 5242880, // 5MB max file size
    onDrop(acceptedFiles, fileRejections) {
      const rawFile = acceptedFiles[0];
      onImageUpload(rawFile);

      // Handle file rejections by displaying an alert
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            alert("Error: File size must be less than 5MB.");
          }

          if (err.code === "file-invalid-type") {
            alert(
              "Error: File type must be image/png, image/jpeg or image/jpg."
            );
          }
        });
      });
    },
  });

  const files = acceptedFiles.map((file: FileWithPath) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Box component="div" sx={{ display: "flex" }}>
      <Box
        component="div"
        sx={{
          border: "2px dashed #373539",
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
          rowGap: 2,
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "24px",
          paddingBottom: "24px",
          width: "100%",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <img
          src={darkMode ? cloudDarkIcon : cloudLightIcon}
          width={40}
          alt="NFTV KYC cloud icon"
        />
        <Box component="div" sx={{ textAlign: "center" }}>
          <Typography
            variant="body1"
            color={
              darkMode ? theme.palette.primary.main : theme.palette.primary.main
            }
            sx={{ mb: 2, pt: 1, padding: 0, margin: 0 }}
          >
            Upload Passport Image
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.text.primary}
            sx={{ mb: 2, pt: 1, padding: 0, margin: 0 }}
          >
            Drag and drop your image (png, jpeg, jpg) here or{" "}
            <span
              style={{
                color: "#27C2FE",
              }}
            >
              browse
            </span>
          </Typography>
        </Box>
        {acceptedFiles.length >= 1 && (
          <Box component="div">
            <ul>{files}</ul>
          </Box>
        )}
      </Box>
    </Box>
  );
};
