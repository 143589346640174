import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { common } from "@mui/material/colors";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs, Swiper as SwiperClass } from "swiper";
import {
	Portal,
	styled,
	Backdrop,
	IconButton,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

export interface GalleryProps {
	show: boolean;
	onDismiss: () => void;
	images: string[];
}

const MainSwiper = styled(Swiper)(({ theme }) => ({
	flex: 1,
	width: "100%",
	"& .swiper-wrapper": {
		alignItems: "center",
	},
	"& .swiper-slide": {
		width: "50%",
		height: "60%",
		opacity: 0
	},
	"& .swiper-slide img": {
		display: "block",
		width: "100%",
		height: "100%",
		objectFit: "cover",
		margin: "auto",
	},
	"& .swiper-slide-active": {
		opacity: 1
	},
	"& .swiper-button-prev, .swiper-button-next": {
		color: common.white,
	},
	[theme.breakpoints.only("xs")]: {
		"& .swiper-slide": {
			width: "80%",
			height: "40%",
		},
	},
	[theme.breakpoints.only("sm")]: {
		"& .swiper-slide": {
			width: "80%",
			height: "50%",
		},
	},
}));
const ThumbnailSwiper = styled(Swiper)(({ theme }) => ({
	flex: "0 0 20%",
	width: "50%",
	padding: "2rem",
	"& .swiper-slide": {
		width: "10rem",
		height: "5rem",
		opacity: 0.4,
	},
	"& .swiper-slide img": {
		display: "block",
		width: "100%",
		height: "100%",
		objectFit: "cover",
	},
	"& .swiper-slide-thumb-active": {
		opacity: 1,
	},
	"& .swiper-slide-thumb-active img": {
		border: `2px solid ${common.white}`,
	},
	[theme.breakpoints.down("md")]: {
		width: "60%",
		"& .swiper-slide": {
			width: "50%",
			height: "70%",
		},
	},
	[theme.breakpoints.only("xs")]: {
		width: "80%",
		"& .swiper-slide": {
			width: "50%",
			height: "80%",
		},
	},
}));

const Gallery: React.FC<GalleryProps> = ({
	show,
	onDismiss,
	images,
}: GalleryProps) => {
	const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperClass | null>(null);

	return (
		<Portal container={document.body}>
			<Backdrop
				open={show}
				sx={{
					position: "absolute",
					display: "flex",
					flexDirection: "column",
					zIndex: (theme) => theme.zIndex.drawer + 1,
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					width: "100%",
					backgroundColor: "rgba(0, 0, 0, 0.7)"
				}}
			>
				<>
					<IconButton
						onClick={onDismiss}
						sx={{
							position: "absolute",
							right: "1rem",
							top: "1rem",
							color: common.white,
							zIndex: (theme) => theme.zIndex.drawer + 1,
						}}
					>
						<CloseRounded
							sx={{ width: "2.5rem", height: "2.5rem" }}
						/>
					</IconButton>
					<MainSwiper
						spaceBetween={10}
						slidesPerView={"auto"}
						thumbs={{
							swiper:
								thumbsSwiper && !thumbsSwiper.destroyed
									? thumbsSwiper
									: null,
						}}
						modules={[FreeMode, Navigation, Thumbs]}
						className="gallery-container"
						loop={images.length > 1}
						navigation
						centeredSlides
					>
						{images.map((image: string, index: number) => (
							<SwiperSlide key={index}>
								<img src={image} alt={image} />
							</SwiperSlide>
						))}
					</MainSwiper>
					<ThumbnailSwiper
						onSwiper={setThumbsSwiper}
						spaceBetween={10}
						slidesPerView="auto"
						freeMode
						watchSlidesProgress
						defaultValue={0}
						modules={[FreeMode, Navigation, Thumbs]}
						className="mySwiper"
					>
						{images.map((image: string, index: number) => (
							<SwiperSlide key={index}>
								<img src={image} alt={image} />
							</SwiperSlide>
						))}
					</ThumbnailSwiper>
				</>
			</Backdrop>
		</Portal>
	);
};

export { Gallery };
