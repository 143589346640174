import React from "react";
import { Typography } from "@mui/material";
import { BaseModal, BaseModalProps } from "../BaseModal";
import { ButtonLarge } from "../CustomButtons";

export interface ConnectionFailedDialogProps
  extends Omit<BaseModalProps, "children" | "title"> {
  onRetry: () => void;
  onBack: () => void;
}

const ConnectionFailedDialog: React.FC<ConnectionFailedDialogProps> = ({
  onRetry,
  onBack,
  ...props
}: ConnectionFailedDialogProps) => {
  return (
    <BaseModal {...props} title="Connection Failed">
      <Typography
        variant="body1"
        component="p"
        sx={{
          marginBottom: "2rem",
        }}
      >
        The connection attempt failed. Please try to connect your wallet again.
      </Typography>
      <ButtonLarge
        fullWidth
        variant="contained"
        onClick={onRetry}
        sx={{
          marginBottom: "1rem",
        }}
      >
        Try Again
      </ButtonLarge>
      <ButtonLarge fullWidth onClick={onBack}>
        Back to select other wallet
      </ButtonLarge>
    </BaseModal>
  );
};

export { ConnectionFailedDialog };
