import React, { useState, useRef, useEffect } from "react";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import NavBar from "../components/NavBar";
import { Module } from "../models/common/Module";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Footer, ThemedTooltip } from "../components";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../features/appStateSlice";
import useMouse from "@react-hook/mouse-position";

export const temporaryFunctionForLink = (index: number) => {
  switch (index) {
    case 6:
      return "https://pandawarriors.club";
    default:
      return "#";
  }
};

interface Props {
  submodules: Module[];
}

const AppLayout: React.FC<Props> = ({ submodules }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { darkMode } = useAppSelector(selectAppState);

  const [value, setValue] = useState(
    submodules.find(
      (submodule) => `/app${submodule.path}` === location.pathname
    )?.index || 0
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/app${submodules[newValue].path}`);
    setValue(newValue);
  };

  // Custom cursor API
  const bodyRef = useRef<HTMLDivElement>(null);
  const cursorRef = useRef<HTMLDivElement>(null);

  // UseMouseOptions
  const mousePos = useMouse(bodyRef, {
    enterDelay: 100,
    leaveDelay: 100,
    fps: 30,
  });

  // Fetch all draggable canvas elements once
  useEffect(() => {
    const canvas = document.querySelectorAll(".canvas__draggable");

    canvas.forEach((link) => {
      link.addEventListener("mouseover", () => {
        cursorRef.current!.classList.add("inner__cursor__true");
        document.body.classList.add("no-cursor");
      });

      link.addEventListener("mouseout", () => {
        cursorRef.current!.classList.remove("inner__cursor__true");
        document.body.classList.remove("no-cursor");
      });
    });
  });

  return (
    <Box
      component="div"
      sx={{
        height: window.innerHeight,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        ref={bodyRef}
        component="div"
        sx={{
          height: "100%",
        }}
      >
        <NavBar />
        <Tabs
          value={value}
          onChange={handleChange}
          orientation="vertical"
          sx={{
            position: "absolute",
            left: "3%",
            top: "20%",
            height: "60%",
            backgroundColor: darkMode
              ? theme.palette.background.paper
              : theme.palette.background.default,
            borderRadius: 3,
            width: 80,
            pt: 3,
            zIndex: 99,
            ...(!darkMode && {
              boxShadow: `0px 4px 40px rgba(152, 80, 255, 0.15)`,
              backdropFilter: "blur(12px)",
            }),
            "& .MuiButtonBase-root": {
              height: 80,
              minWidth: 80,
            },
            "& .Mui-selected #inner-wrapper": {
              backgroundColor: theme.palette.primary.main,
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
            "& #inner-wrapper svg": {
              height: 32,
              width: 32,
            },
            "& #inner-wrapper:hover svg": {
              borderRadius: 2,
              color: theme.palette.primary.main,
              backgroundColor: `${theme.palette.primary.main}60`,
              textShadow: `0px 0px 20px ${theme.palette.primary.main}`,
              boxShadow: `0px 0px 20px ${theme.palette.primary.main}`,
            },
            "& #inner-wrapper:hover path": {
              fill: theme.palette.primary.main,
            },
            "& .Mui-selected #inner-wrapper:hover path": {
              fill: theme.palette.common.white,
            },
            display: {
              xs: "none",
              md: "block",
            },
            overflow: "auto",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {submodules.map(({ label, index, Icon }: Module) => (
            <Tab
              component={index === 6 ? "a" : "div"} //temporary
              href={temporaryFunctionForLink(index)} //temporary
              target={index === 6 ? "about:blank" : "#"} //temporary
              key={label}
              rel="noopener noreferrer"
              icon={
                <ThemedTooltip title={label} placement="right" arrow>
                  <Box
                    component="div"
                    id="inner-wrapper"
                    sx={{ padding: 1, borderRadius: 3 }}
                  >
                    <Icon
                      color={
                        index === value
                          ? theme.palette.common.white
                          : theme.palette.secondary.main
                      }
                    />
                  </Box>
                </ThemedTooltip>
              }
            />
          ))}
        </Tabs>
        <Routes>
          {submodules.map((module: Module) => (
            <Route
              key={`${module.path}`}
              path={`${module.path}`}
              element={module.page}
            />
          ))}
        </Routes>
        <Footer />
        <div
          ref={cursorRef}
          className="inner__cursor__false"
          style={{
            transform: `translate(${mousePos.x! - 24}px, ${
              mousePos.y! - 24
            }px)`,
          }}
        ></div>
      </Box>
    </Box>
  );
};

export default AppLayout;
