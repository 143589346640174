import { Box, Typography, Grid, Link, useTheme } from "@mui/material";
import React from "react";
import {
  PanelWrapper,
  ButtonLarge,
  GradientTypography,
  ErrorModal,
} from "../components";
import MintPandaBackground from "../assets/pages/home/phases/mint-panda-bg.png";
import PandaCardsImage from "../assets/graphics/panda-cards.png";
import { useWeb3Context } from "../utils/hooks/useWeb3Context";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../features/appStateSlice";
import { GenesisBoxSelection } from "../components/genesis-box-selection/GenesisBoxSelection";
// import { useMintPandaContract } from "../utils/hooks/useMintPandaContract";
import { PandaModal } from "../components/panda-modal";
import axios from "axios";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractRead,
} from "wagmi";

import {
  // NFTvGenesisBoxContractAddressGoerli,
  NFTvGenesisBoxContractAddressMainnet,
  // NFTvPandaContractAddressGoerli,
  NFTvPandaContractAddressMainnet,
} from "./../app/constants";
import NFTvGenesisBoxABI from "./../assets/contracts/ABI/NFTvGenesisBoxABI.json";
import NFTvPandaABI from "./../assets/contracts/ABI/NFTvPandaABI.json";

const MintPanda: React.FC = () => {
  // handle states of the component
  const theme = useTheme();
  const { darkMode } = useAppSelector(selectAppState);
  const [errorDialog, setErrorDialog] = React.useState<{
    open: boolean;
    message: string;
  }>({ open: false, message: "" });
  const [selectedBox, setSelectedBox] = React.useState<number[]>([]);
  const [functionType, setFunctionType] = React.useState<
    "mint" | "approve" | ""
  >("");
  const [selectedBoxHasApproved, setSelectedBoxHasApproved] =
    React.useState<boolean>(false);
  const [boxHash, setBoxHash] = React.useState<any>();
  const [pandaHash, setPandaHash] = React.useState<any>();
  const [boxCount, setBoxCount] = React.useState<number>(0);
  const [boxArray, setBoxArray] = React.useState<number[]>([]);
  const [panda, setPanda] = React.useState<any>();
  const [showPandaModal, setShowPandaModal] = React.useState<boolean>(false);
  const { initConnection, connected, accountAddress } = useWeb3Context();

  // handle functions
  const onError = (error: Error): void => {
    const extractedError = error.message?.match?.(/([^;]+)\(/)?.[1];
    let message = extractedError || error.message;

    if (message.includes("call revert exception")) {
      return;
    }
    setErrorDialog({
      open: true,
      message: message.charAt(0).toUpperCase() + message.slice(1),
    });
  };

  const getAllOwner = (address: `0x${string}` | undefined) => {
    console.log(address);
    axios
      .get(`https://api.nftv.social/box/getAllByOwner?address=${address}`)
      .then((response) => {
        console.log(response);
        setBoxCount(response.data.length);
        setBoxArray(response.data.map(Number));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderMintButtonContent = () => {
    if (connected && selectedBox.length === 0) {
      return "Please Select";
    } else if (connected && selectedBox.length > 0 && !selectedBoxHasApproved) {
      return "Approve";
    } else if (connected && selectedBox.length > 0 && selectedBoxHasApproved) {
      return "Mint";
    }

    return "Connect Wallet";
  };

  const onMint = (functionType: "" | "mint" | "approve") => {
    if (!connected) {
      initConnection();
      return;
    }
    if (connected && selectedBox.length > 0 && functionType === "approve") {
      console.log("here approve!");
      boxWrite?.();
      return;
    }

    if (connected && selectedBox.length > 0 && functionType === "mint") {
      console.log("here mint!");
      pandaWrite?.();
      return;
    }
    setErrorDialog({
      open: true,
      message: "Please select at least a box to continue",
    });
  };

  // handle third party hook

  const { config: boxConfig } = usePrepareContractWrite({
    address: NFTvGenesisBoxContractAddressMainnet,
    abi: NFTvGenesisBoxABI,
    functionName: "approve",
    args: [NFTvPandaContractAddressMainnet, selectedBox[0]],
    enabled: functionType === "approve" && selectedBox.length > 0,
    onError,
  });

  const { config: pandaConfig } = usePrepareContractWrite({
    address: NFTvPandaContractAddressMainnet,
    abi: NFTvPandaABI,
    functionName: "mint",
    args: [selectedBox],
    enabled: functionType === "mint" && selectedBox.length > 0,
    onError,
  });

  const {
    write: boxWrite,
    data: boxData,
    isLoading: boxLoading,
  } = useContractWrite(boxConfig);
  const {
    write: pandaWrite,
    data: pandaData,
    isLoading: pandaLoading,
  } = useContractWrite(pandaConfig);

  useWaitForTransaction({
    hash: functionType === "approve" ? boxHash : pandaHash,
    onSuccess(data) {
      console.log("Transaction has been sped up", data);
      if (functionType === "approve") {
        setSelectedBoxHasApproved(true);
      } else {
        setShowPandaModal(true);
      }
    },
  });

  useContractRead({
    address: NFTvPandaContractAddressMainnet,
    abi: NFTvPandaABI,
    functionName: "tokenURI",
    args: [selectedBox[0]],
    enabled: showPandaModal && selectedBox.length > 0,
    onError,
    onSuccess(data) {
      console.log("Success", data);
      let url: any = data;
      let updateIsReveal = "https://api.nftv.social/panda/update-metadata";

      axios
        .patch(updateIsReveal, {
          tokenid: selectedBox[0],
        })
        .then((response) => {
          console.log(response);
          if (response) {
            axios
              .get(url)
              .then((response) => {
                setSelectedBoxHasApproved(false);
                setFunctionType("");
                setBoxHash("");
                setPandaHash("");
                setSelectedBox([]);
                getAllOwner(accountAddress);
                setPanda(response.data);
              })
              .catch((error) => {
                console.log("Metadata: ", error);
              });
          }
        })
        .catch((error) => {
          console.log("Reveal", error);
        });
    },
  });
  // handle useEffect
  React.useEffect(() => {
    if (connected && selectedBox.length > 0 && !selectedBoxHasApproved) {
      setFunctionType("approve");
    }

    if (connected && selectedBox.length > 0 && selectedBoxHasApproved) {
      setFunctionType("mint");
    }
  }, [connected, selectedBox, selectedBoxHasApproved]);

  React.useEffect(() => {
    if (boxData) {
      setBoxHash(boxData?.hash);
    }
  }, [boxData]);

  React.useEffect(() => {
    if (pandaData) {
      setPandaHash(pandaData?.hash);
    }
  }, [pandaData]);

  React.useEffect(() => {
    if (connected) {
      getAllOwner(accountAddress);
    }
  }, [accountAddress, connected]);

  return (
    <PanelWrapper gutterLeft gutterRight>
      <Grid
        container
        sx={{
          pt: {
            xs: "5rem",
            sm: "7.5rem",
            md: "10rem",
          },
          pb: "5rem",
          height: {
            md: "100%",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 2, md: 1 }}
          sx={{
            paddingTop: { md: "unset !important" },
            textAlign: "center",
            marginTop: {
              sm: "2rem",
              md: "unset !important",
            },
            display: {
              md: "flex",
            },
            background: darkMode ? "#171519" : theme.palette.common.white,
            borderRadius: "2rem",
            boxShadow: darkMode
              ? "unset"
              : `0 0 1rem 1rem ${theme.palette.grey["100"]}`,
            padding: "1rem",
          }}
        >
          <Box component="div" sx={{ margin: "auto", width: "100%" }}>
            <GradientTypography
              variant="h4"
              sx={{
                fontWeight: "600",
                marginBottom: {
                  xs: "0.25rem",
                  sm: "0.5rem",
                  md: "0.75rem",
                },
                fontSize: { xs: "1rem", sm: "2rem" },
              }}
            >
              NFTv Panda Mint
            </GradientTypography>
            <Typography
              sx={{
                marginBottom: {
                  xs: "0.5rem",
                  sm: "1.5rem",
                  md: "2rem",
                },
                fontSize: { xs: "0.75rem", sm: "1.25rem" },
              }}
            >
              Select a Genesis Box you want to burn to mint a Panda NFT.
            </Typography>
            <GenesisBoxSelection
              isLoading={boxLoading || pandaLoading}
              isWalletConnected={connected}
              boxCount={boxCount}
              boxArray={boxArray}
              onChange={setSelectedBox}
              value={selectedBox}
            />
            <Typography
              sx={{
                marginTop: "1rem",
                marginBottom: {
                  xs: "0.5rem",
                  sm: "1rem",
                  md: "2rem",
                },
              }}
            >
              Genesis box available: {boxCount}
            </Typography>
            <ButtonLarge
              variant="contained"
              onClick={(e) => {
                // e.preventDefault();
                onMint(selectedBoxHasApproved ? "mint" : "approve");
              }}
              fullWidth
              disabled={selectedBox.length < 1}
            >
              <Typography variant="h6">
                {boxLoading || pandaLoading
                  ? "Loading"
                  : renderMintButtonContent()}
              </Typography>
            </ButtonLarge>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 1, md: 2 }}
          sx={{
            height: {
              md: "100%",
            },
            paddingTop: { md: "unset !important" },
          }}
        >
          <Box
            component="div"
            sx={{
              backgroundImage: `url(${MintPandaBackground})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              textAlign: "center",
              height: {
                md: "100%",
              },
              display: "flex",
              alignItems: "center",
              flexDirection: {
                xs: "row",
                md: "column",
              },
              padding: {
                xs: "1.25rem 1rem",
                sm: "2rem 3rem",
              },
            }}
          >
            <Box component="div" sx={{ margin: "auto" }}>
              <Box
                component="img"
                src={PandaCardsImage}
                alt="PandaCardsImage"
                sx={{
                  width: "100%",
                  marginBottom: {
                    xs: "1.5rem",
                    sm: "2rem",
                    md: "3rem",
                  },
                }}
              />
              <Typography>
                There is 9999 Panda NFT with various rarity, check
                <Link sx={{ paddingX: "0.5rem", cursor: "pointer" }}>
                  Lootbox Rarity
                </Link>{" "}
                or view{" "}
                <Link sx={{ paddingX: "0.5rem", cursor: "pointer" }}>
                  Gallery
                </Link>
                {/* TODO: add links */}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ErrorModal
        children={undefined}
        {...errorDialog}
        onClose={() => setErrorDialog((dialog) => ({ ...dialog, open: false }))}
      />
      <PandaModal
        open={showPandaModal}
        onClose={() => setShowPandaModal(false)}
        // pandas={pandas ?? []}
        pandas={[panda] ?? []}
      />
    </PanelWrapper>
  );
};

export { MintPanda };
