import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const ThemedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 16,
    marginLeft: "32px !important",
  },
  "& .MuiTooltip-arrow": {
    color: theme.palette.primary.main,
  },
}));
