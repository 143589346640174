import React from "react";
import { Box, Fade, useTheme } from "@mui/material";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../features/appStateSlice";

interface Props {
  children?: React.ReactNode;
  background?: string;
  backgroundColor?: string;
  backgroundCenter?: boolean;
  backgroundTop?: boolean;
  backgroundShadow?: boolean;
  gutter?: boolean;
  gutterLeft?: boolean;
  gutterTop?: boolean;
  gutterRight?: boolean;
  gutterBottom?: boolean;
  scrollShadow?: boolean;
  overlay?: boolean;
}

const PanelWrapper: React.FC<Props> = ({
  children,
  background,
  backgroundColor,
  backgroundCenter,
  backgroundTop,
  backgroundShadow,
  gutter,
  gutterLeft,
  gutterTop,
  gutterRight,
  gutterBottom,
  scrollShadow,
  overlay,
}: Props) => {
  const theme = useTheme();
  const { darkMode } = useAppSelector(selectAppState);

  if (gutter) {
    gutterLeft = true;
    gutterTop = true;
    gutterRight = true;
    gutterBottom = true;
  }

  return (
    <Box
      component="div"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        ...(background
          ? {
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              // backgroundAttachment: "fixed",
              backgroundPosition: backgroundCenter
                ? "center"
                : backgroundTop
                ? "top "
                : "bottom",
              backgroundSize: "cover",
              boxShadow: backgroundShadow
                ? "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)"
                : "none",
              backgroundColor: backgroundColor
                ? backgroundColor
                : theme.palette.background.default,
            }
          : {
              backgroundColor: backgroundColor
                ? backgroundColor
                : theme.palette.background.default,
            }),
        ...(overlay
          ? {
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: darkMode ? "#141216" : "#FAFAFA",
                opacity: 0.6,
              },
            }
          : ""),
      }}
    >
      <Fade in={true} timeout={600}>
        <Box
          component="div"
          sx={{
            height: "100%",
            mt: {
              xs: gutterTop ? "12.5vh" : 0,
              md: gutterTop ? "20vh" : 0,
            },
            mb: {
              xs: gutterBottom ? "170px" : 0,
              md: gutterBottom ? "20vh" : 0,
            },
            ml: {
              xs: gutterLeft ? "3%" : 0,
              md: gutterLeft ? "calc(3% + 80px)" : 0,
            },
            mr: {
              xs: gutterLeft ? "3%" : 0,
              md: gutterRight ? "calc(3% + 80px)" : 0,
            },
            overflow: "scroll",
            position: "relative",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {children}
        </Box>
      </Fade>
      {darkMode && !gutterBottom && scrollShadow && (
        <Box
          component="div"
          sx={{
            height: 170,
            width: "100%",
            position: "fixed",
            bottom: 0,
            background: background
              ? "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 20%, #000000 100%)"
              : "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%)",
          }}
        />
      )}
    </Box>
  );
};

export default PanelWrapper;
