import { useContractRead, useContractReads } from "wagmi";
import NFTvGenesisBoxABI from "../../assets/contracts/ABI/NFTvGenesisBoxABI.json";
import NFTvPandaABI from "../../assets/contracts/ABI/NFTvPandaABI.json";
import { useWeb3Context } from "./useWeb3Context";

export type OnErrorCallback = ((err: Error) => void) | undefined;

type BoxContractFunctionName =
  | "maxRoundSupply"
  | "tokenPrice"
  | "roundSold"
  | "balanceOf"
  | "tokenOfOwnerByIndex";
type PandaContractFunctionName = "tokenUri";

export function useWagmiContractRead(
  functionName: BoxContractFunctionName,
  args?: unknown[],
  onError?: OnErrorCallback
) {
  const { nftvContractAddress } = useWeb3Context();
  const response = useContractRead<
    typeof NFTvGenesisBoxABI,
    BoxContractFunctionName
  >({
    address: nftvContractAddress,
    abi: NFTvGenesisBoxABI,
    functionName,
    args,
    onError,
  });
  return response;
}

export function useGenesisBoxContractRead(
  functionName: BoxContractFunctionName,
  onError?: OnErrorCallback,
  args: any = [0],
  enabled?: boolean
) {
  const { nftvContractAddress } = useWeb3Context();

  const response = useContractRead<
    typeof NFTvGenesisBoxABI,
    BoxContractFunctionName
  >({
    address: nftvContractAddress,
    abi: NFTvGenesisBoxABI,
    functionName,
    args,
    onError,
    enabled,
  });

  return response;
}

export function useGenesisBoxContractReads(
  contracts: { functionName: any; args: any }[],
  onError?: OnErrorCallback,
  enabled?: boolean
) {
  const { nftvContractAddress } = useWeb3Context();
  const commonConfig = {
    address: nftvContractAddress,
    abi: NFTvGenesisBoxABI,
    onError,
    enabled,
  };

  const response = useContractReads({
    contracts: contracts.map((item) => ({
      ...commonConfig,
      ...item,
    })),
  });

  return response;
}

export function usePandaContractRead(
  functionName: PandaContractFunctionName,
  onError?: OnErrorCallback,
  args: any = [0],
  enabled?: boolean
) {
  const { nftvPandaContractAddress } = useWeb3Context();

  const response = useContractRead<
    typeof NFTvPandaABI,
    PandaContractFunctionName
  >({
    address: nftvPandaContractAddress,
    abi: NFTvPandaABI,
    functionName,
    args,
    onError,
    enabled,
  });

  return response;
}

export function usePandaContractReads(
  contracts: { functionName: any; args: any }[],
  onError?: OnErrorCallback,
  enabled?: boolean
) {
  const { nftvPandaContractAddress } = useWeb3Context();
  const commonConfig = {
    address: nftvPandaContractAddress,
    abi: NFTvPandaABI,
    onError,
    enabled,
  };

  const response = useContractReads({
    contracts: contracts.map((item) => ({
      ...commonConfig,
      ...item,
    })),
  });
  console.log(response);
  return response;
}
