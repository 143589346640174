import { styled, Typography, TypographyProps } from "@mui/material";

type GradientTypographyProps = React.ComponentType<
  TypographyProps<"span", { component?: "span" }>
>;

export const GradientTypography: GradientTypographyProps = styled(Typography)(
  ({ theme }) => ({
    background: "-webkit-linear-gradient(360deg, #27c3fe, #c810fb)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  })
);
