import { Box, Typography } from "@mui/material";
import React from "react";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../features/appStateSlice";

export interface TraitCardProps {
  inline?: boolean;
}

const TraitCard: React.FC<TraitCardProps> = ({ inline }: TraitCardProps) => {
  const { darkMode } = useAppSelector(selectAppState);

  return (
    <Box
      component="div"
      sx={{
        display: inline ? "inline-flex" : "flex",
        background: (theme) =>
          darkMode
            ? theme.palette.grey["900"]
            : theme.palette.background.default,
        padding: inline
          ? "0.5rem 0.75rem"
          : {
              xs: "0.5rem 0.75rem",
              sm: "0.75rem 1rem",
            },
        mr: inline ? "0.5rem" : "",
        mb: inline ? "0.5rem" : "",
        borderRadius: inline ? 0 : "0.75rem",
      }}
    >
      <Box
        component="img"
        src="https://via.placeholder.com/300"
        alt="Lootbox"
        sx={{
          width: inline
            ? "2rem"
            : {
                xs: "3rem",
                sm: "5rem",
              },
          height: inline
            ? "2rem"
            : {
                xs: "3rem",
                sm: "5rem",
              },
          mr: inline
            ? "0.5rem"
            : {
                xs: "0.5rem",
                sm: "1rem",
              },
        }}
      />
      <Box component="div" sx={{ flex: 1 }}>
        {!inline && (
          <Typography
            sx={{
              color: (theme) => theme.palette.grey[500],
              fontSize: {
                xs: "0.75rem",
                sm: "0.85rem",
              },
              mb: "0.5rem",
            }}
          >
            Label
          </Typography>
        )}
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: {
              xs: "0.85rem",
              sm: "1rem",
            },
            whiteSpace: "nowrap",
          }}
        >
          value tstss
        </Typography>
      </Box>
    </Box>
  );
};

export { TraitCard };
