import React from "react";
import {
  FullPageCarousel,
  PanelWrapper,
  // SectionHeader,
} from "../../components";

// import ComingSoon from "../../assets/graphics/coming-soon.svg";
// import ComingSoonMobile from "../../assets/graphics/coming-soon-mobile.png";
import NftvMarketplace from "../../assets/graphics/nftv-marketplace.png";
import { Panel } from "../../models";
import { Box } from "@mui/material";
// import { NftvMap } from "../../components/nftv-map";
// import { Banner } from "../../components/Banner";
// import { ReactComponent as FTVClubIcon } from "../../assets/icons/ftv-club.svg";

const NftMarket: React.FC = () => {
  //   const [showBanner, setShowBanner] = React.useState<boolean>(true);

  // const marketplacePreview = [
  //   {
  //     title: "NFTv Marketplace Overview",
  //     description: "Find your perfect panda!",
  //     image: MarketplaceOverview,
  //   },
  //   {
  //     title: "Buy and Sell Platform",
  //     description:
  //       "Visit the marketplace to purchase your panda and join the community!",
  //     image: BuySell,
  //   },
  //   {
  //     title: "NFTv User Profile and Dashboard",
  //     description: "Showcase your pandas!",
  //     image: Profile,
  //   },
  // ];

  const panelList: Panel[] = [
    {
      key: "The NFTv Marketplace",
      component: (
        <PanelWrapper
          background={NftvMarketplace}
          backgroundTop
          gutterLeft
          gutterRight
          scrollShadow
        >
          <Box
            component="div"
            sx={{
              my: {
                xs: "15vh",
                sm: "20vh",
              },
              px: {
                xs: 1,
                sm: 4,
                lg: 6,
              },
            }}
          >
            {/* <SectionHeader content={["The", "NFTv Marketplace"]} /> */}
            {/* {marketplacePreview.map((card) => (
              <Card
                sx={{
                  display: "flex",
                  padding: 3,
                  mb: 3,
                  borderRadius: 3,
                  alignItems: "center",
                  height: 100,
                }}
              >
                <img
                  src={card.image}
                  alt={card.title}
                  style={{ maxHeight: 200, maxWidth: 200 }}
                />
                <Box
                  sx={{
                    flex: "1 0 auto",
                    pl: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "calc(100px - 50px)",
                  }}
                >
                  <Typography component="div" variant="h5">
                    {card.title}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {card.description}
                  </Typography>
                </Box>
              </Card>
            ))} */}
          </Box>
        </PanelWrapper>
      ),
    },
  ];

  return <FullPageCarousel panelList={panelList} />;
};

export default NftMarket;
