import {
  Box,
  IconButton,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/chevron-down.svg";
import React from "react";
// import { useAppSelector } from "../../app/hooks";
// import { selectAppState } from "../../features/appStateSlice";
import {
  GalleryLayoutSelector,
  GalleryLayoutSelectorProps,
} from "./GalleryLayoutSelector";

export interface ProductGalleryTopBarFilterProps
  extends Omit<GalleryLayoutSelectorProps, "value"> {
  onFilterClick: () => void;
  layout: GalleryLayoutSelectorProps["value"];
}

const ProductGalleryTopBarFilter: React.FC<ProductGalleryTopBarFilterProps> = ({
  onFilterClick,
  onLayoutChange,
  layout,
}: ProductGalleryTopBarFilterProps) => {
  // const { darkMode } = useAppSelector(selectAppState);

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        justifyItems: "stretch",
        pt: {
          xs: "12.5vh",
          md: "15vh",
        },
        mb: "1rem",
        position: "sticky",
        zIndex: "2",
        top: "0",
        background: (theme) => theme.palette.background.default,
      }}
    >
      <IconButton onClick={onFilterClick}>
        <FilterIcon width="1rem" />
      </IconButton>
      <Box component="div" sx={{ flex: 2 }}>
        <TextField
          placeholder="With normal TextField"
          id="outlined-start-adornment"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon width="1rem" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Select
        id="demo-select-small"
        value={30}
        sx={{ flex: 1 }}
        size="small"
        IconComponent={ChevronDownIcon}
        // onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </Select>
      <GalleryLayoutSelector onLayoutChange={onLayoutChange} value={layout} />
      <Typography>| 9999 NFTs</Typography>
    </Box>
  );
};

export { ProductGalleryTopBarFilter };
