import React from "react";
import { CssBaseline, responsiveFontSizes } from "@mui/material";
import {
  createTheme,
  Theme,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material/styles";
import Router from "./Router";
import { Web3ChainProvider } from "./utils/hooks/useWeb3Chain";
import { Web3ContextProvider } from "./utils/hooks/useWeb3Context";
import { useAppSelector } from "./app/hooks";
import { selectAppState } from "./features/appStateSlice";
import { WagmiWrapper } from "./components/WagmiWrapper";

const App: React.FC = () => {
  const appState = useAppSelector(selectAppState);

  const common: ThemeOptions = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: "'Outfit', sans-serif",
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },
  };

  const dark: Theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#9850ff",
      },
      secondary: {
        main: "#5e496e",
      },
      background: {
        default: "#141216",
        paper: "#171519",
      },
      text: {
        primary: "#ffffff",
        secondary: "rgba(255, 255, 255, 0.65)",
      },
    },
    ...common,
  });

  const light: Theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#9850ff",
      },
      secondary: {
        main: "#dbc6ec",
      },
      background: {
        default: "#fafafa",
        paper: "#EFEFEF",
      },
      text: {
        primary: "#2c2c2c",
        secondary: "rgba(44, 44, 44, 0.65)",
      },
    },
    ...common,
  });

  return (
    <Web3ChainProvider>
      <WagmiWrapper>
        <ThemeProvider
          theme={responsiveFontSizes(appState.darkMode ? dark : light)}
        >
          <Web3ContextProvider>
            <CssBaseline />
            <Router />
          </Web3ContextProvider>
        </ThemeProvider>
      </WagmiWrapper>
    </Web3ChainProvider>
  );
};

export default App;
