import {
	List,
	ListItem,
} from '@mui/material';
import React from 'react';
import { WalletProviderItem, WalletProviderItemProps } from './WalletProviderItem';

export interface WalletProviderListProps {
	list: WalletProviderItemProps[];
	selected?: string;
	status: WalletProviderItemProps['status'];
	onChange: (index: number) => void;
}

const WalletProviderList: React.FC<WalletProviderListProps> = ({
	list,
	selected,
	status,
	onChange
}: WalletProviderListProps) => {
	return (
		<List
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				gap: '1.5rem',
				marginTop: '1rem',
				maxHeight: status === 'idle' ? 'calc(100vh - 30rem)' : 'unset',
				overflowY: status === 'idle' ? 'auto' : 'hidden'
			}}
		>
			{list.map(
				(item: WalletProviderItemProps, index: number) => {
					return (
							<ListItem
								key={index}
								sx={{
									padding: 0,
									width: selected === item.id ? '100%' : !selected ? 'calc(50% - 0.75rem)' : '0px',
									height: selected === item.id || !selected ? 'auto' : '0px',
									opacity: selected === item.id || !selected ? '1' : '0',
									transition: 'height 200ms, width 200ms'
								}}
							>
								<WalletProviderItem {...item} status={status} onClick={() => onChange(index)} />
							</ListItem>
					);
				}
			)}
		</List>
	);
};

export { WalletProviderList };
