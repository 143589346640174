import React from "react";
import { ButtonLarge, PanelWrapper, SectionHeader } from "../components";
import GalleryBackground from "../assets/pages/gallery/landing-bg.png";
import { Box, Typography } from "@mui/material";
import { ProductGallery } from "../components/product-gallery";

const GALLERY_ID = "panda-gallery";

const PandaGallery: React.FC = () => {
  const onExplore = () => {
    const element: HTMLElement | null = document.getElementById(GALLERY_ID);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <PanelWrapper>
      <Box
        component="div"
        sx={{
          backgroundImage: `url(${GalleryBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="div"
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <SectionHeader content={["NFTv Panda", "NFTs Collection"]} />
          <Typography>9999 Rare Pandas are here!</Typography>
          <Box
            component="div"
            sx={{ display: "flex", gap: "1rem", mt: "1rem" }}
          >
            <ButtonLarge variant="contained" onClick={onExplore}>
              Let's Explore
            </ButtonLarge>
            <ButtonLarge>Mint NFT now -</ButtonLarge>
          </Box>
        </Box>
      </Box>
      <ProductGallery id={GALLERY_ID} />
    </PanelWrapper>
  );
};

export { PandaGallery };
