import { useMediaQuery } from "@mui/material";
import axios from "axios";
import React from "react";
import { useInfiniteQuery } from "react-query";
import { GalleryLayoutType } from "./GalleryLayoutSelector";

export interface Product {
  imageUrl: string;
  traits: { type: string; value: string }[];
  name: string;
  owner: string;
  rarity: string;
  traitsCount: number;
}

interface GalleryContextProps {
  onRowClick: (index: number) => void;
  loadMoreItems: () => Promise<void>;
  isItemLoaded: (index: number) => boolean;
  toggleDetailsModal: (open: boolean) => void;
  selectedProduct?: Product;
  data?: Product[];
  error?: Error;
  showDetailsModal: boolean;
}

interface ProductGalleryContextProps {
  children?: React.ReactNode;
  layout: GalleryLayoutType;
}

const GalleryContext = React.createContext<GalleryContextProps>({
  onRowClick: () => {},
  loadMoreItems: async () => {},
  isItemLoaded: () => false,
  toggleDetailsModal: () => {},
  showDetailsModal: false,
});

const ProductGalleryContext: React.FC<ProductGalleryContextProps> = ({
  children,
  layout,
}: ProductGalleryContextProps) => {
  const isMobileView: boolean = useMediaQuery("(max-width:600px)");
  const [selectedProduct, setSelectedProduct] = React.useState<
    Product | undefined
  >();
  const [showDetailsModal, setShowDetailsModal] =
    React.useState<boolean>(false);
  const fetchContactsByPage = async ({ pageParam = 1 }) => {
    const { data }: any = await axios.get(
      "https://jsonplaceholder.typicode.com/todos",
      { params: { _page: pageParam, _limit: 10 } }
    );
    /**
     * userId: 1, id: 1, title: 'delectus aut autem', completed: false
     */
    return data.map(
      ({ userId, title }: any): Product => ({
        name: title,
        owner: userId,
        traits: [],
        traitsCount: Math.floor(Math.random() * (7 - 1 + 1) + 1),
        imageUrl: "https://via.placeholder.com/300",
        rarity: "Super rare",
      })
    );
  };

  const {
    // This will only be true on the first load and/or when the cache is empty.
    isLoading,
    // This will be true whenever a request is sent from fetchNextPage.
    isFetchingNextPage,
    // isError,
    error,
    refetch,
    data,
    fetchNextPage,
  } = useInfiniteQuery("contacts", fetchContactsByPage, {
    getNextPageParam: (_, pages) => pages.length + 1,
  });

  // React Table will call forEach on this, so it must be an array.
  // Also note that React Query's pages property is a 2D array (each page is its own array), so it must be flattened.
  const memoizedData = React.useMemo(
    () => (isLoading ? [] : data?.pages?.flat?.()),
    [isLoading, data]
  );

  // This function needs to return a Promise<void> as it is awaited by InfiniteLoader internally.
  const loadMoreItems = async () => {
    if (!isFetchingNextPage) {
      await fetchNextPage();
    }
  };

  const isItemLoaded = (index: number) => index < (memoizedData?.length || 0);

  const onRowClick = (index: number) => {
    setSelectedProduct(() => {
      return memoizedData?.[index];
    });
    if (layout !== "list" || isMobileView) {
      setShowDetailsModal(true);
    }
  };

  React.useEffect(() => {
    refetch();
    if (layout === "list") {
      setSelectedProduct(memoizedData?.[0]);
    }
  }, [layout, memoizedData, refetch]);

  return (
    <GalleryContext.Provider
      value={{
        onRowClick,
        loadMoreItems,
        isItemLoaded,
        toggleDetailsModal: setShowDetailsModal,
        selectedProduct,
        data: memoizedData,
        error: error as Error,
        showDetailsModal,
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
};

const useProductGalleryContext = function () {
  return React.useContext(GalleryContext);
};

export { ProductGalleryContext, useProductGalleryContext };
