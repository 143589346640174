import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectAppState } from "../../features/appStateSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { isEmpty } from "lodash";

export type FilterValue = {
  [key: string]: string[];
};

export interface ProductGallerySideFilterProps {
  open: boolean;
  onClose: () => void;
  groups: ProductGalleryFilterGroup[];
  value: FilterValue;
  onFilterChange: (data: FilterValue) => void;
}

export interface ProductGalleryFilterListItem {
  label: string;
  value: string;
}

export interface ProductGalleryFilterGroup {
  label: string;
  id: string;
  list: ProductGalleryFilterListItem[];
}

const drawerWidth = 240;

const ProductGallerySideFilter: React.FC<ProductGallerySideFilterProps> = ({
  open,
  onClose,
  groups,
  value,
  onFilterChange,
}: ProductGallerySideFilterProps) => {
  const { darkMode } = useAppSelector(selectAppState);
  const hasFilter = React.useMemo(() => {
    return (
      !isEmpty(value) &&
      Object.keys(value).some((key: string) => value[key]?.length > 0)
    );
  }, [value]);

  const onCheck = (key: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedList: string[] = value[key] ? [...value[key]] : [];
    const checkboxValue: string = event.target.value;
    if (event.target.checked) {
      checkedList.push(checkboxValue);
    } else {
      checkedList.splice(checkedList.indexOf(checkboxValue), 1);
    }
    onFilterChange({
      ...value,
      [key]: checkedList,
    });
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          background: (theme) =>
            darkMode
              ? theme.palette.background.paper
              : theme.palette.background.default,
          width: drawerWidth,
          paddingX: "1rem",
        },
      }}
    >
      <Typography
        variant="h5"
        component="h2"
        sx={{
          position: "relative",
          mt: "2rem",
          mb: "1rem",
          pb: "0.5rem",
          borderBottom: (theme) => `1px solid ${theme.palette.grey["600"]}`,
        }}
      >
        Filter
        {hasFilter && (
          <Button sx={{ float: "right" }} onClick={() => onFilterChange({})}>
            Clear
          </Button>
        )}
      </Typography>
      {groups.map((item: ProductGalleryFilterGroup, index: number) => (
        <Accordion
          key={index}
          disableGutters
          sx={{
            background: (theme) =>
              darkMode
                ? theme.palette.background.paper
                : theme.palette.background.default,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              px: 0,
            }}
          >
            <Typography>{item.label}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              px: 0,
            }}
          >
            <FormGroup>
              {item.list.map(
                (filterItem: ProductGalleryFilterListItem, index: number) => (
                  <FormControlLabel
                    key={index}
                    labelPlacement="start"
                    label={filterItem.label}
                    sx={{
                      width: "100%",
                      justifyItems: "stretch",
                      marginLeft: 0,
                      ".MuiFormControlLabel-label": {
                        flex: 1,
                      },
                      ".MuiCheckbox-root": {
                        px: 0,
                      },
                    }}
                    control={
                      <Checkbox
                        value={filterItem.value}
                        checked={
                          value[item.id]?.includes?.(filterItem.value) || false
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => onCheck(item.id, event)}
                      />
                    }
                  />
                )
              )}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      ))}
    </Drawer>
  );
};

export { ProductGallerySideFilter };
