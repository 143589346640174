import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { GradientTypography } from "./CustomTypography";

interface Props {
  content: string[];
  nextLine?: boolean;
}

const SectionHeader: React.FC<Props> = ({ content, nextLine }: Props) => {
  const tabletView = useMediaQuery("(max-width:960px)");

  return (
    <Box component="div" sx={{ mb: { xs: 2, sm: 3, xl: 5 } }}>
      <Typography component="span" variant={tabletView ? "h4" : "h3"}>
        <b>{content[0]}&nbsp;</b>
      </Typography>
      <GradientTypography
        // @ts-ignore - TEMP FIX
        component={nextLine ? "div" : "span"}
        variant={tabletView ? "h4" : "h3"}
      >
        <b>{content[1]}</b>
      </GradientTypography>
    </Box>
  );
};

export default SectionHeader;
