import {
  Box,
  // Button,
  Chip,
  // Dialog,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { BaseModal } from "../BaseModal";
import { ButtonSmall } from "../CustomButtons";
// import { RarityChip } from "../RarityChip";
import { TraitCard } from "../TraitCard";
import { useProductGalleryContext } from "./GalleryContext";

export interface ProductDetailsModalProps {}

const ProductDetailsModal: React.FC<ProductDetailsModalProps> = () =>
  //{}: // open,
  // onClose,
  // ProductDetailsModalProps
  {
    const { selectedProduct, toggleDetailsModal, showDetailsModal } =
      useProductGalleryContext();
    const isMobileView: boolean = useMediaQuery("(max-width:600px)");

    return (
      <BaseModal
        title=""
        open={showDetailsModal}
        onClose={() => toggleDetailsModal(false)}
      >
        <Box component="div" sx={{ display: "flex", mb: "1rem" }}>
          <Box
            component="img"
            src={selectedProduct?.imageUrl}
            alt={selectedProduct?.name}
            sx={{
              width: {
                xs: "7.5rem",
                sm: "10rem",
              },
              height: {
                xs: "7.5rem",
                sm: "10rem",
              },
              mr: "1rem",
              borderRadius: "1.5rem",
            }}
          />
          <Box
            component="div"
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: isMobileView ? "column" : "row",
                alignItems: isMobileView ? "start" : "center",
                mb: "0.5rem",
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  mr: "0.5rem",
                  mb: isMobileView ? "0.5rem" : 0,
                  fontWeight: 500,
                }}
              >
                {selectedProduct?.name}
              </Typography>
              <Chip
                label={selectedProduct?.rarity}
                sx={{
                  padding: {
                    sm: "0.2rem 0.25rem",
                  },
                  background:
                    "linear-gradient(90deg, #FF9589 0%, #FF6CF0 100%)",
                  fontSize: {
                    xs: "0.85rem",
                    sm: "1rem",
                  },
                }}
              />
            </Box>
            <Typography
              sx={{
                color: (theme) => theme.palette.grey[500],
                fontSize: {
                  xs: "0.85rem",
                  sm: "1rem",
                },
                mb: "0.5rem",
              }}
            >
              Owned by {selectedProduct?.owner}
            </Typography>
            {!isMobileView && (
              <ButtonSmall variant="contained" sx={{ mt: "auto" }}>
                View in Marketplace
              </ButtonSmall>
            )}
          </Box>
        </Box>
        {isMobileView && (
          <ButtonSmall
            variant="contained"
            sx={{ mt: "auto", mb: "1rem" }}
            fullWidth
          >
            View in Marketplace
          </ButtonSmall>
        )}
        <Typography variant="h5" component="h3" sx={{ mb: "1rem", mt: "2rem" }}>
          Traits:
        </Typography>
        <Grid container spacing={2}>
          {selectedProduct?.traits?.map((item, index: number) => (
            <Grid key={index} item xs={12} sm={6}>
              <TraitCard />
            </Grid>
          ))}
        </Grid>
      </BaseModal>
    );
  };

export { ProductDetailsModal };
