import React, { useState } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";
import {
  ButtonLarge,
  // ButtonSmall,
  FeaturedCompanies,
  FullPageCarousel,
  GradientTypography,
  SectionHeader,
} from "../../components";
import { Panel } from "../../models";
import AboutNftvClub from "../../assets/graphics/about-nftv-club.jpg";
import AboutNftvClubLight from "../../assets/graphics/about-nftv-club.jpg";
import InTheNftvMetaverseNew from "../../assets/graphics/in-the-nftv-metaverse-dark.jpg";
// import InTheNftvMetaverse from "../../assets/graphics/in-the-nftv-metaverse.png";
import InTheNftvMetaverseLight from "../../assets/graphics/in-the-nftv-metaverse-light.jpg";
import PandaCollection from "../../assets/graphics/pandas-collection.png";
import PandaCollectionLight from "../../assets/graphics/pandas-collection-light.png";
import PandaCollectionMobile from "../../assets/graphics/pandas-collection-mobile.png";
import PandaCollectionMobileLight from "../../assets/graphics/pandas-collection-mobile-light.png";
import LootboxRarity from "../../assets/graphics/lootbox-rarity.png";
import LootboxRarityMobile from "../../assets/graphics/lootbox-rarity-mobile.png";
import CompaniesBg from "../../assets/graphics/companies-bg.gif";
import Collaboration from "../../assets/graphics/collaboration.svg";
import CollaborationLight from "../../assets/graphics/collaboration-light.png";
import CollaborationMobile from "../../assets/graphics/collaboration-mobile.png";
import CollaborationMobileLight from "../../assets/graphics/collaboration-mobile-light.png";
import PanelWrapper from "../../components/PanelWrapper";
import Economy1 from "../../assets/pages/home/economy/economy-1.svg";
import Economy2 from "../../assets/pages/home/economy/economy-2.svg";
import Economy3 from "../../assets/pages/home/economy/economy-3.svg";
import Economy4 from "../../assets/pages/home/economy/economy-4.svg";
import Economy5 from "../../assets/pages/home/economy/economy-5.svg";
import Economy6 from "../../assets/pages/home/economy/economy-6.svg";
import { ReactComponent as Activity1 } from "../../assets/pages/home/activities/activity-1.svg";
import { ReactComponent as Activity2 } from "../../assets/pages/home/activities/activity-2.svg";
import { ReactComponent as Activity3 } from "../../assets/pages/home/activities/activity-3.svg";
import { ReactComponent as Activity4 } from "../../assets/pages/home/activities/activity-4.svg";
import { ReactComponent as Activity5 } from "../../assets/pages/home/activities/activity-5.svg";
import AllPhases from "../../assets/pages/home/phases/all-phases.png";
// import Lootbox from "../../assets/graphics/lootbox.gif";
import { useAppSelector } from "../../app/hooks";
import { selectAppState } from "../../features/appStateSlice";
// import { Minting } from "../../features/Minting";

const Home: React.FC = () => {
  // const theme = useTheme();
  const mobileView = useMediaQuery("(max-width:600px)");
  const tabletView = useMediaQuery("(max-width:960px)");
  const { darkMode } = useAppSelector(selectAppState);

  const [viewMore, setViewMore] = useState<boolean>(false);
  // const [selected, setSelected] = useState<string>("babyPandas");

  const contentVariant = tabletView ? "body2" : "h6";

  const metaverseEconomicModelList = [
    {
      content: "PRIVACY & MONITORED DATA",
      image: Economy1,
    },
    {
      content: "TRUTH AND FALSEHOODS",
      image: Economy2,
    },
    {
      content: "OWNERSHIP OF DATA",
      image: Economy3,
    },
    {
      content: "DOMINEERING INFLUENCE",
      image: Economy4,
    },
    {
      content: "MIDDLEMEN & ADVERTISING CHANNELS",
      image: Economy5,
    },
    {
      content: "FREEWILL SOCIAL ACTIVITIES",
      image: Economy6,
    },
  ];

  const clubActivitiesList = [
    {
      title: "OWNERSHIP OF DECENTRALIZED DATA",
      Image: Activity1,
    },
    {
      title: "EMPOWERING A CREATOR'S PARADISE",
      Image: Activity2,
    },
    {
      title: "ENABLE SOCIAL REWARDS",
      Image: Activity3,
    },
    {
      title: "DELIVERING SOCIAL TRUTH",
      Image: Activity4,
    },
    {
      title: "TRANSPARENT ADVERTISING",
      Image: Activity5,
    },
  ];

  const rarityList = [
    {
      label: "Normal",
      color: "#fe6f7f",
      value: "50%",
    },
    {
      label: "Epic",
      color: "#e1b035",
      value: "6.2%",
    },
    {
      label: "Rare",
      color: "#5b4eed",
      value: "25%",
    },
    {
      label: "Legendary",
      color: "#695bc3",
      value: "3.2%",
    },
    {
      label: "Super Rare",
      color: "#fe83b6",
      value: "15%",
    },
    {
      label: "Mythic",
      color: "#1aa8c4",
      value: "0.6%",
    },
  ];

  const panelList: Panel[] = [
    {
      key: "About NFTv Club",
      component: (
        <PanelWrapper
          background={darkMode ? AboutNftvClub : AboutNftvClubLight}
          gutter
          overlay
        >
          <Box
            component="div"
            sx={{
              px: {
                xs: 1,
                sm: 4,
                lg: 6,
              },
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: viewMore ? "flex-start" : "flex-end",
            }}
          >
            <SectionHeader content={["About", "NFTv Club"]} />
            <Box component="div" sx={{ mb: 3 }}>
              <Typography variant={contentVariant}>
                Our NFT gives you unparalleled access to a brand new web 3.0
                SocialFi platform where you can experience Social, Fashion and
                Entertainment all at one place. In addition to bringing IP
                collaboration and social experiences onto Web 3.0, the NFTV
                SocialFi platform and its DAO governance system empower content
                creators and consumers to build and make meaningful decisions
                that will shape the future of Web 3.0 SocialFi on how they live
                and play in. Our world is your canvas.
              </Typography>
            </Box>
            <Box component="div">
              <ButtonLarge
                variant="contained"
                onClick={() => setViewMore(!viewMore)}
              >
                {viewMore ? (
                  <>
                    View Less <KeyboardArrowUpRounded />
                  </>
                ) : (
                  <>
                    View More <KeyboardArrowDownRounded />
                  </>
                )}
              </ButtonLarge>
            </Box>

            {viewMore && (
              <>
                <Box
                  component="div"
                  sx={{ mt: { xs: 3, sm: 6 }, mb: { xs: 2, sm: 4 } }}
                >
                  <Typography
                    component="span"
                    variant={mobileView ? "h5" : "h4"}
                  >
                    <b>NFTv aims to tackle the challenges of &nbsp;</b>
                  </Typography>
                  <GradientTypography
                    component="span"
                    variant={mobileView ? "h5" : "h4"}
                  >
                    <b>Web 2.0 Social Media Environment</b>
                  </GradientTypography>
                </Box>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 1, sm: 8, md: 12 }}
                >
                  {metaverseEconomicModelList.map(({ content, image }) => (
                    <Grid
                      item
                      xs={2}
                      sm={4}
                      md={4}
                      key={content}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <img src={image} alt={content} />
                      <Typography
                        variant={mobileView ? "body2" : "subtitle1"}
                        sx={{ ml: 3 }}
                      >
                        {content}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "Evolution from Web 2.0 to Web 3.0 NFTV SocialFi Platform",
      component: (
        <PanelWrapper
          background={
            darkMode ? InTheNftvMetaverseNew : InTheNftvMetaverseLight
          }
          gutter
          overlay
        >
          <Box
            component="div"
            sx={{
              height: "100%",
              px: {
                xs: 1,
                sm: 4,
                lg: 6,
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              "@media (max-height: 750px)": {
                justifyContent: "flex-start",
              },
            }}
          >
            <SectionHeader
              content={[
                "Evolution from Web 2.0 to Web 3.0",
                "NFTV SocialFi Platform",
              ]}
            />
            <Box component="div" sx={{ mb: { xs: 1, sm: 4 } }}>
              <Typography variant={contentVariant}>
                Users with NFTV NFTs gain lifetime access to the NFTV SocialFi
                platform, with exclusive privileges to FashionTV clubs and
                merchandises, and many SocialFi utilities such as free web 3.0
                storage space, genesis account and social media handles
                creations.
              </Typography>
            </Box>
            <Box component="div" sx={{ mb: { xs: 3, sm: 5 } }}>
              <Typography variant={contentVariant}>
                The NFTV SocialFi Platform aims to:
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {clubActivitiesList.map(({ title, Image }) => (
                <Box
                  component="div"
                  key={title}
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: {
                      xs: "45%",
                      sm: "18%",
                    },
                    mb: {
                      xs: 3,
                      sm: 0,
                    },
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      height: 58,
                      width: 58,
                    }}
                  >
                    <Image />
                  </Box>
                  <Typography
                    variant={contentVariant}
                    sx={{ mt: { xs: 1, sm: 7 } }}
                  >
                    {title}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "The FTV Pandas Collection",
      component: (
        <PanelWrapper
          background={
            tabletView
              ? darkMode
                ? PandaCollectionMobile
                : PandaCollectionMobileLight
              : darkMode
              ? PandaCollection
              : PandaCollectionLight
          }
          gutterTop
          gutterLeft
          gutterBottom
        >
          <Box
            component="div"
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              px: 0,
            }}
          >
            <Box
              component="div"
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
                width: "45%",
              }}
            ></Box>
            <Box
              component="div"
              sx={{
                height: "100%",
                width: {
                  xs: "100%",
                  md: "50%",
                },
                display: "flex",
                flexDirection: "column",
                justifyContent: {
                  xs: "flex-end",
                  md: "flex-start",
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  pl: { xs: 0, sm: 3, md: 0 },
                  pr: { xs: 0, sm: 3, md: 12 },
                }}
              >
                <SectionHeader content={["FTV PANDA", "NFT"]} />
                <Typography variant={contentVariant} sx={{ mb: 4 }}>
                  FTV Panda is made by 9999 panda images of NFT collectibles,
                  each with completely unique characteristics.
                </Typography>
                <Box component="div">
                  <Typography variant={contentVariant}>
                    Some traits are rarer than others, this means collectors
                    value them more. Owning FTV Panda NFT brings upon numerous
                    perks upon joining the NFTv SocialFi Platform such as
                    earning airdrops of tokens, future NFTs and other NFTv
                    SocialFi benefits.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "Lootbox Rarity",
      component: (
        <PanelWrapper
          background={mobileView ? LootboxRarityMobile : LootboxRarity}
          backgroundCenter
          gutter
        >
          <Box
            component="div"
            sx={{
              width: {
                xs: "100%",
                sm: "60%",
                md: "50%",
              },
              pl: {
                xs: 1,
                sm: 4,
                lg: 6,
              },
            }}
          >
            <SectionHeader content={["FTV Panda", "Lootbox Rarity"]} />
            <Box
              component="div"
              sx={{
                pr: {
                  xs: 1,
                  sm: 4,
                  lg: 6,
                },
              }}
            >
              <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }}>
                {rarityList.map(({ label, color, value }) => (
                  <Grid item xs={6} key={label}>
                    <Typography variant="h5" gutterBottom>
                      {label}
                    </Typography>
                    <Box
                      component="div"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box
                        component="div"
                        sx={{
                          height: 8,
                          width: 8,
                          borderRadius: "50%",
                          backgroundColor: color,
                          mr: 2,
                        }}
                      />
                      <Typography variant="body1">{value}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "Panda NFT Launch Phases",
      component: (
        <PanelWrapper gutterLeft gutterRight>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
              textAlign: "center",
            }}
          >
            <Box component="div">
              <SectionHeader content={["Panda", "NFT Launch Phases"]} />
            </Box>
            <Box
              component="div"
              sx={{ height: "45vh", textAlign: "center", pt: 3 }}
            >
              <img
                src={AllPhases}
                alt="roadmap"
                height="100%"
                style={{ maxWidth: "100%" }}
              />
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
    // {
    //   key: "Sale Schedule",
    //   component: <Minting />,
    // },
    // {
    //   key: "FTV Panda Ecosystem",
    //   component: (
    //     <PanelWrapper gutter>
    //       <Box
    //         sx={{
    //           pl: {
    //             xs: 1,
    //             sm: 4,
    //             lg: 6,
    //           },
    //         }}
    //       >
    //         <SectionHeader content={["FTV", "Panda Ecosystem"]} />
    //       </Box>
    //     </PanelWrapper>
    //   ),
    // },
    {
      key: "As Featured In",
      component: (
        <PanelWrapper
          background={darkMode ? CompaniesBg : ""}
          backgroundShadow
          gutterLeft
          gutterRight
          scrollShadow
        >
          <Box
            component="div"
            sx={{
              mt: {
                xs: "15vh",
                sm: "20vh",
              },
              px: {
                xs: 1,
                lg: 16,
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FeaturedCompanies />
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "Collaboration",
      component: (
        <PanelWrapper
          background={
            tabletView
              ? darkMode
                ? CollaborationMobile
                : CollaborationMobileLight
              : darkMode
              ? Collaboration
              : CollaborationLight
          }
          backgroundColor="#090d13"
          backgroundCenter
          gutter
        />
      ),
    },
  ];

  return <FullPageCarousel panelList={panelList} />;
};

export default Home;
