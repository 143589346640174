import { Chip, Grid, useMediaQuery } from "@mui/material";
import { Column } from "react-table";
import { InfiniteTableList } from "../InfiniteTableList";
import { Product, useProductGalleryContext } from "./GalleryContext";
import { ProductDetails } from "./ProductDetails";

const EmailAddressCell = ({ value }: any) => <Chip label={value} />;

const COLUMNS: Column<Product>[] = [
  { Header: "Item", accessor: "name", width: "50%" },
  {
    Header: "Rarity",
    accessor: "rarity",
    width: "20%",
    Cell: EmailAddressCell,
  },
  { Header: "ID", accessor: "traitsCount", width: "5%" },
  { Header: "Owner", accessor: "owner", width: "25%" },
];

const ListGallery: React.FC = () => {
  const isMobileView: boolean = useMediaQuery("(max-width:600px)");
  const { onRowClick, loadMoreItems, isItemLoaded, data, error } =
    useProductGalleryContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8} md={9}>
        <InfiniteTableList
          onRowClick={onRowClick}
          onLoadMore={loadMoreItems}
          isItemLoaded={isItemLoaded}
          height="calc(100vh - 300px)"
          columns={COLUMNS}
          data={data}
          error={error}
        />
      </Grid>
      {isMobileView ? null : (
        <Grid item sm={4} md={3} sx={{ padding: "1rem" }}>
          <ProductDetails />
        </Grid>
      )}
    </Grid>
  );
};

export { ListGallery };
