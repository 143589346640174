import React from "react";
import { FullPageCarousel, PanelWrapper } from "../../components";
import ComingSoon from "../../assets/graphics/coming-soon.svg";
import ComingSoonMobile from "../../assets/graphics/coming-soon-mobile.png";
import { Panel } from "../../models";
import { useMediaQuery } from "@mui/material";
// import { BybitNftvSocial } from "../../features/BybitNftvSocial";

const Clan: React.FC = () => {
  const tabletView = useMediaQuery("(max-width:960px)");

  const panelList: Panel[] = [
    // {
    //   key: "Bybit x NFTv.social",
    //   component: <BybitNftvSocial />,
    // },
    {
      key: "Coming Soon",
      component: (
        <PanelWrapper
          background={tabletView ? ComingSoonMobile : ComingSoon}
          backgroundCenter
          gutter
        />
      ),
    },
  ];

  return <FullPageCarousel panelList={panelList} />;
};

export default Clan;
