import React from "react";
import SVG from "react-inlinesvg";

export interface CountryFlagProps {
	filepath: string;
}

const CountryFlag: React.FC<CountryFlagProps> = ({
	filepath,
}: CountryFlagProps) => {

	return filepath ? (
		<SVG
			src={filepath}
			width={3}
			height="3px"
			title="React"
		/>
	) : null;
};

export { CountryFlag };
