import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { common } from "@mui/material/colors";
import { Box, styled } from "@mui/material";
import { Gallery } from "./Gallery";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";

// import required modules
import { Scrollbar, EffectCoverflow } from "swiper";

export interface CollectionShowcaseItem {
  thumbnail: string;
  title: string;
  images: string[];
}

export interface CollectionShowcaseProps {
  list: CollectionShowcaseItem[];
  onActiveCollectionIndexChange: (index: number) => void;
}

const MainSwiper = styled(Swiper)(({ theme }) => ({
  flex: 1,
  width: "100%",
  height: "auto",
  transition: "opacity 0.6s ease",
  marginTop: "3rem",
  "& .swiper-wrapper": {
    alignItems: "center",
  },
  "& .swiper-slide": {
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    opacity: 0.4,
  },
  "& .swiper-slide img": {
    width: "100%",
    height: "auto",
  },
  "& .swiper-slide-active": {
    opacity: 1,
  },
  "& .swiper-button-prev, .swiper-button-next": {
    color: common.white,
  },
  "& .swiper-slide-shadow-left, .swiper-slide-shadow-right": {
    backgroundImage: "unset",
  },
}));

const CollectionShowcase: React.FC<CollectionShowcaseProps> = ({
  list,
  onActiveCollectionIndexChange,
}: CollectionShowcaseProps) => {
  const [activeCollectionIndex, setActiveCollectionIndex] =
    React.useState<number>();
  const activeCollectionGallery: string[] = React.useMemo(
    () =>
      activeCollectionIndex === undefined
        ? []
        : list[activeCollectionIndex].images,
    [list, activeCollectionIndex]
  );

  return (
    <Box component="div" sx={{ position: "relative" }}>
      <MainSwiper
        scrollbar={{
          hide: true,
        }}
        modules={[Scrollbar, EffectCoverflow]}
        speed={800}
        slidesPerView={2}
        effect="coverflow"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        onActiveIndexChange={(swiper) =>
          onActiveCollectionIndexChange(swiper.realIndex)
        }
        grabCursor
        parallax
        init
        loop
        centeredSlides
      >
        {list.map((item: CollectionShowcaseItem, index: number) => (
          <SwiperSlide
            key={index}
            onClick={() => setActiveCollectionIndex(index)}
          >
            <img src={item.thumbnail} alt={item.title} />
          </SwiperSlide>
        ))}
      </MainSwiper>
      <Gallery
        images={activeCollectionGallery}
        show={activeCollectionIndex !== undefined}
        onDismiss={() => setActiveCollectionIndex(undefined)}
      />
    </Box>
  );
};

export { CollectionShowcase };
