import React from "react";
import { BigNumber, ethers } from "ethers";
import {
  formatEthereumBigNumber,
  formatEtherFromRawData,
} from "../ethereum.util";

import {
  OnErrorCallback,
  useWagmiContractRead,
  // useGenesisBoxContractRead,
} from "./useWagmiContractRead";
import {
  // useWagmiContractWrite,
  useGenesisBoxContractWrite,
  WagmiContractWriteParams,
} from "./useWagmiContractWrite";
import NftvMintingWhitelistMerkle from "../../assets/contracts/JSON/NftvMintingWhitelistMerklePhase3.json";

import { useWeb3Context } from "./useWeb3Context";

type MintBoxContractHookResponse = {
  maxRoundSupply: string;
  mintLeft: number;
  soldPerRound: string;
  tokenPrice: string;
  totalMintingAmount: number;
  isMaxRoundSupplyLoading: boolean;
  isTokenPriceLoading: boolean;
  isRoundSoldDataLoading: boolean;
  transactionLink: string | undefined;
  mint: ((overrideConfig?: any) => void) | undefined;
};

type MintBoxContractHookParams = {
  onReadError: OnErrorCallback;
  mintQuantity: number;
  mintConfig: Pick<
    WagmiContractWriteParams,
    "onError" | "onPrepareError" | "enabled"
  >;
};

export function useMintBoxContract({
  onReadError,
  mintQuantity,
  mintConfig,
}: MintBoxContractHookParams): MintBoxContractHookResponse {
  const { accountAddress, getTransactionLink } = useWeb3Context();
  const [transactionHash, setTransactionHash] = React.useState<string>();
  const merkle: string[] | undefined = React.useMemo(
    () =>
      NftvMintingWhitelistMerkle[
        accountAddress?.toLowerCase?.() as keyof typeof NftvMintingWhitelistMerkle
      ],
    [accountAddress]
  );

  const { data: maxRoundSupplyData, isLoading: isMaxRoundSupplyLoading } =
    useWagmiContractRead("maxRoundSupply", [2], onReadError);

  const { data: tokenPriceData, isLoading: isTokenPriceLoading } =
    useWagmiContractRead("tokenPrice", [2], onReadError);

  const { data: roundSoldData, isLoading: isRoundSoldDataLoading } =
    useWagmiContractRead("roundSold", [2], onReadError);

  const tokenPrice: string = React.useMemo(
    () => formatEtherFromRawData(tokenPriceData as BigNumber),
    [tokenPriceData]
  );

  const totalMintingAmount: number = React.useMemo(
    () => mintQuantity * parseFloat(tokenPrice),
    [mintQuantity, tokenPrice]
  );

  const mintLeft: number = React.useMemo(
    () =>
      Number(formatEthereumBigNumber(maxRoundSupplyData as BigNumber)) -
      Number(formatEthereumBigNumber(roundSoldData as BigNumber)),
    [maxRoundSupplyData, roundSoldData]
  );

  // const { response, refetch } = useWagmiContractWrite({
  //   ...mintConfig,
  //   functionName: "mint",
  //   args: [2, mintQuantity, merkle],
  //   overrides: {
  //     value: ethers.utils.parseEther(`${totalMintingAmount}`),
  //   },
  //   enabled: merkle === undefined || mintConfig.enabled,
  // });

  const { response, refetch } = useGenesisBoxContractWrite({
    ...mintConfig,
    functionName: "mint",
    args: [2, mintQuantity, merkle],
    overrides: {
      value: ethers.utils.parseEther(`${totalMintingAmount}`),
    },
    enabled: merkle === undefined || mintConfig.enabled,
  });

  React.useEffect(() => {
    if (response.data) {
      response.data.wait().then((tx: any) => {
        setTransactionHash(tx.transactionHash);
        refetch();
      });
    }
  }, [refetch, response.data]);

  return {
    maxRoundSupply: formatEthereumBigNumber(maxRoundSupplyData as BigNumber),
    mintLeft,
    tokenPrice,
    totalMintingAmount,
    soldPerRound: formatEthereumBigNumber(roundSoldData as BigNumber),
    isMaxRoundSupplyLoading,
    isTokenPriceLoading,
    isRoundSoldDataLoading,
    transactionLink: transactionHash
      ? getTransactionLink(transactionHash)
      : undefined,
    mint: response.write,
  };
}
