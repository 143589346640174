import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Module } from "./models";
import { ReactComponent as HomeIcon } from "./assets/nav/home.svg";
import { ReactComponent as LitepaperIcon } from "./assets/nav/litepaper.svg";
import { ReactComponent as AboutUsIcon } from "./assets/nav/about-us.svg";
import { ReactComponent as NftvPlatformIcon } from "./assets/nav/nftv-token.svg";
import { ReactComponent as NftMarketIcon } from "./assets/nav/nft-market.svg";
import { ReactComponent as MerchandiseIcon } from "./assets/nav/merchandise.svg";
import { ReactComponent as ClanIcon } from "./assets/nav/clan.svg";
import { ReactComponent as NftvMintingIcon } from "./assets/nav/nftv-minting.svg";
import { ReactComponent as KycCreditCardIcon } from "./assets/nav/nftv-cc-kyc.svg";
import { ReactComponent as GalleryIcon } from "./assets/nav/gallery.svg";
import { AppLayout, Landing } from "./modules";
import {
  AboutUs,
  Litepaper,
  Home,
  NftMarket,
  NftvPlatform,
  Clan,
  Merchandise,
  NftvMinting,
  DebitCardKYC,
  Gallery,
} from "./modules/submodules";

export const submodules = [
  {
    index: 0,
    label: "Home",
    path: "",
    Icon: HomeIcon,
    page: <Home />,
  },
  {
    index: 1,
    label: "About Us",
    path: "/about",
    Icon: AboutUsIcon,
    page: <AboutUs />,
  },
  {
    index: 2,
    label: "NFTv Platform",
    path: "/platform",
    Icon: NftvPlatformIcon,
    page: <NftvPlatform />,
  },
  {
    index: 3,
    label: "Gallery",
    path: "/gallery",
    Icon: GalleryIcon,
    page: <Gallery />,
  },
  {
    index: 4,
    label: "NFTv Merchandise",
    path: "/merchandise",
    Icon: MerchandiseIcon,
    page: <Merchandise />,
  },
  {
    index: 5,
    label: "NFT Market",
    path: "/market",
    Icon: NftMarketIcon,
    page: <NftMarket />,
  },
  {
    index: 6,
    label: "Litepaper",
    path: "/litepaper",
    Icon: LitepaperIcon,
    page: <Litepaper />,
  },
  {
    index: 7,
    label: "Clan",
    path: "/clan",
    Icon: ClanIcon,
    page: <Clan />,
  },
  {
    index: 8,
    label: "Minting Page",
    path: "/mint",
    Icon: NftvMintingIcon,
    page: <NftvMinting />,
  },
  {
    index: 8,
    label: "Debit Card",
    path: "/debit-card-kyc",
    Icon: KycCreditCardIcon,
    page: <DebitCardKYC />,
  },
];

export const rootModule: Module[] = [
  {
    index: 0,
    label: "Landing",
    path: "/",
    page: <Landing />,
  },
  {
    index: 1,
    label: "App",
    path: "/app/*",
    page: <AppLayout submodules={submodules} />,
  },
];

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {rootModule.map((module: Module) => {
          return (
            <Route
              key={`${module.path}`}
              path={`${module.path}`}
              element={module.page}
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
