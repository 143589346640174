import { Box } from "@mui/material";
import React from "react";
import { ProductGalleryContext } from "./GalleryContext";
import { GalleryLayoutType } from "./GalleryLayoutSelector";
import { GridGallery } from "./GridGallery";
import { ListGallery } from "./ListGallery";
import {
  ProductDetailsModal,
  // ProductDetailsModalProps,
} from "./ProductDetailsModal";
import {
  FilterValue,
  ProductGalleryFilterGroup,
  ProductGallerySideFilter,
} from "./ProductGallerySideFilter";
import { ProductGalleryTopBarFilter } from "./ProductGalleryTopBarFilter";

export interface ProductGalleryProps {
  id: string;
}

const FILTER_GROUPS: ProductGalleryFilterGroup[] = [
  {
    label: "Background",
    id: "background",
    list: [
      {
        label: "Red",
        value: "red",
      },
      {
        label: "Blue",
        value: "blue",
      },
    ],
  },
  {
    label: "Fur Type",
    id: "furtype",
    list: [
      {
        label: "Curly",
        value: "curly",
      },
      {
        label: "Rugged",
        value: "rugged",
      },
      {
        label: "Rugged long long long long",
        value: "rugged eee",
      },
    ],
  },
];

const ProductGallery: React.FC<ProductGalleryProps> = ({
  id,
}: ProductGalleryProps) => {
  const [toggleSideFilter, setToggleSideFilter] =
    React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<FilterValue>({});
  const [galleryLayout, setGalleryLayout] =
    React.useState<GalleryLayoutType>("grid");

  const renderGallery = () => {
    switch (galleryLayout) {
      case "grid":
        return <GridGallery itemsPerRow={4} />;
      case "grid-sm":
        return <GridGallery itemsPerRow={7} />;
      default:
        return <ListGallery />;
    }
  };

  return (
    <ProductGalleryContext layout={galleryLayout}>
      <Box
        component="div"
        id={id}
        sx={{
          height: "auto",
          minHeight: "100%",
          // pb: {
          // 	xs: '170px',
          // 	md: '20vh',
          // },
          pl: {
            xs: "3%",
            md: "calc(3% + 100px)",
          },
          mr: {
            xs: "3%",
            md: "calc(3% + 60px)",
          },
        }}
      >
        <ProductGallerySideFilter
          open={toggleSideFilter}
          onClose={() => setToggleSideFilter(false)}
          groups={FILTER_GROUPS}
          value={filter}
          onFilterChange={setFilter}
        />
        <ProductGalleryTopBarFilter
          onFilterClick={() =>
            setToggleSideFilter((isShow: boolean) => !isShow)
          }
          onLayoutChange={setGalleryLayout}
          layout={galleryLayout}
        />
        {renderGallery()}
        <ProductDetailsModal />
      </Box>
    </ProductGalleryContext>
  );
};

export { ProductGallery };
