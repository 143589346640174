import React from "react";
import { useTheme } from "@mui/material";
import {
	ComposableMap,
	Geographies,
	Geography,
	ZoomableGroup,
} from "react-simple-maps";
import Countries50m from "../../assets/maps/ne_50m_admin_0_countries.json";
import { CityNodes } from "./CityNodes";
import { CountryNodes } from "./CountryNodes";
import "./nftv-map.css";

export interface NftvMapProps {
	onMoveStart?: () => void;
	onMoveEnd?: () => void;
}

const NftvMap: React.FC<NftvMapProps> = ({ onMoveStart, onMoveEnd }) => {
	const { palette } = useTheme();

	return (
		<ComposableMap
			projection="geoMercator"
			style={{ width: "100%", height: "100%" }}
		>
			<ZoomableGroup
				center={[0, 0]}
				zoom={1}
				onMoveStart={onMoveStart}
				onMoveEnd={onMoveEnd}
			>
				<Geographies geography={Countries50m}>
					{({ geographies }) =>
						geographies.map((geo) => (
							<Geography
								key={geo.rsmKey}
								geography={geo}
								fill={
									palette.mode === "light"
										? "lightgrey"
										: undefined
								}
								className="focus:outline-none"
							/>
						))
					}
				</Geographies>
				<CountryNodes />
				<CityNodes />
			</ZoomableGroup>
		</ComposableMap>
	);
};

export { NftvMap };
