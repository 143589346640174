/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 planet.glb --types --transform
*/

import * as THREE from "three";
import { useAnimations, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useEffect, useRef } from "react";
import { EffectComposer, Vignette } from "@react-three/postprocessing";

// Import glb file
import planetObj from "./planet-transformed.glb";

type GLTFResult = GLTF & {
  nodes: {
    Circle014: THREE.Mesh;
    Circle014_1: THREE.Mesh;
    Circle014_2: THREE.Mesh;
    Circle014_3: THREE.Mesh;
    Circle014_4: THREE.Mesh;
    Circle014_5: THREE.Mesh;
    Circle014_6: THREE.Mesh;
    Circle014_7: THREE.Mesh;
    Circle014_8: THREE.Mesh;
    Sphere002: THREE.Mesh;
    Sphere002_1: THREE.Mesh;
    Sphere002_2: THREE.Mesh;
    Sphere002_3: THREE.Mesh;
    Sphere002_4: THREE.Mesh;
    Sphere002_5: THREE.Mesh;
    Sphere002_6: THREE.Mesh;
    Sphere002_7: THREE.Mesh;
    Sphere002_8: THREE.Mesh;
    Sphere002_9: THREE.Mesh;
    Virus: THREE.Mesh;
    Sphere006: THREE.Mesh;
    Sphere006_1: THREE.Mesh;
    Orbit: THREE.Mesh;
    Jupiter: THREE.Mesh;
    Moon: THREE.Mesh;
    Plane006: THREE.Mesh;
    Plane006_1: THREE.Mesh;
    Plane006_2: THREE.Mesh;
    Plane006_3: THREE.Mesh;
  };
  materials: {
    ["blue flame.003"]: THREE.MeshStandardMaterial;
    ["war head"]: THREE.MeshStandardMaterial;
    ["Material.006"]: THREE.MeshBasicMaterial;
    Leggy: THREE.MeshStandardMaterial;
    ["TEAL EXHAUST"]: THREE.MeshStandardMaterial;
    ["lense ring"]: THREE.MeshStandardMaterial;
    ["Blue glossy glass.001"]: THREE.MeshStandardMaterial;
    ["PURPLE  EXHAUST"]: THREE.MeshStandardMaterial;
    ["uv unWRAP.003"]: THREE.MeshStandardMaterial;
    ["UFO GLASS.002"]: THREE.MeshStandardMaterial;
    ["UFO DASH BOARD"]: THREE.MeshStandardMaterial;
    ["Material.001"]: THREE.MeshStandardMaterial;
    ["UFO BASE.teal"]: THREE.MeshStandardMaterial;
    ["uFO DASHBOARD.002"]: THREE.MeshStandardMaterial;
    ["Material.004"]: THREE.MeshStandardMaterial;
    ["SQUARE RED BUTTON"]: THREE.MeshStandardMaterial;
    ["bUTTON YELLOW.002"]: THREE.MeshStandardMaterial;
    ["bUTTON BLUE.002"]: THREE.MeshStandardMaterial;
    ["Button red"]: THREE.MeshStandardMaterial;
    ["RED COMET"]: THREE.MeshStandardMaterial;
    ["Light Blue"]: THREE.MeshStandardMaterial;
    ["Dark blue"]: THREE.MeshStandardMaterial;
    Material: THREE.MeshStandardMaterial;
    ["材质.004"]: THREE.MeshStandardMaterial;
    ["材质.005"]: THREE.MeshStandardMaterial;
    material_token_tick: THREE.MeshStandardMaterial;
    material_token_inner: THREE.MeshStandardMaterial;
    material_token_edge: THREE.MeshStandardMaterial;
    material_token_text: THREE.MeshStandardMaterial;
  };
};

type ActionName =
  | "VirusAction"
  | "TOKEN.003Action"
  | "BubblegumAction"
  | "Rocket.008Action.005"
  | "Rocket.008Action"
  | "Rocket.008Action.008"
  | "RocketAction"
  | "JupiterAction"
  | "MoonAction"
  | "UFOAction"
  | "TOKEN.003Action.002"
  | "TokenAction";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export function Planet(
  props: JSX.IntrinsicElements["group"] & {
    darkMode: boolean;
  }
) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials, animations } = useGLTF(planetObj) as GLTFResult;
  // @ts-ignore
  const { actions } = useAnimations<GLTFActions>(animations, group);

  // Play animation
  useEffect(() => {
    // @ts-ignore
    actions.VirusAction.play();
    // @ts-ignore
    actions.BubblegumAction.play();
    // @ts-ignore
    actions.RocketAction.play();
    // @ts-ignore
    actions.JupiterAction.play();
    // @ts-ignore
    actions.MoonAction.play();
    // @ts-ignore
    actions.UFOAction.play();
    // @ts-ignore
    actions.TokenAction.play();
  });

  return (
    <>
      <EffectComposer autoClear={false}>
        <Vignette offset={0.1} darkness={1} />
      </EffectComposer>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group
            name="TOKEN003"
            position={[0.12, 1.32, 0.1]}
            rotation={[1.36, -0.35, 0.36]}
            scale={[2.04, 2.43, 2.04]}
          />
          <group
            name="NurbsPath"
            position={[13.61, -3.19, 8.11]}
            rotation={[-0.7, -0.41, -0.08]}
          >
            <group
              name="Rocket005"
              position={[-8.41, 0.46, -0.64]}
              rotation={[0.36, 0.45, 0.75]}
              scale={0.53}
            />
            <group
              name="Rocket008"
              position={[-8.41, 0.46, -0.64]}
              rotation={[0.36, 0.45, 0.75]}
              scale={0.53}
            />
            <group
              name="Rocket011"
              position={[-8.41, 0.46, -0.64]}
              rotation={[0.36, 0.45, 0.75]}
              scale={0.53}
            />
            <group
              name="Rocket"
              position={[-8.41, 0.46, -0.64]}
              rotation={[0.36, 0.45, 0.75]}
              scale={0.53}
            >
              <mesh
                name="Circle014"
                geometry={nodes.Circle014.geometry}
                material={materials["blue flame.003"]}
              />
              <mesh
                name="Circle014_1"
                geometry={nodes.Circle014_1.geometry}
                material={materials["war head"]}
              />
              <mesh
                name="Circle014_2"
                geometry={nodes.Circle014_2.geometry}
                material={materials["Material.006"]}
              />
              <mesh
                name="Circle014_3"
                geometry={nodes.Circle014_3.geometry}
                material={materials.Leggy}
              />
              <mesh
                name="Circle014_4"
                geometry={nodes.Circle014_4.geometry}
                material={materials["TEAL EXHAUST"]}
              />
              <mesh
                name="Circle014_5"
                geometry={nodes.Circle014_5.geometry}
                material={materials["lense ring"]}
              />
              <mesh
                name="Circle014_6"
                geometry={nodes.Circle014_6.geometry}
                material={materials["Blue glossy glass.001"]}
              />
              <mesh
                name="Circle014_7"
                geometry={nodes.Circle014_7.geometry}
                material={materials["PURPLE  EXHAUST"]}
              />
              <mesh
                name="Circle014_8"
                geometry={nodes.Circle014_8.geometry}
                material={materials["uv unWRAP.003"]}
              />
            </group>
          </group>
          <group
            name="Empty"
            position={[6.5, 3.49, -4.12]}
            rotation={[-2.38, -0.42, -Math.PI]}
            scale={2.27}
          >
            <group
              name="UFO"
              position={[0, -0.39, 0]}
              rotation={[-2.17, 0.66, 2.54]}
              scale={0.41}
            >
              <mesh
                name="Sphere002"
                geometry={nodes.Sphere002.geometry}
                material={materials["UFO GLASS.002"]}
              />
              <mesh
                name="Sphere002_1"
                geometry={nodes.Sphere002_1.geometry}
                material={materials["UFO DASH BOARD"]}
              />
              <mesh
                name="Sphere002_2"
                geometry={nodes.Sphere002_2.geometry}
                material={materials["Material.001"]}
              />
              <mesh
                name="Sphere002_3"
                geometry={nodes.Sphere002_3.geometry}
                material={materials["UFO BASE.teal"]}
              />
              <mesh
                name="Sphere002_4"
                geometry={nodes.Sphere002_4.geometry}
                material={materials["uFO DASHBOARD.002"]}
              />
              <mesh
                name="Sphere002_5"
                geometry={nodes.Sphere002_5.geometry}
                material={materials["Material.004"]}
              />
              <mesh
                name="Sphere002_6"
                geometry={nodes.Sphere002_6.geometry}
                material={materials["SQUARE RED BUTTON"]}
              />
              <mesh
                name="Sphere002_7"
                geometry={nodes.Sphere002_7.geometry}
                material={materials["bUTTON YELLOW.002"]}
              />
              <mesh
                name="Sphere002_8"
                geometry={nodes.Sphere002_8.geometry}
                material={materials["bUTTON BLUE.002"]}
              />
              <mesh
                name="Sphere002_9"
                geometry={nodes.Sphere002_9.geometry}
                material={materials["Button red"]}
              />
            </group>
          </group>
          <group
            name="TOKEN002"
            position={[0.12, 1.32, 0.1]}
            rotation={[1.36, -0.35, 0.36]}
            scale={[2.04, 2.43, 2.04]}
          />
          <mesh
            name="Virus"
            geometry={nodes.Virus.geometry}
            material={materials["RED COMET"]}
            position={[5.81, 6.75, -3.93]}
            scale={1.12}
          />
          <group name="Bubblegum" position={[-5.49, -2.69, 3.38]}>
            <mesh
              name="Sphere006"
              geometry={nodes.Sphere006.geometry}
              material={materials["Light Blue"]}
            />
            <mesh
              name="Sphere006_1"
              geometry={nodes.Sphere006_1.geometry}
              material={materials["Dark blue"]}
            />
          </group>
          <mesh
            name="Orbit"
            geometry={nodes.Orbit.geometry}
            // material={materials.Material}
            material={
              new THREE.MeshBasicMaterial({
                color: props.darkMode ? "#ffffff" : "#7D2DBC",
              })
            }
          />
          <mesh
            name="Jupiter"
            geometry={nodes.Jupiter.geometry}
            material={materials["材质.004"]}
            position={[-5.14, 9.9, -8.56]}
            rotation={[0, 0, -2.37]}
            scale={1.58}
          />
          <mesh
            name="Moon"
            geometry={nodes.Moon.geometry}
            material={materials["材质.005"]}
            position={[1.13, 3.24, 8.47]}
            rotation={[1.73, 1.28, -1.72]}
          />
          <group
            name="Token"
            position={[0.12, 1.32, 0.1]}
            rotation={[1.36, -0.35, 0.36]}
            scale={[2.04, 2.43, 2.04]}
          >
            <mesh
              name="Plane006"
              geometry={nodes.Plane006.geometry}
              material={materials.material_token_tick}
            />
            <mesh
              name="Plane006_1"
              geometry={nodes.Plane006_1.geometry}
              material={materials.material_token_inner}
            />
            <mesh
              name="Plane006_2"
              geometry={nodes.Plane006_2.geometry}
              material={materials.material_token_edge}
            />
            <mesh
              name="Plane006_3"
              geometry={nodes.Plane006_3.geometry}
              material={materials.material_token_text}
            />
          </group>
        </group>
      </group>
    </>
  );
}

// useGLTF.preload("/planet-transformed.glb");
