import { BigNumber } from "ethers";
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import NFTvGenesisBoxABI from "../../assets/contracts/ABI/NFTvGenesisBoxABI.json";
import NFTvPandaABI from "../../assets/contracts/ABI/NFTvPandaABI.json";
import { useWeb3Context } from "./useWeb3Context";

export type OnErrorCallback = ((err: Error) => void) | undefined;

type ContractFunctionName = "mint" | "approve";
type ContractType = "box" | "panda";

export type WagmiContractWriteParams = {
  functionName: ContractFunctionName;
  onError: OnErrorCallback;
  onPrepareError: OnErrorCallback;
  args: readonly unknown[] | undefined;
  overrides?: { value?: number | BigNumber | undefined };
  enabled?: boolean;
};

export type WagmiContractWriteResponse = {
  refetch: (options?: any | undefined) => Promise<any>;
  response: {
    write: ((overrideConfig?: any) => void) | undefined;
    data: any | undefined;
    error: Error | null;
    isError: boolean;
    isIdle: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    reset: () => void;
  };
  transactionResponse: any; // TODO: to add proper type
};

export function useWagmiContractWrite(
  abi: typeof NFTvGenesisBoxABI | typeof NFTvPandaABI,
  contractType: ContractType,
  {
    functionName,
    args,
    overrides,
    enabled,
    onError,
    onPrepareError,
  }: WagmiContractWriteParams
): WagmiContractWriteResponse {
  const { nftvContractAddress, nftvPandaContractAddress } = useWeb3Context();

  const { config, refetch } = usePrepareContractWrite({
    address:
      contractType === "box" ? nftvContractAddress : nftvPandaContractAddress,
    abi,
    functionName,
    enabled,
    args,
    overrides,
    onError: onPrepareError,
  });

  const response = useContractWrite({
    ...(config as any),
    onError,
  });

  const transactionResponse = useWaitForTransaction({
    hash: response.data?.hash,
  });

  return {
    response,
    transactionResponse,
    refetch,
  };
}

export function useGenesisBoxContractWrite(
  props: WagmiContractWriteParams
): WagmiContractWriteResponse {
  const data = useWagmiContractWrite(NFTvGenesisBoxABI, "box", props);
  return data;
}

export function usePandaContractWrite(
  props: WagmiContractWriteParams
): WagmiContractWriteResponse {
  const data = useWagmiContractWrite(NFTvPandaABI, "panda", props);
  return data;
}
