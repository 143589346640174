import React from "react";
import { FullPageCarousel } from "../../components";
import { Panel } from "../../models";
import { PandaGallery } from "../../features/PandaGallery";

const Gallery: React.FC = () => {
  const panelList: Panel[] = [
    {
      key: "Gallery",
      component: <PandaGallery />,
    },
  ];

  return <FullPageCarousel panelList={panelList} />;
};

export default Gallery;
