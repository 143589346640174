import React from "react";
import {
  FullPageCarousel,
  PanelWrapper,
  SectionHeader,
  IconRound,
} from "../../components";
import { Panel } from "../../models";
import { Box, Card, Typography, useMediaQuery } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { selectAppState } from "../../features/appStateSlice";
import BurningMechanism from "../../assets/pages/litepaper/burning-mechanism.svg";
import DaoEcosystemFund from "../../assets/pages/litepaper/dao-ecosystem-fund.svg";
import NftvTokenOwners from "../../assets/pages/litepaper/nftv-token-owners.svg";
import BurningMechanismLight from "../../assets/pages/litepaper/light/burning-mechanism.svg";
import DaoEcosystemFundLight from "../../assets/pages/litepaper/light/dao-ecosystem-fund.svg";
import NftvTokenOwnersLight from "../../assets/pages/litepaper/light/nftv-token-owners.svg";
import PandaScene from "../../assets/scenes/PandaScene";
import PlatformScene from "../../assets/scenes/PlatformScene";
import PlanetScene from "../../assets/scenes/PlanetScene";
import ChartScene from "../../assets/scenes/ChartScene";

const Litepaper: React.FC = () => {
  // const mobileView = useMediaQuery("(max-width:600px)");
  const tabletView = useMediaQuery("(max-width:960px)");
  const { darkMode } = useAppSelector(selectAppState);

  const contentVariant = tabletView ? "body2" : "h6";

  const tokenomicsDistribution = [
    {
      title: "Burning Mechanism",
      content:
        "80% of the fees collected from Loot Boxes sales and transaction fees generated from the NFTv ecosystem will be burned.",
      image: darkMode ? BurningMechanism : BurningMechanismLight,
    },
    {
      title: "DAO Ecosystem Fund",
      content:
        "10% goes to the DAO Ecosystem Fund to continue to maintain the whole ecosystem.",
      image: darkMode ? DaoEcosystemFund : DaoEcosystemFundLight,
    },
    {
      title: "NFTv Token Owners",
      content:
        "10% goes to NFTv token holders based on their NFTv token quantity as a form of weightage to calculate this allocation.",
      image: darkMode ? NftvTokenOwners : NftvTokenOwnersLight,
    },
  ];

  const panelList: Panel[] = [
    {
      key: "NFTv Social Mining",
      component: (
        <PanelWrapper gutterLeft gutterRight scrollShadow>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "15vh",
                sm: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
              height: {
                xs: "70vh",
                sm: "60vh",
              },
            }}
          >
            <SectionHeader content={["NFTv", "Social Mining"]} />
            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                alignItems: "center",
                justifyContent: "center",
                height: {
                  xs: "fit-content",
                  md: "100%",
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  width: {
                    xs: "100%",
                    md: "50%",
                  },
                  pr: {
                    xs: 0,
                    md: 3,
                  },
                  mb: {
                    xs: 3,
                    md: 0,
                  },
                  py: {
                    xs: 3,
                    md: 0,
                  },
                  textAlign: "center",
                }}
              >
                <PandaScene />
              </Box>
              <Box
                component="div"
                sx={{
                  width: {
                    xs: "100%",
                    md: "50%",
                  },
                  height: {
                    xs: "fit-content",
                    md: "100%",
                  },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  pl: {
                    xs: 0,
                    md: 3,
                  },
                }}
              >
                <Typography variant="h4" sx={{ mb: 2 }}>
                  NFTv Token Airdrops
                </Typography>
                <ul>
                  <li>
                    <Typography variant={contentVariant} sx={{ mb: 3 }}>
                      Social Mining in NFTv SocialFi Platform as a node to
                      receive airdrops of NFTv tokens
                    </Typography>
                  </li>
                  <li>
                    <Typography variant={contentVariant} sx={{ mb: 3 }}>
                      Strong governance in the SocialFi DAO to shape the future
                      of web 3.0 social media
                    </Typography>
                  </li>
                  <li>
                    <Typography variant={contentVariant} sx={{ mb: 3 }}>
                      Exclusive access to content creators NFTs
                    </Typography>
                  </li>
                  <li>
                    <Typography variant={contentVariant} sx={{ mb: 3 }}>
                      Discounts to future NFTv products
                    </Typography>
                  </li>
                  <li>
                    <Typography variant={contentVariant} sx={{ mb: 3 }}>
                      A primary marketplace for trading of NFTs
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "As Featured In",
      component: (
        <PanelWrapper gutterLeft gutterRight scrollShadow>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
            }}
          >
            <Box component="div" sx={{ textAlign: "center" }}>
              <SectionHeader content={["NFTv", "SocialFi Platform"]} />
            </Box>
            <PlatformScene />
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "SocialFi Platform",
      component: (
        <PanelWrapper gutter backgroundColor={darkMode ? "#141216" : ""}>
          <Box
            component="div"
            sx={{
              pl: {
                xs: 0,
                md: 6,
              },
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              height: "100%",
            }}
          >
            <Box
              component="div"
              sx={{
                width: {
                  xs: "100%",
                  md: "50%",
                },
                pr: 3,
                height: {
                  xs: "fit-content",
                  md: "100%",
                },
                mb: {
                  xs: 3,
                  md: 0,
                },
              }}
            >
              <SectionHeader content={["SocialFi", "Platform"]} />
              <Typography variant={contentVariant} sx={{ mb: 3 }}>
                Governance: Allow token holders to vote on governance decisions
                in the NFTv DAO.
              </Typography>
              <Typography variant={contentVariant} sx={{ mb: 3 }}>
                Unified consumption: NFTv tokens are also utility tokens for the
                entire ecosystem, which can be used to carry out social
                activities.
              </Typography>
              <Typography variant={contentVariant} sx={{ mb: 3 }}>
                Possessing a certain amount of NFTv tokens can also act as an
                entry pass to the NFTv SocialFi Platform, such as exclusive
                socials events and games.
              </Typography>
              <Typography variant={contentVariant}>
                Enable users to earn tokens based on the time and effort they
                put into the SocialFi Platform.
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{
                width: {
                  xs: "100%",
                  md: "50%",
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: {
                  xs: 1000,
                  md: "100%",
                },
                pl: {
                  xs: 0,
                  md: 3,
                },
              }}
            >
              <PlanetScene darkMode={darkMode} />
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "NFTv Token Distribution",
      component: (
        <PanelWrapper gutter backgroundColor={darkMode ? "#141216" : ""}>
          <Box
            component="div"
            sx={{
              pl: {
                xs: 0,
                md: 6,
              },
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              height: "100%",
            }}
          >
            <Box
              component="div"
              sx={{
                width: {
                  xs: "100%",
                  md: "40%",
                },
                pr: 3,
                height: {
                  xs: "fit-content",
                  md: "100%",
                },
                mb: {
                  xs: 3,
                  md: 0,
                },
              }}
            >
              <SectionHeader
                nextLine={true}
                content={["NFTv", "Token Distribution"]}
              />
              <Typography variant={contentVariant} sx={{ mb: 3 }}>
                <IconRound color="#3AFFAC" />
                <Box component="span" sx={{ pl: 2.5 }}>
                  DAO Ecosystem - 85%
                </Box>
              </Typography>
              <Typography variant={contentVariant} sx={{ mb: 3 }}>
                <IconRound color="#5179D3" />
                <Box component="span" sx={{ pl: 2.5 }}>
                  Team - 5%
                </Box>
              </Typography>
              <Typography variant={contentVariant} sx={{ mb: 3 }}>
                <IconRound color="#F680D7" />
                <Box component="span" sx={{ pl: 2.5 }}>
                  Public - 5%
                </Box>
              </Typography>
              <Typography variant={contentVariant} sx={{ mb: 3 }}>
                <IconRound color="#7C33F0" />
                <Box component="span" sx={{ pl: 2.5 }}>
                  Strategic Partners - 3%
                </Box>
              </Typography>
              <Typography variant={contentVariant}>
                <IconRound color="#4080FF" />
                <Box component="span" sx={{ pl: 2.5 }}>
                  Seed round - 2%
                </Box>
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{
                width: {
                  xs: "100%",
                  md: "60%",
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: {
                  xs: 1000,
                  md: "100%",
                },
                pl: {
                  xs: 0,
                  md: 3,
                },
              }}
            >
              <ChartScene darkMode={darkMode} />
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "NFTv Tokenomics",
      component: (
        <PanelWrapper gutterLeft gutterRight scrollShadow>
          <Box
            component="div"
            sx={{
              my: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
            }}
          >
            <SectionHeader content={["NFTv", "Tokenomics"]} />
            <Typography variant={contentVariant} sx={{ mb: 3 }}>
              FTV pandas lets you earn NFTv Token, the currency and governance
              token of the NFTv SocialFi Platform!
            </Typography>
            <Box
              component="div"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                mb: 5,
              }}
            >
              {tokenomicsDistribution.map(({ title, content, image }) => (
                <Card
                  key={title}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "49%",
                      md: "32%",
                    },
                    py: 3,
                    textAlign: "center",
                    borderRadius: 5,
                    mb: {
                      xs: 2,
                      sm: 3,
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={image}
                    alt={title}
                    height={64}
                    sx={{ fill: "currentColor" }}
                  />
                  <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                    {title}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ mb: 2, mx: 3 }}>
                    {content}
                  </Typography>
                </Card>
              ))}
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
  ];

  return <FullPageCarousel panelList={panelList} />;
};

export default Litepaper;
