import React from "react";
import { Box, styled, useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { PandaCarouselItem } from "./PandaCarouselItem";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";

const CustomPandaCarousel = styled(Swiper)(({ theme }) => ({
  height: "100%",
  width: "100%",
}));

export interface PandaCarouselProps {
  pandas: PandaNFT[];
}

const PandaCarousel: React.FC<PandaCarouselProps> = ({
  pandas,
}: PandaCarouselProps) => {
  const isMobileView: boolean = useMediaQuery("(max-width:375px)");

  return (
    <Box
      component="div"
      sx={{ width: "100%", display: "flex", alignItems: "center" }}
    >
      <Box
        component="div"
        className="panda-carousel-prev"
        sx={{
          cursor: "pointer",
          height: 25,
          width: 25,
        }}
      >
        <ArrowLeft />
      </Box>
      <CustomPandaCarousel
        slidesPerView={1}
        spaceBetween={isMobileView ? 50 : 40}
        navigation={{
          enabled: true,
          prevEl: ".panda-carousel-prev",
          nextEl: ".panda-carousel-next",
        }}
        modules={[Navigation]}
        allowTouchMove={false}
        loop
      >
        {pandas.map((item: PandaNFT, index: number) => {
          return (
            <SwiperSlide key={index} style={{ height: "100%" }}>
              <PandaCarouselItem {...item} />
            </SwiperSlide>
          );
        })}
      </CustomPandaCarousel>
      <Box
        component="div"
        className="panda-carousel-next"
        style={{ cursor: "pointer", height: 25, width: 25 }}
      >
        <ArrowRight />
      </Box>
    </Box>
  );
};

export { PandaCarousel };
