import { ReactNode, Suspense, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Html, OrbitControls } from "@react-three/drei";
import { Planet } from "../gltfjsx/Planet";
import * as THREE from "three";

interface RigProps {
  children: ReactNode;
}

interface Props {
  darkMode: boolean;
}

function Lights() {
  return (
    <>
      <ambientLight intensity={0.1} color="#F7ACCD" />
      <directionalLight
        color="#2E1832"
        position={[0, 300, 500]}
        intensity={3}
      />
      <directionalLight
        color="#D6AFE6"
        position={[500, 100, 0]}
        intensity={3}
      />
      <directionalLight
        color="#2E1832"
        position={[0, 100, -500]}
        intensity={3}
      />
      <directionalLight
        color="#D6AFE6"
        position={[-500, 300, 0]}
        intensity={3}
      />
    </>
  );
}

function Rig({ children }: RigProps) {
  const ref = useRef<THREE.Camera>(null);

  useFrame((state) => {
    ref.current!.rotation.y = THREE.MathUtils.lerp(
      ref.current!.rotation.y,
      (state.mouse.x * Math.PI) / 17,
      0.05
    );
    ref.current!.rotation.x = THREE.MathUtils.lerp(
      ref.current!.rotation.x,
      (state.mouse.y * Math.PI) / 17,
      0.05
    );
  });

  return <group ref={ref as any}>{children}</group>;
}

const PlanetScene = ({ darkMode }: Props) => {
  const Loader = () => {
    return (
      <Html
        center
        style={{
          backgroundColor: "#9850ff",
          borderRadius: "100%",
          color: "#ffffff",
          width: "6rem",
          height: "6rem",
          pointerEvents: "none",
          animation: "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 999,
        }}
      >
        Loading
      </Html>
    );
  };

  return (
    <Canvas
      shadows
      camera={{
        fov: 50,
        position: [-60, 26, 120],
      }}
      style={{
        height: "60vh",
      }}
    >
      <Lights />
      <Suspense fallback={<Loader />}>
        <Rig>
          <Planet position={[0, -2.5, 0]} darkMode={darkMode} />
        </Rig>
      </Suspense>
      <OrbitControls
        enablePan={false}
        enableZoom={false}
        enableRotate={false}
        maxDistance={23}
        minDistance={3}
      />
    </Canvas>
  );
};

export default PlanetScene;
