import React from "react";
import { Box, Fade, Popper } from "@mui/material";
import { StoreItem, StoreItemProps } from "./StoreItem";

export interface StoreListProps {
  anchorEl: any;
  open: boolean;
  location: string;
  list: StoreItemProps[];
  ref?: React.ForwardedRef<HTMLElement>;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onItemClick: (index: number) => void;
}

const StoreList: React.FC<StoreListProps> = React.forwardRef(
  (
    {
      anchorEl,
      open,
      list,
      location,
      onMouseLeave,
      onItemClick,
    }: StoreListProps,
    ref: React.ForwardedRef<HTMLElement>
  ) => {
    return (
      <Popper
        className="nftv-map__storelist"
        open={open}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box component="div" ref={ref} onMouseLeave={onMouseLeave}>
              {list.map((item: StoreItemProps, index: number) => (
                <Box component="div" key={index} sx={{ mb: 1 }}>
                  <StoreItem
                    {...item}
                    storeLocation={location}
                    onClick={() => onItemClick(index)}
                  />
                </Box>
              ))}
            </Box>
          </Fade>
        )}
      </Popper>
    );
  }
);

export { StoreList };
