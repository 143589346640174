import React from "react";
import { Box, Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Panel } from "../../models";
import {
  FullPageCarousel,
  PanelWrapper,
  SectionHeader,
} from "../../components";
import { ReactComponent as NftvEcommerce } from "../../assets/pages/platform/ecosystem/nftv-ecommerce.svg";
import { ReactComponent as SocialfiPlatform } from "../../assets/pages/platform/ecosystem/socialfi-platform.svg";
import { ReactComponent as FtvGlobal } from "../../assets/pages/platform/ecosystem/ftv-global.svg";
import { ReactComponent as FtvModel } from "../../assets/pages/platform/ecosystem/ftv-model.svg";
import { ReactComponent as NftvMarketplace } from "../../assets/pages/platform/ecosystem/nftv-marketplace.svg";
import { ReactComponent as Philanthropy } from "../../assets/pages/platform/ecosystem/philanthropy.svg";
import Utility1 from "../../assets/pages/platform/utilities/utility-1.png";
import Utility2 from "../../assets/pages/platform/utilities/utility-2.png";
import Utility3 from "../../assets/pages/platform/utilities/utility-3.png";
import ContentCreators from "../../assets/pages/platform/socialfi/content-creators.svg";
import UserRewards from "../../assets/pages/platform/socialfi/user-rewards.svg";
import EffectiveMarketing from "../../assets/pages/platform/socialfi/effective-marketing.svg";
import PlatformEvolution from "../../assets/pages/platform/evolution/platform-evolution.svg";
import PlatformEvolutionMobile from "../../assets/pages/platform/evolution/platform-evolution-mobile.svg";
import GreatTransition from "../../assets/pages/platform/transition/great-transition.svg";
import GreatTransitionMobile from "../../assets/pages/platform/transition/great-transition-mobile.svg";

const NftvPlatform: React.FC = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery("(max-width:600px)");
  const tabletView = useMediaQuery("(max-width:960px)");

  const contentVariant = tabletView ? "body2" : "h6";

  const ecosystemAccess = [
    {
      title: "NFTv E-commerce",
      description:
        "Ecommerce has some exciting uses, like increased security, special advantages, and improved customer service.",
      icon: NftvEcommerce,
    },
    {
      title: "Social-Fi Platform",
      description:
        "content creators, influencers, and participants who want better data control, freedom of speech, and the ability to monetize their social media following and engagement.",
      icon: SocialfiPlatform,
    },
    {
      title: "FTV Global Clubs/Events",
      description:
        "f Club is designed to impress and presents itself as an atmospheric late night nightclub as well as a flexible, highly equipped events space.",
      icon: FtvGlobal,
    },
    {
      title: "FTV Model, Designers & Influencer",
      description:
        "utilizing the strength of decentralization to promote your brand by working with our top models, designers, and influencers",
      icon: FtvModel,
    },
    {
      title: "NFTv Marketplace",
      description:
        "Game changing peer-to-peer NFT marketplace. You may access NFT games, music, and other creative works through NFTv Marketplace. The inclusion of ERC-1155 and ERC-721 tokens on the NFTV platform is fascinating.",
      icon: NftvMarketplace,
    },
    {
      title: "Philanthropy Causes Approved By Vote",
      description:
        "Truthful DAO ecosystem that offers the most impartial voting process to the community",
      icon: Philanthropy,
    },
  ];

  const tokenUtilityList = [
    {
      name: "CONTENT CREATORS",
      image: Utility1,
      list: [
        "Mint their content into NFTs",
        "Trade their NFTs in NFTv marketplace",
        "Earn from content creations and social engagements",
        "Perform in live shows and be rewarded",
        "Interact with the upper echelon of the global fashion industry",
      ],
    },
    {
      name: "CONTENT CONSUMERS",
      image: Utility2,
      list: [
        "Access exclusive events",
        "Trade NFTs in NFTv marketplaces",
        "Carry out social activities and earn tokens",
        "Access to exclusive events or discounts by content creators",
        "Watch live shows or reward content creators",
      ],
    },
    {
      name: "ADVERTISERS",
      image: Utility3,
      list: [
        "Buy advertisement spots",
        "Earn rebates and discounts from advertising",
        "Create discount vouchers using tokens",
        "Buy analysis and reports on user behaviours",
        "Develop unique advertising experiences",
      ],
    },
    // {
    //   name: "Developers",
    //   image: PlaceholderImage,
    //   list: ["Buy NFT land", "Buy NFT Materials", "Build/rent Parks"],
    // },
  ];

  const socialfiApp = [
    {
      description: "Showcase of Content Creator’s NFTs",
      image: ContentCreators,
    },
    {
      description: "Social Actions, Stats and Rewards for Users",
      image: UserRewards,
    },
    {
      description: "Effective Marketing Ads",
      image: EffectiveMarketing,
    },
  ];

  const panelList: Panel[] = [
    {
      key: "Your Access to the NFTv Ecosystem",
      component: (
        <PanelWrapper gutterLeft gutterRight scrollShadow>
          <Box
            component="div"
            sx={{
              my: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
            }}
          >
            <Box component="div" sx={{ textAlign: "center" }}>
              <SectionHeader
                content={["Your Access to the", "NFTv Ecosystem"]}
              />
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {ecosystemAccess.map(({ title, description, icon: Icon }) => (
                <Card
                  key={title}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "49%",
                      md: "32%",
                    },
                    py: 3,
                    borderRadius: 5,
                    mb: {
                      xs: 2,
                      sm: 3,
                    },
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: theme.palette.primary.main,
                    },
                    ":hover #title": {
                      display: "none",
                      animation: "fade-out 1s",
                    },
                    ":hover #icon": {
                      display: "none",
                      animation: "fade-out 1s",
                    },
                    ":hover #description": {
                      display: "flex",
                      animation: "fade-in 0.75s",
                    },
                    "@keyframes fade-in": {
                      "0%": {
                        opacity: 0,
                      },
                      "100%": {
                        opacity: 1,
                      },
                    },
                    "@keyframes fade-out": {
                      "0%": {
                        opacity: 1,
                      },
                      "100%": {
                        opacity: 0,
                      },
                    },
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      ml: 3,
                      p: 1,
                      backgroundColor: theme.palette.background.default,
                      borderRadius: 3,
                      height: 56,
                      width: 56,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    id="icon"
                  >
                    <Icon height={32} />
                  </Box>
                  <Typography
                    variant="h6"
                    id="title"
                    sx={{
                      mx: 3,
                      display: "flex",
                      alignItems: "center",
                      width: "calc(100% - 24px - 56px - 48px)",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant={contentVariant}
                    id="description"
                    sx={{
                      mx: 3,
                      display: "none",
                      alignItems: "center",
                    }}
                  >
                    {description}
                  </Typography>
                </Card>
              ))}
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "The NFTv Token Utility",
      component: (
        <PanelWrapper gutterLeft gutterRight scrollShadow>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
            }}
          >
            <SectionHeader content={["The", "NFTv Token Utility"]} />
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                mb: 16,
              }}
            >
              {tokenUtilityList.map(({ name, image, list }) => (
                <Card
                  key={name}
                  sx={{
                    py: 3,
                    width: { xs: "100%", sm: "49%", md: "32%" },
                    borderRadius: 5,
                    mb: {
                      xs: 2,
                      sm: 3,
                    },
                  }}
                >
                  <Box component="div" sx={{ px: 3 }}>
                    <img src={image} alt={name} height={64} />
                    <Typography variant="h5" sx={{ mt: 2 }}>
                      <b>{name}</b>
                    </Typography>
                    <Typography variant="subtitle1">
                      <ul>
                        {list.map((listItem) => (
                          <li key={listItem}>{listItem}</li>
                        ))}
                      </ul>
                    </Typography>
                  </Box>
                </Card>
              ))}
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "NFTv SocialFi App",
      component: (
        <PanelWrapper gutterLeft gutterRight scrollShadow>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "15vh",
                sm: "20vh",
              },
              px: {
                xs: 1,
                lg: 16,
              },
            }}
          >
            <Box component="div" sx={{ textAlign: "center" }}>
              <SectionHeader content={["NFTv", "SocialFi App"]} />
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                pt: 3,
                mb: {
                  xs: "15vh",
                  sm: "20vh",
                },
              }}
            >
              {socialfiApp.map(({ description, image }, index: number) => (
                <Box
                  component="div"
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: {
                      xs: "100%",
                      sm: "33%",
                    },
                    alignItems: "center",
                  }}
                >
                  <Box component="div" sx={{ height: "35vh" }}>
                    <img src={image} alt={description} height="100%" />
                  </Box>
                  <Typography
                    variant={contentVariant}
                    sx={{
                      width: {
                        xs: "100%",
                        md: 230,
                      },
                      textAlign: "center",
                      mt: {
                        xs: 2,
                        sm: 3,
                      },
                      mb: {
                        xs: 4,
                        sm: 0,
                      },
                    }}
                  >
                    {description}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "SocialFi Platform Evolution",
      component: (
        <PanelWrapper gutterLeft gutterRight>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
              textAlign: "center",
            }}
          >
            <Box component="div" sx={{}}>
              <SectionHeader content={["SocialFi", "Platform Evolution"]} />
            </Box>
            <Box component="div" sx={{ height: "40vh" }}>
              <img
                src={mobileView ? PlatformEvolutionMobile : PlatformEvolution}
                alt="roadmap"
                height="100%"
                style={{ maxWidth: "100%" }}
              />
            </Box>
            <Typography variant={contentVariant} sx={{ mt: 3 }}>
              NFTV SocialFi Platform conducts social analysis of the
              ever-changing user behaviour and its future is dependable on the
              users in the platform powered by DAO that will shape the future of
              the platform.
            </Typography>
          </Box>
        </PanelWrapper>
      ),
    },
    {
      key: "Transition from Web 2.0 to Web 3.0 The Great Transition",
      component: (
        <PanelWrapper gutterLeft gutterRight>
          <Box
            component="div"
            sx={{
              mt: {
                xs: "12.5vh",
                md: "20vh",
              },
              pl: {
                xs: 0,
                md: 6,
              },
              textAlign: "center",
            }}
          >
            <Box component="div">
              <SectionHeader
                content={[
                  "Transition from Web 2.0 to",
                  "Web 3.0 The Great Transition",
                ]}
              />
            </Box>
            <Box component="div" sx={{ height: "35vh" }}>
              <img
                src={mobileView ? GreatTransitionMobile : GreatTransition}
                alt="roadmap"
                height="100%"
                style={{ maxWidth: "100%" }}
              />
            </Box>
            <Typography variant="h5" sx={{ mt: 3 }} gutterBottom>
              <b>Ease of Onboarding</b>
            </Typography>
            <Typography variant={contentVariant}>
              Current web 2.0 social media users can easily join the NFTV
              SocialFi Platform with their existing social accounts.
            </Typography>
          </Box>
        </PanelWrapper>
      ),
    },
  ];

  return <FullPageCarousel panelList={panelList} />;
};

export default NftvPlatform;
