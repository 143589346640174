import React from "react";
import { Box, Typography, Grid, useTheme } from "@mui/material";
import { PanelWrapper, ButtonLarge } from "../components";
import { GenesisBoxCard } from "../components/GenesisBoxCard";
import LootboxesBg from "../assets/graphics/lootboxes-bg.png";

const BybitNftvSocial: React.FC = () => {
  const theme = useTheme();

  return (
    <PanelWrapper gutterLeft>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        sx={{
          pt: {
            xs: "5rem",
            sm: "7.5rem",
            md: "10rem",
          },
          pb: "5rem",
          height: {
            md: "100%",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 2, md: 1 }}
          sx={{
            paddingTop: { md: "unset !important" },
            textAlign: { xs: "center", md: "left" },
            marginTop: {
              xs: "7.5rem",
              md: "unset !important",
            },
            display: {
              md: "flex",
            },
          }}
        >
          <Box component="div" sx={{ margin: "auto" }}>
            <Typography
              variant="h2"
              sx={{
                mb: 3,
                fontWeight: 600,
                fontSize: { xs: "1.5rem", sm: "2rem", md: "3.5rem" },
              }}
            >
              <Box component="span" sx={{ color: theme.palette.warning.main }}>
                Bybit
              </Box>
              <Box component="span" sx={{ marginX: "0.75rem" }}>
                x
              </Box>
              <Box component="span" sx={{ color: theme.palette.primary.main }}>
                NFTv.social
              </Box>
            </Typography>

            <Typography
              variant="h3"
              sx={{
                mb: 3,
                fontWeight: 600,
                fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" },
              }}
            >
              NFT Genesis Boxes Upcoming Phase
            </Typography>
            <Box component="div" sx={{ mb: 3 }}>
              <Typography
                variant="body1"
                sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}
              >
                Launching soon!
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}
              >
                <Box
                  component="span"
                  sx={{
                    color: theme.palette.primary.main,
                    paddingRight: "0.35rem",
                  }}
                >
                  200
                </Box>
                limited genesis boxes awaiting now!
              </Typography>
            </Box>
            <ButtonLarge
              variant="contained"
              sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}
              onClick={() => {
                window.open("https://www.bybit.com/", "_blank");
              }}
            >
              Visit Bybit.com
            </ButtonLarge>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 1, md: 2 }}
          sx={{
            height: {
              md: "100%",
            },
            position: "relative",
            paddingTop: { md: "unset !important" },
            justifyContent: "center",
            display: "flex",
            backgroundImage: `url(${LootboxesBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <Box component="div" sx={{ margin: "auto" }}>
            <Box
              component="div"
              sx={{
                transform: "rotate(-10deg)",
                display: "inline-block",
              }}
            >
              <GenesisBoxCard />
            </Box>
            <Box
              component="div"
              sx={{
                position: "relative",
                transform: "rotate(10deg)",
                display: "inline-block",
                top: "3.5rem",
                left: "-1rem",
              }}
            >
              <GenesisBoxCard />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PanelWrapper>
  );
};

export { BybitNftvSocial };
