import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Box,
  Typography,
  useTheme,
  alpha,
} from "@mui/material";
import Lootbox from "../assets/graphics/lootbox.gif";
import { ReactComponent as LootboxIcon } from "../assets/graphics/lootbox-icon.svg";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../features/appStateSlice";

const GenesisBoxCard: React.FC = () => {
  const theme = useTheme();
  const { darkMode } = useAppSelector(selectAppState);

  return (
    <Card
      sx={{
        width: {
          xs: "9rem",
          sm: "15rem",
        },
        padding: 0,
        position: "relative",
        background: `linear-gradient(360deg, ${alpha(
          theme.palette.common[darkMode ? "white" : "black"],
          0.55
        )} -0.12%, ${darkMode ? "#282828" : "#d7d7d7"} -0.11%, ${
          darkMode ? "rgba(27, 26, 40, 0.8)" : "rgba(241, 241, 241, 0.8)"
        } 24%), ${
          darkMode ? theme.palette.common.black : theme.palette.common.white
        }`,
      }}
    >
      <Box
        component="div"
        sx={{ position: "absolute", top: "0.5rem", left: "0.5rem" }}
      >
        <LootboxIcon />
      </Box>
      <CardMedia
        component="img"
        alt="Lootbox"
        image={Lootbox}
        sx={{
          width: "100%",
          cursor: "pointer",
          marginBottom: {
            xs: "0.25rem",
            sm: "1rem",
          },
        }}
        onClick={() => {
          window.open(
            "https://www.premint.xyz/nftv-panda-nfts-whitelist-campaign/",
            "_blank"
          );
        }}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="h5"
          sx={{
            paddingTop: 0,
            fontSize: {
              xs: "0.85rem",
              sm: "1.25rem !important",
            },
          }}
        >
          NFTv Genesis Box
        </Typography>
        <Typography
          gutterBottom
          variant="body1"
          component="h6"
          sx={{
            paddingTop: 0,
            color: theme.palette.warning.main,
            fontSize: {
              xs: "0.75rem",
              sm: "1rem !important",
            },
          }}
        >
          @NFTv.social
        </Typography>
        <Box
          component="div"
          sx={{
            width: "100%",
            display: "flex",
          }}
        >
          <Box component="div" sx={{ flex: 1 }}>
            <Typography
              gutterBottom
              variant="body2"
              component="div"
              sx={{
                fontSize: {
                  xs: "0.65rem",
                  sm: "initial",
                },
                color: theme.palette.grey["700"],
              }}
            >
              Price
            </Typography>
          </Box>
          <Box component="div" sx={{ flex: 1, textAlign: "right" }}>
            <Typography
              gutterBottom
              variant="body2"
              component="div"
              sx={{
                fontSize: {
                  xs: "0.65rem",
                  sm: "initial",
                },
              }}
            >
              0.1 ETH
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              component="div"
              sx={{
                fontSize: {
                  xs: "0.65rem",
                  sm: "initial",
                },
                color: theme.palette.grey["700"],
              }}
            >
              0.1 ETH
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export { GenesisBoxCard };
